import { RatingSearchRequest, RatingSearchResponse, RatingStatsRequest, RatingStatsResponse } from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'rating'

const searchRatings = (searchParams: RatingSearchRequest) => {
	return apiClient.post<RatingSearchResponse>(`${API_RESOURCE}/search`, searchParams)
}

const getRatingStats = (searchParams: RatingStatsRequest) => {
	return apiClient.post<RatingStatsResponse>(`${API_RESOURCE}/stats`, searchParams)
}

export const ratingApi = {
	searchRatings,
	getRatingStats,
}
