import { Channel } from '@smartsupp/core-client-api'
import { SearchFilter as ChatsSearchRequestQuery } from '@smartsupp/smartsupp-backend-client'
import { ChatReadInfo, ChatUnreadInfo, Variables, Visitor } from '@smartsupp/websocket-client-agent'

import { ChatbotGroupType } from './Chatbot'
import { Contact } from './Contact'
import { Message } from './Message'
import { ShortcutType } from './Shortcut'

export { ChannelType, Channel as ChatChannel, ResponseOk } from '@smartsupp/core-client-api'
export {
	BulkDeleteRequest as ChatbotChatsDeleteBody,
	BotConversationsSearchRequest as ChatbotChatSearchRequest,
	SearchOperator as ChatsSearchOperator,
	SearchFilter as ChatsSearchRequestQuery,
} from '@smartsupp/smartsupp-backend-client'
export { ChatUnreadInfo } from '@smartsupp/websocket-client-agent'
export enum ChatRatingValue {
	Positive = 5,
	Neutral = 3,
	Negative = 1,
}

export enum ChatStatus {
	Open = 'open',
	Closed = 'closed',
	Pending = 'pending',
	Served = 'served',
}

export enum ChatSelectionType {
	All = 'all',
	None = 'none',
	Specific = 'specific',
}

export enum OpenChatsFilterType {
	All = 'all',
	Primary = 'primary',
	Unserved = 'unserved',
	Mine = 'mine',
}

export enum ClosedChatsFilterType {
	Agents = 'agents',
	Automations = 'automations',
	Ai = 'ai',
}

export enum ChatsTab {
	Open = 'open',
	Closed = 'closed',
}
export type RecaptchaAction = 'transcript' | 'sendMail' | 'inviter' | 'inviterRetry'

export interface BrowsingPath {
	title: string
	url?: string
}

export interface ChatBrowsingPath extends BrowsingPath {
	createdAt: string
}

export interface ChatRating {
	value: number
	text: string | null
}

export interface ChatContactDetails {
	avatar: Contact['avatar']
	name: string | null
	email: string | null
	note: string | null
	phone: string | null
}

export interface ChatVisitorDetails {
	connectedAt: string | null
}

export interface Chat {
	id: string
	type: string
	visitorId: string
	contactId: string
	channel: Channel
	status: ChatStatus
	isClosed: boolean // by visitor
	isOffline: boolean
	isServed: boolean
	groupId: string | null
	assignedIds: string[]
	agentIds: string[]
	createdAt: string
	updatedAt: string
	finishedAt: string | null
	ratingAiValue: number | null
	ratingValue: number | null
	important: boolean
	locCode: string | null
	locCountry: string | null
	locCity: string | null
	locIp: string | null
	referer: string | null
	domain: string | null
	userAgent: string | null
	variables: Variables | null
	lastMessage: Message
	readInfo?: ChatReadInfo // about visitor
	unreadInfo: ChatUnreadInfo // about agent
	contactDetails: ChatContactDetails
	visitorDetails?: ChatVisitorDetails
	paths: ChatBrowsingPath[]
	exportUrl: string
	tags: string[]
	groupType: ChatbotGroupType | null
}

// Chat object obtained from selectors
export type SelectedChat = Chat & {
	displayedName: string
	hasLastMessageDeliveryFailed: boolean
}

export interface ChatLocationInfo {
	cityName: string | null
	countryCode: string | null
	countryName: string | null
	ip: string | null
}

export interface ChatsSearchRequestFilters {
	contactId?: string
	dateFrom?: string
	dateTo?: string
	locIp?: string
	message?: string
	pageUrl?: string
	query?: string
	visitorEmail?: string
	visitorName?: string
}

export type ChatsSearchRequestSortItem = {
	[key in keyof Chat]?: 'asc' | 'desc'
}

export interface ChatsSearchRequest {
	size: number
	query: ChatsSearchRequestQuery[]
	filters?: ChatsSearchRequestFilters
	sort: ChatsSearchRequestSortItem[]
	after?: number[]
	timezone: string
}

export type ChatsExportRequest = Pick<ChatsSearchRequest, 'query' | 'filters' | 'sort' | 'timezone'>

export type ChatsSearchItem = Chat & {
	visitorDetails: Pick<Visitor, 'connectedAt'>
	lastMessage: Message
	unreadInfo: ChatUnreadInfo
}

export interface ChatsSearchResponse {
	total: number
	items: ChatsSearchItem[]
	after: number[]
	autoCloseCount?: number
}

export type ChatsFetchResponse = Chat & {
	lastMessage: Message
	messages: Message[]
	exportUrl: string
}

export type ChatsReadResponse = {
	lastReadAt: string
}

export type SendChatTranscriptActionParams = {
	chatId: string
	email: string
	recaptchaToken: string | null
	onSuccess?: () => void
}

export type InsertIntoDraftTextActionParams = {
	chatId: string
	text: string
	currentCursorPosition: number
}

export type SelectShortcutActionParams = InsertIntoDraftTextActionParams & {
	type: ShortcutType
}

export type MessageAttachment = {
	name: string
	token?: string
	isUploading: boolean
}

export type ChatsFetchUnreadResponse = {
	count: number
}
