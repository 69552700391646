import { ReactNode } from 'react'

import { Box, Center, HStack, Icon, Skeleton, StackProps, Text } from 'core'

type IconStyle = 'solid' | 'outline'

type Props = {
	children: ReactNode
	sumValue?: ReactNode
	icon?: string
	iconStyle?: IconStyle
	colorScheme?: string
	isEmpty?: boolean
	isLoading?: boolean
	valueFontSize?: string
} & StackProps

export const StatValue = ({
	children,
	sumValue,
	isEmpty = false,
	icon,
	colorScheme = 'blue',
	isLoading = false,
	valueFontSize = '3xl',
	iconStyle = 'outline',
	...rest
}: Props) => {
	const iconStyleMap: Record<IconStyle, { color: string; bgColor: string }> = {
		solid: { color: `white`, bgColor: `${colorScheme}.500` },
		outline: { color: `${colorScheme}.500`, bgColor: `${colorScheme}.200` },
	}

	return (
		<HStack alignSelf="flex-start" spacing={2} w="full" {...rest}>
			{icon && (
				<Box w="32px" alignSelf="center">
					<Center borderRadius="50%" bgColor={iconStyleMap[iconStyle].bgColor} w="32px" h="32px">
						<Icon path={icon} fontSize={3} color={iconStyleMap[iconStyle].color} />
					</Center>
				</Box>
			)}
			<Skeleton w="50%" borderRadius={2} isLoaded={!isLoading}>
				{isEmpty ? (
					<Text fontSize={valueFontSize} fontWeight="bold">
						-
					</Text>
				) : (
					<HStack w="full">
						<Text fontSize={valueFontSize} fontWeight="bold">
							{children}
						</Text>
						{sumValue && (
							<Text color="gray.500" fontSize="lg">
								({sumValue})
							</Text>
						)}
					</HStack>
				)}
			</Skeleton>
		</HStack>
	)
}
