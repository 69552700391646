import { DateRangePreset } from 'models'
import { RangeType } from 'shared/models/Analytics'

export type RatingStatsInterval = Extract<
	DateRangePreset,
	DateRangePreset.Past7D | DateRangePreset.Past30D | DateRangePreset.PastYear
>

export const aiRatingInterval: Record<RatingStatsInterval, number> = {
	[DateRangePreset.Past7D]: 60 * 60 * 24 * 6,
	[DateRangePreset.Past30D]: 60 * 60 * 24 * 29,
	[DateRangePreset.PastYear]: 60 * 60 * 24 * 364,
}

export const ratingStatsIntervalToRangeType = {
	[DateRangePreset.Past7D]: RangeType.Last7days,
	[DateRangePreset.Past30D]: RangeType.Last30days,
	[DateRangePreset.PastYear]: RangeType.LastYear,
}
