import { mdiArrowBottomRight, mdiArrowRight, mdiArrowTopRight } from '@mdi/js'

import { Flex, HStack, Icon, Skeleton, Text } from 'core'

export enum StatTrendType {
	Increase = 'increase',
	Decrease = 'decrease',
	noChange = 'noChange',
}

type Props = {
	value: number
	isLoading: boolean
	isReversed?: boolean
	isEmpty?: boolean
	iconSize?: number
	fontSize?: string
	w?: string
}

const calculateTrend = (value: number, isEmpty?: boolean) => {
	if (isEmpty) {
		return StatTrendType.noChange
	}
	if (value > 0) {
		return StatTrendType.Increase
	}
	if (value < 0) {
		return StatTrendType.Decrease
	}
	return StatTrendType.noChange
}

const getTrendIcon = (type: StatTrendType): string => {
	switch (type) {
		case StatTrendType.Increase: {
			return mdiArrowTopRight
		}
		case StatTrendType.Decrease: {
			return mdiArrowBottomRight
		}
		default: {
			return mdiArrowRight
		}
	}
}

const getTrendColor = (type: StatTrendType, isReversed: boolean): string => {
	switch (type) {
		case StatTrendType.Increase: {
			return isReversed ? 'red.500' : 'green.500'
		}
		case StatTrendType.Decrease: {
			return isReversed ? 'green.500' : 'red.500'
		}
		default: {
			return 'gray.500'
		}
	}
}

export const StatTrend = ({
	value,
	isLoading,
	isReversed = false,
	isEmpty = false,
	iconSize = 4,
	fontSize = 'lg',
	w = '100%',
}: Props) => {
	const statTrendValue = `${Math.abs(value)}%`
	const trend = calculateTrend(value, isEmpty)

	return (
		<Flex w={w}>
			<Skeleton w={isLoading ? '40%' : 'auto'} borderRadius={2} isLoaded={!isLoading}>
				<HStack w="full" spacing={0}>
					<Icon path={getTrendIcon(trend)} color={getTrendColor(trend, isReversed)} fontSize={iconSize} />
					<Text fontSize={fontSize} color={getTrendColor(trend, isReversed)}>
						{isEmpty ? '%' : statTrendValue}
					</Text>
				</HStack>
			</Skeleton>
		</Flex>
	)
}
