import {
	PackageInterval,
	PackageIntervalAvailable,
	PackageName,
	StripeInvoice,
	StripeInvoiceProductItem,
	StripeInvoiceProductItemPrice,
	StripeSubscriptionCalculation,
	StripeSubscriptionCalculationItem,
	StripeSubscriptionCalculationItemPrice,
	StripeSubscriptionCalculationRequest,
	StripeSubscriptionCalculationRequestPackageCode,
	StripeSubscriptionCalculationResponse,
	StripeSubscriptionOrderItemCode,
	StripeSubscriptionSaveRequestInterval,
} from 'models'
import { CheckoutType } from 'modules/checkout'

export const getPackageFromProducts = (
	products: StripeSubscriptionCalculationItem[] | StripeInvoiceProductItem[],
): StripeSubscriptionCalculationItemPrice | null => {
	const product = products.find(({ code }) => code === StripeSubscriptionOrderItemCode.Package)
	const price = product?.prices.find(({ proration }) => !proration)

	return price || null
}

export const getProductExtra = (
	products: StripeSubscriptionCalculationItem[] | StripeInvoiceProductItem[],
	productCode: StripeSubscriptionOrderItemCode,
): StripeSubscriptionCalculationItemPrice | null => {
	const product = products.find(({ code }) => code === productCode)
	const extra = product?.prices.find(({ unitPrice, proration }) => unitPrice !== 0 && !proration)

	return extra || null
}

export const minorToNormalPrice = (price: number, minorDecimals = 2) => price / 10 ** minorDecimals

export const transformMinorUnitPrices = (
	calculation: StripeSubscriptionCalculationResponse,
): StripeSubscriptionCalculationResponse => {
	const convertPrices = (prices: StripeSubscriptionCalculationItemPrice[]) =>
		prices.map((price) => ({
			...price,
			unitPrice: minorToNormalPrice(price.unitPrice),
			total: minorToNormalPrice(price.total),
		}))

	const convertProducts = (products: StripeSubscriptionCalculationItem[]) =>
		products.map((product) => ({
			...product,
			prices: convertPrices(product.prices),
		}))

	const convertInterval = (interval: StripeSubscriptionCalculation) => ({
		...interval,
		products: convertProducts(interval.products),
		subtotal: minorToNormalPrice(interval.subtotal),
		total: minorToNormalPrice(interval.total),
		totalExcludingTax: minorToNormalPrice(interval.totalExcludingTax),
		totalTaxAmounts: minorToNormalPrice(interval.totalTaxAmounts),
		prorationTotal: minorToNormalPrice(interval.prorationTotal),
		discountTotal: minorToNormalPrice(interval.discountTotal),
		nextBillingAmount: minorToNormalPrice(interval.nextBillingAmount),
		amountDue: minorToNormalPrice(interval.amountDue),
		balanceApplied: minorToNormalPrice(interval.balanceApplied),
	})

	return {
		month: convertInterval(calculation.month),
		year: convertInterval(calculation.year),
	}
}

export const transformInvoiceMinorUnitPrices = (invoice: StripeInvoice): StripeInvoice => {
	const convertPrices = (prices: StripeInvoiceProductItemPrice[]) =>
		prices.map((price) => ({
			...price,
			unitPrice: minorToNormalPrice(price.unitPrice),
			total: minorToNormalPrice(price.total),
		}))

	const convertProducts = (products: StripeInvoiceProductItem[]) =>
		products.map((product) => ({
			...product,
			prices: convertPrices(product.prices),
		}))

	return {
		...invoice,
		products: convertProducts(invoice.products),
		subtotal: minorToNormalPrice(invoice.subtotal),
		total: minorToNormalPrice(invoice.total),
		totalExcludingTax: minorToNormalPrice(invoice.totalExcludingTax),
		totalTaxAmounts: minorToNormalPrice(invoice.totalTaxAmounts),
		prorationTotal: minorToNormalPrice(invoice.prorationTotal),
		discountTotal: minorToNormalPrice(invoice.discountTotal),
		amountDue: minorToNormalPrice(invoice.amountDue),
		balanceApplied: minorToNormalPrice(invoice.balanceApplied),
	}
}

export const resolveStripePackageName = (packageName: PackageName): StripeSubscriptionCalculationRequestPackageCode => {
	switch (packageName) {
		case PackageName.Pro: {
			return StripeSubscriptionCalculationRequestPackageCode.Pro
		}
		case PackageName.Basic: {
			return StripeSubscriptionCalculationRequestPackageCode.Standard
		}
		case PackageName.Mini: {
			return StripeSubscriptionCalculationRequestPackageCode.Mini
		}
		default: {
			return StripeSubscriptionCalculationRequestPackageCode.Standard
		}
	}
}

export const resolveStripeInterval = (interval: PackageIntervalAvailable): StripeSubscriptionSaveRequestInterval => {
	if (interval === PackageInterval.Month) return StripeSubscriptionSaveRequestInterval.Month
	return StripeSubscriptionSaveRequestInterval.Year
}

export const resolveCalculationPromoCodeInterval = (calculation: StripeSubscriptionCalculationResponse) => {
	const { month, year } = calculation

	if (month.discountTotal && !year.discountTotal) {
		return StripeSubscriptionSaveRequestInterval.Month
	}

	if (!month.discountTotal && year.discountTotal) {
		return StripeSubscriptionSaveRequestInterval.Year
	}

	return null
}

export const hasCalculationDiscounts = (calculation: StripeSubscriptionCalculationResponse) => {
	const { month, year } = calculation
	return !!(month.discountTotal + year.discountTotal)
}

export const resolveBlackFridayCoupons = (
	configurationData: StripeSubscriptionCalculationRequest,
	checkoutType: CheckoutType,
	promoCodes?: string[],
): string[] => {
	if (
		promoCodes &&
		(promoCodes.includes('BF24STANDARD') || promoCodes.includes('BF24PRO') || promoCodes.includes('BF24AI')) &&
		checkoutType === CheckoutType.StripeNewSubscription
	) {
		const blackFridayPromoCodes: string[] = []
		switch (configurationData.packageCode) {
			case StripeSubscriptionCalculationRequestPackageCode.Standard: {
				blackFridayPromoCodes.push('BF24STANDARD')
				break
			}
			case StripeSubscriptionCalculationRequestPackageCode.Pro: {
				blackFridayPromoCodes.push('BF24PRO')
				break
			}
			case StripeSubscriptionCalculationRequestPackageCode.Mini: {
				blackFridayPromoCodes.push('BF24AI')
				break
			}
		}

		const configurationAiConversationsProducts = configurationData.products.filter(
			(item) => item.code === StripeSubscriptionOrderItemCode.AiConversation,
		)
		if (
			configurationAiConversationsProducts.length > 0 &&
			configurationAiConversationsProducts[0].quantity > 0 &&
			configurationData.packageCode !== StripeSubscriptionCalculationRequestPackageCode.Mini
		) {
			blackFridayPromoCodes.push('BF24AI')
		}

		return blackFridayPromoCodes
	}
	return promoCodes ?? []
}
