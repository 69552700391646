import { ChatLocationInfo, Contact } from 'models'
import { Visitor } from 'shared/models/Visitor'
import { checkFirstCharSharp, ParsedUserAgent } from 'utils'

const getLocation = (country: string, city: string): string => {
	if (country && city) {
		return `${country}, ${city}`
	}
	if (country) {
		return country
	}
	if (city) {
		return city
	}
	return ''
}

export const getDataToCopy = (
	userAgent: ParsedUserAgent,
	locationInfo: ChatLocationInfo,
	source: Visitor | Contact | null,
) => ({
	name: checkFirstCharSharp(source ? (source.name ?? source.id) : ''),
	email: checkFirstCharSharp(source?.email ?? ''),
	location: getLocation(locationInfo.countryCode || '', locationInfo.cityName || ''),
	ip: locationInfo.ip || '',
	platform: `${userAgent.os.visibleName}, ${userAgent.browser.visibleName}`,
})
