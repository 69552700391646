import { batch } from 'react-redux'
import { createAsyncThunk, createSelector, createSlice, PayloadAction, unwrapResult } from '@reduxjs/toolkit'

import {
	ChannelType,
	ChatbotGroupType,
	ChatChannel,
	ChatsTab,
	ChatStatus,
	ClosedChatsFilterType,
	FeatureUsageName,
	InsertIntoDraftTextActionParams,
	MessageAttachment,
	ResponseOk,
	SelectShortcutActionParams,
	SendChatTranscriptActionParams,
	ShortcutType,
} from 'models'
import { AppThunkAction, DashboardState, Dictionary } from 'types'
import { CustomHeader } from 'shared/config/headers'
import { ConfigurationService, TranslationService as T } from 'shared/services'
import { ApiError, flashMessage, notification } from 'shared/utils'
import { createConversationLink, routes } from 'configuration/routes'
import { ERROR_INVALID_RECAPTCHA, ERROR_TOO_MANY_REQUESTS } from 'constants/errors'
import { MAX_ATTACHMENTS_COUNT } from 'constants/files'
import { getChatId } from 'selectors/commonSelectors'
import { closeNotification, formatFileSize, navigateTo } from 'utils'
import { replaceEmoticonsByEmojis } from 'utils/replaceEmoticonsByEmojis'
import { initAddonsContextChatDetail, setIsAddonsInitialized } from 'modules/addons'
import { appSelectors } from 'modules/app'
import { clearSelectedChannelDetail, fetchChannelDetailById } from 'modules/channels'
import { chatsSelectors, isChatbotConversation } from 'modules/chats'
import {
	chatAgentAssigned,
	chatAgentJoined,
	chatAgentLeft,
	chatAgentUnassigned,
	chatClosed,
	chatOpened,
	chatSelected,
	chatSelectedError,
	chatUnselected,
	fetchChat,
	readChat,
	setChatDetailId,
} from 'modules/chats/actions'
import { setClosedChatsFilter } from 'modules/chatsFilter'
import { chatsSliceSelectors, fetchChatbotChatThunk, setActiveChatsTab, setActiveTab } from 'modules/chatsSlice'
import { contactsSelectors } from 'modules/contacts'
import { logFeatureUsage } from 'modules/features'
import { messagesSelectors, sendMessage } from 'modules/messages'
import { detectShortcutInText, replaceShortcutInText } from 'modules/shortcuts'
import { openSystemModal, SystemModalType } from 'modules/systemModal'
import { setTextareaFocused, setTextareaNextCursorPosition } from 'modules/textarea'
import { userSelectors } from 'modules/user'
import { visitorsSelectors } from 'modules/visitors'

import { chatDetailApi } from './api'
import { AssignAgentParams, JoinChatParams, SendChatTranscriptParams } from './types'
import { isLinkLimitReached, trimDraftMessage } from './utils'

const ENTITY_TOO_LARGE = 413

export type ChatDetailState = typeof initialState
export type ChatDetailRootState = Pick<DashboardState, 'chatDetail'>

export const initialState = {
	draftAttachments: {} as Dictionary<Dictionary<MessageAttachment>>,
	draftMessages: {} as Dictionary<string>,
	channel: null as null | ChatChannel,
	unreadMessage: null as null | string,
	holdMessageListScrollPosition: false, // Indicates that user manually scrolled in message list, used to prevent scroll to bottom
	isJoiningChat: false,
	isLeavingChat: false,
	isAssigningChat: false,
	isUploading: false,
	isSendMailPending: false,
	isResolvingChat: false,
	isChatDetailVisible: false, // for cases when route Conversations is not active (active is e.g. VisitorList, ...)
}

const joinChatThunk = createAsyncThunk('chats/JOIN', (params: JoinChatParams) => {
	return chatDetailApi.joinChat(params)
})

const leaveChatThunk = createAsyncThunk('chats/LEAVE', (chatId: string) => {
	return chatDetailApi.leaveChat(chatId)
})

export const leaveChat =
	(chatId: string, agentId: string): AppThunkAction =>
	async (dispatch) => {
		const resultAction = await dispatch(leaveChatThunk(chatId))
		if (leaveChatThunk.fulfilled.match(resultAction)) {
			dispatch(chatAgentLeft({ chatId, agentId }))
		} else {
			flashMessage.error('general.error')
		}
	}

const assignAgentThunk = createAsyncThunk('chats/ASSIGN', (params: AssignAgentParams) => {
	return chatDetailApi.assignAgent(params)
})

export const assignAgent =
	(chatId: string, agentId: number): AppThunkAction =>
	async (dispatch) => {
		const resultAction = await dispatch(assignAgentThunk({ chatId, agentId }))
		if (assignAgentThunk.fulfilled.match(resultAction)) {
			dispatch(chatAgentAssigned({ chatId, assignedId: `${agentId}` }))
		} else {
			flashMessage.error('chat.assign.error')
		}
	}

const unassignAgentThunk = createAsyncThunk('chats/UNASSIGN', (params: AssignAgentParams) => {
	return chatDetailApi.unassignAgent(params)
})

export const unassignAgent =
	(chatId: string, agentId: number): AppThunkAction =>
	async (dispatch) => {
		const resultAction = await dispatch(unassignAgentThunk({ chatId, agentId }))
		if (unassignAgentThunk.fulfilled.match(resultAction)) {
			dispatch(chatAgentUnassigned({ chatId, unassignedId: `${agentId}` }))
		} else {
			flashMessage.error('chat.assign.error')
		}
	}

const sendChatTranscriptToEmailThunk = createAsyncThunk(
	'chats/SEND_TRANSCRIPT',
	async (params: SendChatTranscriptParams, { rejectWithValue }) => {
		try {
			return await chatDetailApi.sendChatTranscriptToEmail(params)
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (error?.code === ERROR_INVALID_RECAPTCHA) {
				flashMessage.error('general.error.recaptcha', { testId: 'flashMessage-recaptcha-error-send-transcript' })
			} else if (error?.code === ERROR_TOO_MANY_REQUESTS) {
				flashMessage.error('general.error.resendLimit', {
					testId: 'flashMessage-too-many-requests-error-send-transcript',
				})
			} else {
				flashMessage.error('general.error', { testId: 'flashMessage-general-error-send-transcript' })
			}
			return rejectWithValue({ error })
		}
	},
)

export const sendChatTranscriptToEmail =
	({ chatId, recaptchaToken, email, onSuccess }: SendChatTranscriptActionParams): AppThunkAction =>
	async (dispatch) => {
		const { lang } = ConfigurationService.getData()
		const headers: Record<string, string> = {}
		if (recaptchaToken) {
			headers[CustomHeader.Recaptcha] = recaptchaToken
		}

		const resultAction = await dispatch(sendChatTranscriptToEmailThunk({ chatId, email, lang, headers }))
		if (sendChatTranscriptToEmailThunk.fulfilled.match(resultAction)) {
			flashMessage.success('flashMessage.email.success', { testId: 'flashMessage-success-send-transcript' })
			onSuccess && onSuccess()
		}
	}

const resolveChatThunk = createAsyncThunk('chats/RESOLVE', (id: string) => {
	return chatDetailApi.resolveChat(id)
})

export const resolveChat =
	(chatId: string): AppThunkAction<Promise<boolean>> =>
	async (dispatch, getState) => {
		const isResolvingChat = getIsResolvingChat(getState())
		const selectedChatId = chatsSelectors.getChatDetailId(getState())
		if (isResolvingChat) return false

		const resultAction = await dispatch(resolveChatThunk(chatId))
		if (resolveChatThunk.fulfilled.match(resultAction)) {
			if (chatId === selectedChatId) {
				dispatch(unselectChat(true))
			}
			dispatch(chatClosed({ chatId }))
			return true
		}
		return false
	}

export const setChannelOnVisitorChange =
	(chatId: string, isConnected: boolean): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const selectedChatId = chatsSelectors.getChatDetailId(state)
		const selectedContact = contactsSelectors.getSelectedContact(state)
		const hasContactEmail = !!selectedContact?.email

		// apply changes of visitor visibility only for selected chat in Chat detail
		if (selectedChatId !== chatId) return

		const getHasDraftMessage = chatDetailSelectors.makeGetHasDraftMessageById()
		const hasDraftMessage = getHasDraftMessage(state, { chatId })

		// visitor has just changed his connection status
		// if agent has draftText - channel is changed only if Visitor has just disconnected (from Chat to Email)
		// ... opposite way doesn't change channel
		if (!isConnected) {
			if (hasContactEmail) {
				dispatch(setChannel({ type: ChannelType.Email, id: null }))
				if (hasDraftMessage) {
					flashMessage.warning('chat.systemMsg.visitorLeft')
				}
			} else {
				dispatch(setChannel({ type: ChannelType.Default, id: null }))
			}
		} else if (hasDraftMessage) {
			flashMessage.success('chat.visitor.isOnline')
		} else {
			dispatch(setChannel({ type: ChannelType.Default, id: null }))
		}
	}

export const setBaseChannelAsChannel = (): AppThunkAction => (dispatch, getState) => {
	const channel = chatsSelectors.getBaseChatChannel(getState())
	if (!channel) return

	dispatch(setChannel(channel))
}

export const initChannel =
	(baseChannel: ChatChannel, visitorId: string): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const getVisitorById = visitorsSelectors.makeGetVisitorById()
		const visitor = getVisitorById(state, { visitorId })

		const hasAnotherChat = chatsSelectors.getHasSelectedChatVisitorAnotherChat(state)

		const selectedContact = contactsSelectors.getSelectedContact(state)
		const hasContactEmail = !!selectedContact?.email

		const isVisitorConnected = !!visitor?.connectedAt

		dispatch(clearSelectedChannelDetail())

		switch (baseChannel.type) {
			case ChannelType.Default: {
				const channelToSet =
					(isVisitorConnected || !hasContactEmail) && !hasAnotherChat ? ChannelType.Default : ChannelType.Email
				dispatch(setChannel({ type: channelToSet, id: null }))
				break
			}
			case ChannelType.FacebookMessenger: {
				if (baseChannel.id) {
					// fetch channel data
					const response = await dispatch(fetchChannelDetailById(baseChannel.id))
					const channelData = await unwrapResult(response)
					const channelToSet = channelData.deletedAt ? ChannelType.Email : ChannelType.FacebookMessenger
					dispatch(setChannel({ type: channelToSet, id: null }))
				} else dispatch(clearSelectedChannelDetail())
				break
			}
			case ChannelType.Smartsupp:
			case ChannelType.App: {
				dispatch(setChannel(baseChannel))
				break
			}
		}
	}

export const uploadFiles =
	(chatId: string, files: File[]): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const getAttachmentsCountById = chatDetailSelectors.makeGetAttachmentsCountByChatId(chatId)
		const countBeforeUpload = getAttachmentsCountById(state)

		if (countBeforeUpload + files.length > MAX_ATTACHMENTS_COUNT) {
			const message = `${T.translate('chat.fileUpload.error.countLimit')} ${MAX_ATTACHMENTS_COUNT}`
			notification.error({ title: message, testId: 'notification-error-upload-count' })
		}

		const freeSlotsForUpload = MAX_ATTACHMENTS_COUNT - countBeforeUpload
		if (freeSlotsForUpload > 0) {
			const filesForUpload = files.slice(0, freeSlotsForUpload)
			filesForUpload.forEach((file) => {
				dispatch(uploadFile(chatId, file))
			})
		}
	}

const uploadFileThunk = createAsyncThunk('chats/UPLOAD_FILE', (chatId: string) => {
	return chatDetailApi.uploadFile(chatId)
})

export const uploadFile =
	(chatId: string, file: File): AppThunkAction =>
	async (dispatch, getState) => {
		try {
			const result = await dispatch(uploadFileThunk(chatId))
			const uploadInitResponse = unwrapResult(result)
			try {
				dispatch(addAttachmentInit({ chatId, filename: file.name, token: uploadInitResponse.token }))

				const formData = new window.FormData()
				formData.append('file', file, file.name)
				const uploadResponse = await fetch(uploadInitResponse.url, {
					method: 'post',
					credentials: 'include',
					body: formData,
				})

				if (!uploadResponse.ok) {
					throw new ApiError(uploadResponse)
				}
				dispatch(addAttachmentSuccess({ chatId, token: uploadInitResponse.token }))
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				dispatch(removeAttachment({ chatId, token: uploadInitResponse.token }))

				let message = T.translate('chat.fileUpload.error.server')

				const fileUpload = appSelectors.getFileUploadConfig(getState())

				// Handle API errors
				if (error?.response) {
					const { status } = error.response
					if (status === ENTITY_TOO_LARGE) {
						message = fileUpload
							? T.translate('chat.fileUpload.error.maxSize', { filesize: formatFileSize(fileUpload.maxFileSize) })
							: T.translate('general.error')
					}
				}

				notification.error({ title: message })
			}
		} catch {
			notification.error({ title: T.translate('chat.fileUpload.error.server') })
		}
	}

export const insertIntoDraftMessage =
	({ chatId, text, currentCursorPosition }: InsertIntoDraftTextActionParams): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const getDraftMessageById = makeGetDraftMessageById()
		const draftMessage = getDraftMessageById(state, { chatId })

		const nextCursorPosition = currentCursorPosition + text.length
		const part1 = draftMessage.slice(0, currentCursorPosition)
		const part2 = draftMessage.slice(currentCursorPosition)
		const updatedText = `${part1}${text}${part2}`
		dispatch(setDraftMessage({ chatId, text: updatedText }))

		const isTextInside = part2.length > 0
		if (isTextInside) {
			dispatch(setTextareaNextCursorPosition(nextCursorPosition))
		}
	}

export const selectShortcut =
	({ chatId, type, text, currentCursorPosition }: SelectShortcutActionParams): AppThunkAction =>
	(dispatch, getState) => {
		const getDraftMessageById = chatDetailSelectors.makeGetDraftMessageById()
		const draftMessage = getDraftMessageById(getState(), { chatId })

		const isShortcutDetected = detectShortcutInText(draftMessage) !== null
		if (isShortcutDetected) {
			const updatedText = replaceShortcutInText(draftMessage, text)
			dispatch(setDraftMessage({ chatId, text: updatedText }))
		} else {
			dispatch(insertIntoDraftMessage({ chatId, text, currentCursorPosition }))
		}

		if (type === ShortcutType.Personal) {
			dispatch(logFeatureUsage(FeatureUsageName.ShortcutsPersonal))
		} else {
			dispatch(logFeatureUsage(FeatureUsageName.ShortcutsTeam))
		}
	}

export const exportConversation = createAsyncThunk(
	'chatDetail/EXPORT',
	({ chatId, timezone }: { chatId: string; timezone: string }) => {
		return chatDetailApi.exportConversation(chatId, timezone)
	},
)

export const selectChat =
	(chatId: string): AppThunkAction =>
	async (dispatch, getState) => {
		const isChatbotChat = isChatbotConversation(chatId)
		dispatch(setChatDetailId({ chatId }))

		dispatch(setIsAddonsInitialized(false))
		const chat = isChatbotChat ? await dispatch(fetchChatbotChatThunk(chatId)) : await dispatch(fetchChat(chatId, true))
		if (!chat) {
			dispatch(chatSelectedError())
			throw new Error('Chat not found')
		}

		const { channel, visitorId, status, groupType } = chat

		batch(() => {
			navigateTo(createConversationLink(chatId))
			closeNotification(chatId)
			dispatch(setUnreadMessage(null))
			dispatch(setHoldMessageListScrollPosition(false))
			dispatch(chatSelected({ chat }))
			dispatch(initChannel(channel, visitorId))
			dispatch(readChat(chatId))
			dispatch(initAddonsContextChatDetail())

			// Set active chats tab by chat status
			if (status) {
				const activeTab = chatsSliceSelectors.getActiveTab(getState())
				const newActiveTab =
					status === ChatStatus.Open || status === ChatStatus.Pending ? ChatsTab.Open : ChatsTab.Closed
				if (newActiveTab !== activeTab) {
					dispatch(setActiveChatsTab(newActiveTab))
				}
				const { lastMessage } = chat
				const { trigger } = lastMessage ?? {}
				if (!trigger) {
					setClosedChatsFilter(ClosedChatsFilterType.Agents)
					return
				}

				groupType === ChatbotGroupType.Ai
					? dispatch(setClosedChatsFilter(ClosedChatsFilterType.Ai))
					: dispatch(setClosedChatsFilter(ClosedChatsFilterType.Automations))
			}
		})
	}

export const unselectChat =
	(shouldRedirect = true): AppThunkAction =>
	(dispatch) => {
		dispatch(chatUnselected())
		if (shouldRedirect) navigateTo(routes.conversations.path)
	}

export const joinChat =
	(chatId: string): AppThunkAction<Promise<boolean>> =>
	async (dispatch, getState) => {
		const state = getState()
		const userId = userSelectors.getUserId(state)
		const canForceJoin = chatsSelectors.canForceJoinChat(state)
		if (!userId) return false

		const resultAction = await dispatch(joinChatThunk({ chatId, canForceJoin }))
		const response: ResponseOk = unwrapResult(resultAction)
		// if join was refused - prevent situations when another agent is joining into chat in same time
		if (response.ok) {
			batch(() => {
				dispatch(chatAgentJoined({ chatId, agentId: userId }))
				dispatch(setActiveTab({ tab: ChatsTab.Open }))
				dispatch(chatOpened({ chatId, isMuted: true }))
			})
			return true
		}
		return false
	}

export const sendJoinMessage =
	(chatId: string, text: string, channel: ChatChannel): AppThunkAction =>
	async (dispatch) => {
		// Sending of message is allowed only if join was successful
		const joinResult = await dispatch(joinChat(chatId))
		if (joinResult) {
			dispatch(sendMessage(chatId, text, channel))
		} else {
			flashMessage.warning('chat.sendMsg.confirm.title')
		}
	}

export const sendTextMessage =
	(chatId: string): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const channel = chatDetailSelectors.getChatChannelData(state)
		const isSendingMessage = messagesSelectors.isSendingMessage(state)
		if (!channel || !channel.type || isSendingMessage) return

		const getAttachmentsCount = chatDetailSelectors.makeGetAttachmentsCountByChatId(chatId)
		const attachmentsCount = getAttachmentsCount(state)
		const hasAttachments = attachmentsCount > 0

		const getTrimmedDraftMessageById = chatDetailSelectors.makeGetTrimmedDraftMessageById()
		const trimmedDraftText = getTrimmedDraftMessageById(state, { chatId })

		const finalDraftText = replaceEmoticonsByEmojis(trimmedDraftText, true)

		if (finalDraftText.length === 0 && !hasAttachments) return
		if (isLinkLimitReached(finalDraftText)) {
			notification.warning({
				title: T.translate('general.warning'),
				description: T.translate('chat.sendMsg.links.warning.text'),
			})
			return
		}

		const canJoinChatValue = chatsSelectors.canJoinChat(state)
		const canForceJoinChatValue = chatsSelectors.canForceJoinChat(state)

		if (canJoinChatValue) {
			if (canForceJoinChatValue) {
				batch(() => {
					dispatch(setTextareaFocused(false))
					dispatch(
						openSystemModal({
							name: SystemModalType.Confirm,
							data: {
								title: 'chat.sendMsg.confirm.title',
								text: 'chat.sendMsg.confirm.text',
								onConfirm: () => {
									batch(() => {
										dispatch(sendJoinMessage(chatId, finalDraftText, channel))
										dispatch(setTextareaFocused(true))
									})
								},
							},
						}),
					)
				})
			} else {
				dispatch(sendJoinMessage(chatId, finalDraftText, channel))
			}
		} else {
			dispatch(sendMessage(chatId, finalDraftText, channel))
		}
	}

const chatDetailSlice = createSlice({
	name: 'chatDetail',
	initialState,
	reducers: {
		addAttachmentInit: (state, action) => {
			const { chatId, filename, token } = action.payload
			const attachment: MessageAttachment = { name: filename, token, isUploading: true }

			const chatAttachments = { ...state.draftAttachments[chatId], [token]: attachment }

			state.draftAttachments = { ...state.draftAttachments, [chatId]: chatAttachments }
		},
		addAttachmentSuccess: (state, action) => {
			const { chatId, token } = action.payload
			if (state.draftAttachments[chatId][token]) {
				state.draftAttachments[chatId][token].isUploading = false
			}
		},
		removeAttachment: (state, action) => {
			const { chatId, token } = action.payload
			if (state.draftAttachments[chatId][token]) {
				delete state.draftAttachments[chatId][token]
			}
		},
		clearAttachmentsInChat: (state, action) => {
			const { chatId } = action.payload
			state.draftAttachments[chatId] = {}
		},
		setDraftMessage: (state, { payload }: PayloadAction<{ chatId: string; text: string }>) => {
			const { chatId, text } = payload
			state.draftMessages[chatId] = text
		},
		setChannel: (state, { payload }: PayloadAction<ChatChannel>) => {
			state.channel = payload
		},
		setUnreadMessage: (state, { payload }: PayloadAction<string | null>) => {
			state.unreadMessage = payload
		},
		setHoldMessageListScrollPosition: (state, { payload }: PayloadAction<boolean>) => {
			state.holdMessageListScrollPosition = payload
		},
		setChatDetailVisible: (state, { payload }: PayloadAction<boolean>) => {
			state.isChatDetailVisible = payload
		},
	},
	extraReducers: (builder) => {
		// join chat
		builder.addCase(joinChatThunk.fulfilled, (state) => {
			state.isJoiningChat = false
		})
		builder.addCase(joinChatThunk.pending, (state) => {
			state.isJoiningChat = true
		})
		builder.addCase(joinChatThunk.rejected, (state) => {
			state.isJoiningChat = false
		})
		// leave chat
		builder.addCase(leaveChatThunk.fulfilled, (state) => {
			state.isLeavingChat = false
		})
		builder.addCase(leaveChatThunk.rejected, (state) => {
			state.isLeavingChat = false
		})
		builder.addCase(leaveChatThunk.pending, (state) => {
			state.isLeavingChat = true
		})
		// assign agent
		builder.addCase(assignAgentThunk.fulfilled, (state) => {
			state.isAssigningChat = false
		})
		builder.addCase(assignAgentThunk.pending, (state) => {
			state.isAssigningChat = true
		})
		builder.addCase(assignAgentThunk.rejected, (state) => {
			state.isAssigningChat = false
		})
		// upload file
		builder.addCase(uploadFileThunk.fulfilled, (state) => {
			state.isUploading = false
		})
		builder.addCase(uploadFileThunk.rejected, (state) => {
			state.isUploading = false
		})
		builder.addCase(uploadFileThunk.pending, (state) => {
			state.isUploading = true
		})
		// sending mail
		builder.addCase(sendChatTranscriptToEmailThunk.fulfilled, (state) => {
			state.isSendMailPending = false
		})
		builder.addCase(sendChatTranscriptToEmailThunk.rejected, (state) => {
			state.isSendMailPending = false
		})
		builder.addCase(sendChatTranscriptToEmailThunk.pending, (state) => {
			state.isSendMailPending = true
		})
		// resolve chat
		builder.addCase(resolveChatThunk.fulfilled, (state) => {
			state.isResolvingChat = false
		})
		builder.addCase(resolveChatThunk.pending, (state) => {
			state.isResolvingChat = true
		})
		builder.addCase(resolveChatThunk.rejected, (state) => {
			state.isResolvingChat = false
		})
	},
})

const { actions, reducer } = chatDetailSlice
export default reducer
export const {
	addAttachmentInit,
	addAttachmentSuccess,
	removeAttachment,
	clearAttachmentsInChat,
	setDraftMessage,
	setChannel,
	setUnreadMessage,
	setHoldMessageListScrollPosition,
	setChatDetailVisible,
} = actions

// Selectors
const getAttachments = (state: ChatDetailRootState) => state.chatDetail.draftAttachments
const getDraftMessages = (state: ChatDetailRootState) => state.chatDetail.draftMessages
const getChatChannel = (state: DashboardState) => state.chatDetail.channel?.type ?? null
const getChatChannelId = (state: DashboardState) => state.chatDetail.channel?.id ?? null
const getChatChannelData = (state: DashboardState) => state.chatDetail.channel
const getUnreadMessage = (state: ChatDetailRootState) => state.chatDetail.unreadMessage
const holdMessageListScrollPosition = (state: ChatDetailRootState) => state.chatDetail.holdMessageListScrollPosition
const getIsJoiningChat = (state: ChatDetailRootState) => state.chatDetail.isJoiningChat
const getIsLeavingChat = (state: ChatDetailRootState) => state.chatDetail.isLeavingChat
const getIsUploadingFile = (state: ChatDetailRootState) => state.chatDetail.isUploading
const getIsSendEmailPending = (state: ChatDetailRootState) => state.chatDetail.isSendMailPending
const getIsResolvingChat = (state: ChatDetailRootState) => state.chatDetail.isResolvingChat
const getIsChatDetailVisible = (state: ChatDetailRootState) => state.chatDetail.isChatDetailVisible

const makeGetAttachmentsByChatId = (chatId: string | null) => {
	return createSelector([getAttachments], (attachments) => {
		if (!chatId) return null
		return attachments[chatId]
	})
}

const makeGetAttachmentsCountByChatId = (chatId: string | null) => {
	return createSelector([getAttachments], (attachments) => {
		if (!chatId || !attachments[chatId]) return 0
		return Object.keys(attachments[chatId]).length
	})
}

const makeGetAttachmentTokensByChatId = (chatId: string) => {
	return createSelector([getAttachments], (attachments) => {
		if (!chatId || !attachments[chatId]) return []
		return Object.values(attachments[chatId]).map((value) => value.token)
	})
}

const makeAreAttachmentsUploaded = (chatId: string) => {
	return createSelector([getAttachments], (attachments) => {
		if (!chatId || !attachments[chatId]) return []
		const notUploaded = Object.values(attachments[chatId]).filter((value) => value.isUploading)
		return notUploaded.length === 0
	})
}

const makeGetDraftMessageById = () =>
	createSelector([getDraftMessages, getChatId], (draftMessages, chatId): string => {
		if (chatId && draftMessages[chatId]) {
			return draftMessages[chatId]
		}
		return ''
	})

const makeGetTrimmedDraftMessageById = () =>
	createSelector([getDraftMessages, getChatId], (draftMessages, chatId): string => {
		if (chatId && draftMessages[chatId]) {
			return trimDraftMessage(draftMessages[chatId])
		}
		return ''
	})

const makeGetHasDraftMessageById = () =>
	createSelector([getDraftMessages, getChatId], (draftMessages, chatId): boolean => {
		return !!chatId && !!draftMessages[chatId]
	})

export const chatDetailSelectors = {
	makeGetAttachmentsCountByChatId,
	makeGetAttachmentsByChatId,
	makeGetAttachmentTokensByChatId,
	makeAreAttachmentsUploaded,
	makeGetDraftMessageById,
	makeGetTrimmedDraftMessageById,
	makeGetHasDraftMessageById,
	getChatChannel,
	getChatChannelData,
	getUnreadMessage,
	holdMessageListScrollPosition,
	getChatChannelId,
	getIsJoiningChat,
	getIsLeavingChat,
	getIsUploadingFile,
	getIsSendEmailPending,
	getIsResolvingChat,
	getIsChatDetailVisible,
}
