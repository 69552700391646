import { OnboardingResponse, OnboardingStepCode, OnboardingType } from 'shared/models/Onboarding'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'onboarding'

const finishStep = (code: OnboardingStepCode, finished: boolean) => {
	return apiClient.post<OnboardingResponse>(`${API_RESOURCE}/step`, { code, finished })
}

const fetchOnboardingData = () => {
	return apiClient.get<OnboardingResponse>(`${API_RESOURCE}`)
}

const patchOnboarding = (type: OnboardingType) => {
	return apiClient.patch<OnboardingResponse>(`${API_RESOURCE}`, { type })
}

export const onboardingApi = { finishStep, fetchOnboardingData, patchOnboarding }
