import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
	DateRangePreset,
	HydratedRating,
	Rating,
	RatingRatingTarget,
	RatingSearchRequest,
	RatingSearchRequestOrder,
	RatingStats,
	RatingStatsRequest,
} from 'models'
import { AppThunkAction, DashboardState } from 'types'
import { getDateRangeMap, getTodayDateEnd } from 'utils'
import { fetchAiChatbotStatisticsSummaryByRange } from 'modules/aiChatbotStatistics'
import { contactsApi } from 'modules/contacts/api'

import { ratingApi } from './api'
import { AI_RATING_FETCH_BATCH_SIZE } from './constants'
import { aiRatingInterval, RatingStatsInterval, ratingStatsIntervalToRangeType } from './types'

export type AiRatingRootState = Pick<DashboardState, 'aiRating'>

const aiRatingsAdapter = createEntityAdapter<HydratedRating>({
	selectId: (conversation) => conversation.messageId,
})

const initialState = aiRatingsAdapter.getInitialState({
	stats: [] as RatingStats[],
	order: RatingSearchRequestOrder.NewestToOldest as RatingSearchRequestOrder,
	filter: {
		ratingValue: null as null | Rating['ratingValue'],
		interval: DateRangePreset.Past30D as RatingStatsInterval,
	},
	isFetchingConversations: false,
	isFetchingStats: false,
	isChatMissingModalOpen: false,
})

export const fetchAiConversationRatings = createAsyncThunk(
	'aiRating/FETCH_AI_CONVERSATIONS',
	async (args: { loadMore?: boolean } | undefined, { getState }) => {
		const { loadMore = false } = args || {}

		const state = getState() as AiRatingRootState

		const interval = getFilterInterval(state)
		const order = getOrder(state)
		const ratingValue = getFilterRatingValue(state)
		const total = getTotal(state)

		const { start, end } = getDateRangeMap()[interval]

		const updatedAt = start && end ? { to: end.format(), from: start.format() } : undefined

		const data: RatingSearchRequest = {
			ratingTarget: RatingRatingTarget.Ai,
			take: AI_RATING_FETCH_BATCH_SIZE,
			skip: loadMore ? total : 0,
			order,
			...(updatedAt && { updatedAt }),
			...(ratingValue && { ratingValue }),
		}

		const ratings = await ratingApi.searchRatings(data)

		const contactIds = ratings.data.map((rating) => rating.contactId).filter((id) => !!id)

		const contacts = await contactsApi.fetchContacts({
			query: [
				{
					name: 'id',
					op: 'eq',
					value: contactIds,
				},
			],
		})

		const hydratedRatings = ratings.data.map((rating) => {
			const contact = contacts.items.find(({ id }) => id === rating.contactId) ?? null
			return { ...rating, contact }
		})

		return {
			data: hydratedRatings,
		}
	},
)

export const fetchAiConversationsRatingStats = createAsyncThunk('aiRating/FETCH_STATS', async (_, { getState }) => {
	const interval = getFilterInterval(getState() as AiRatingRootState)

	const data: RatingStatsRequest = {
		ratingTarget: RatingRatingTarget.Ai,
		take: 1,
		interval: aiRatingInterval[interval],
		until: getTodayDateEnd().format(),
	}

	return ratingApi.getRatingStats(data)
})

export const fetchAiConversationsRatingWithStats = (): AppThunkAction => (dispatch, getState) => {
	const interval = getFilterInterval(getState() as AiRatingRootState)
	const rangeType = ratingStatsIntervalToRangeType[interval]

	dispatch(fetchAiConversationsRatingStats())
	dispatch(fetchAiConversationRatings())
	dispatch(fetchAiChatbotStatisticsSummaryByRange(rangeType))
}

const aiRatingSlice = createSlice({
	name: 'aiRating',
	initialState,
	reducers: {
		setOrder: (state, { payload }: PayloadAction<RatingSearchRequestOrder>) => {
			state.order = payload
		},
		setFilterRatingValue: (state, { payload }: PayloadAction<Rating['ratingValue'] | null>) => {
			state.filter.ratingValue = state.filter.ratingValue === payload ? null : payload
		},
		setStatsFilterInterval: (state, { payload }: PayloadAction<RatingStatsInterval>) => {
			state.filter.interval = payload
		},
		setIsChatMissingModalOpen: (state, { payload }: PayloadAction<boolean>) => {
			state.isChatMissingModalOpen = payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAiConversationRatings.pending, (state) => {
				state.isFetchingConversations = true
			})
			.addCase(fetchAiConversationRatings.fulfilled, (state, { payload, meta }) => {
				state.isFetchingConversations = false
				if (meta.arg?.loadMore) {
					aiRatingsAdapter.addMany(state, payload.data)
				} else {
					aiRatingsAdapter.setAll(state, payload.data)
				}
			})
			.addCase(fetchAiConversationRatings.rejected, (state) => {
				state.isFetchingConversations = false
			})
		builder
			.addCase(fetchAiConversationsRatingStats.pending, (state) => {
				state.isFetchingStats = true
			})
			.addCase(fetchAiConversationsRatingStats.fulfilled, (state, { payload }) => {
				state.stats = payload.data
				state.isFetchingStats = false
			})
			.addCase(fetchAiConversationsRatingStats.rejected, (state) => {
				state.isFetchingStats = false
			})
	},
})

const { reducer, actions } = aiRatingSlice

export const { setOrder, setFilterRatingValue, setStatsFilterInterval, setIsChatMissingModalOpen } = actions

export default reducer

const entitySelectors = aiRatingsAdapter.getSelectors<AiRatingRootState>((state) => state.aiRating)
const getRatings = (state: AiRatingRootState) => entitySelectors.selectAll(state)
const getTotal = (state: AiRatingRootState) => entitySelectors.selectTotal(state)
const getOrder = (state: AiRatingRootState) => state.aiRating.order
const getFilterRatingValue = (state: AiRatingRootState) => state.aiRating.filter.ratingValue
const getStats = (state: AiRatingRootState) => state.aiRating.stats
const getFilterInterval = (state: AiRatingRootState) => state.aiRating.filter.interval
const getIsFetchingConversations = (state: AiRatingRootState) => state.aiRating.isFetchingConversations
const getIsFetchingStats = (state: AiRatingRootState) => state.aiRating.isFetchingStats
const getIsChatMissingModalOpen = (state: AiRatingRootState) => state.aiRating.isChatMissingModalOpen

const getComputedStats = createSelector([getStats], (stats) => {
	return (
		stats[0] ?? {
			countTotal: 0,
			countPositive: 0,
			countNegative: 0,
			countNeutral: 0,
			averagePercentage: 0,
			averagePercentageTrend: null,
		}
	)
})

const getRatingByIndex = (index: number) => {
	return createSelector([getRatings], (ratings) => {
		return ratings[index] ?? null
	})
}

export const aiRatingSelectors = {
	getRatings,
	getTotal,
	getOrder,
	getFilterRatingValue,
	getStats,
	getFilterInterval,
	getComputedStats,
	getIsFetchingConversations,
	getIsFetchingStats,
	getRatingByIndex,
	getIsChatMissingModalOpen,
}
