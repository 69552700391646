import { mdiNumeric1, mdiNumeric2, mdiNumeric3, mdiPlayCircleOutline } from '@mdi/js'

import {
	ChatbotAiConfidenceValue,
	ChatbotAiEmojiValue,
	ChatbotAiSkillHandoverType,
	ChatbotAiSkillProductRecommendationType,
	ChatbotAiTalkativenessValue,
	ChatbotAiToneValue,
	ChatbotWorkflow,
} from 'shared/models/Chatbot'

import { ChatbotWorkflowProfileInputs, ChatbotWorkflowSection, ChatbotWorkflowSkills } from './types'

export const WORKFLOW_FORM_ID = 'chatbot-workflow-form'
export const FORM_BEHAVIOR = 'behavior'
export const FORM_SKILLS = 'skills'
export const FORM_SOURCES = 'sources'

export const DEBOUNCE_TIME = 1000

export const DEFAULT_SKILL: ChatbotWorkflowSkills = 'productRecommendation'

export const DEFAULT_WORKFLOW: ChatbotWorkflow = {
	type: 'ai',
	behavior: {
		tone: ChatbotAiToneValue.Friendly,
		talkativeness: ChatbotAiTalkativenessValue.Brief,
		emoji: ChatbotAiEmojiValue.No,
		confidence: ChatbotAiConfidenceValue.Confident,
	},
	profile: {
		name: '',
		avatar: '',
	},
	skills: {
		productRecommendation: { type: ChatbotAiSkillProductRecommendationType.Disabled },
		handover: { type: ChatbotAiSkillHandoverType.Disabled },
	},
	sources: [],
	instructions: [],
}

export const sectionsDataMap: Record<ChatbotWorkflowSection, { title: string; icon: string; description: string }> = {
	[ChatbotWorkflowSection.Basics]: {
		title: 'chatbot.workflow.section.basics.title',
		icon: mdiNumeric1,
		description: 'chatbot.workflow.section.basics.description',
	},
	[ChatbotWorkflowSection.Training]: {
		title: 'chatbot.workflow.section.training.title',
		icon: mdiNumeric2,
		description: 'chatbot.workflow.section.training.description',
	},
	[ChatbotWorkflowSection.Skills]: {
		title: 'chatbot.workflow.skills.title',
		icon: mdiNumeric3,
		description: 'chatbot.workflow.skills.description',
	},
	[ChatbotWorkflowSection.Preview]: {
		title: 'chatbot.workflow.section.preview.title',
		icon: mdiPlayCircleOutline,
		description: 'chatbot.workflow.section.preview.description',
	},
}

export const profileInputsDataMap: Record<
	ChatbotWorkflowProfileInputs,
	{ name: ChatbotWorkflowProfileInputs; title: string; description: string }
> = {
	tone: {
		name: 'tone',
		title: 'chatbot.workflow.profile.tone.title',
		description: 'chatbot.workflow.profile.tone.description',
	},
	talkativeness: {
		name: 'talkativeness',
		title: 'chatbot.workflow.profile.talkativeness.title',
		description: 'chatbot.workflow.profile.talkativeness.description',
	},
	confidence: {
		name: 'confidence',
		title: 'chatbot.workflow.profile.confidence.title',
		description: 'chatbot.workflow.profile.confidence.description',
	},
	emoji: {
		name: 'emoji',
		title: 'chatbot.workflow.profile.emoji.title',
		description: 'chatbot.workflow.profile.emoji.description',
	},
}

export const skillsDataMap: Record<
	ChatbotWorkflowSkills,
	{ name: ChatbotWorkflowSkills; title: string; description: string }
> = {
	productRecommendation: {
		name: 'productRecommendation',
		title: 'chatbot.workflow.skills.productRecommendation',
		description: 'chatbot.workflow.skills.productRecommendation.description',
	},
	handover: {
		name: 'handover',
		title: 'chatbot.workflow.skills.handover',
		description: 'chatbot.workflow.skills.handover.description',
	},
}

export const chatbotWorkflowTestIds = {
	behavior: {
		tone: 'chatbot-workflow-profile-input-tone',
		talkativeness: 'chatbot-workflow-profile-input-talkativeness',
		emoji: 'chatbot-workflow-profile-input-emoji',
		confidence: 'chatbot-workflow-profile-input-confidence',
	},
	skills: {
		item: {
			name: 'chatbot-workflow-skills-item-name',
			button: 'chatbot-workflow-skills-item-button',
			type: 'chatbot-workflow-skills-item-type',
		},
		skillsList: 'chatbot-workflow-skills-list',
	},
	sources: {
		addNewButton: 'chatbot-workflow-sources-add-new-button',
		sourcesList: 'chatbot-workflow-sources-list',
		empty: 'chatbot-workflow-sources-empty',
		emptyAddNewButton: 'chatbot-workflow-sources-empty-add-new-button',
	},
	continueBtn: 'chatbot-workflow-form-continue-btn',
	publishBtn: 'chatbot-workflow-form-publish-btn',
}
