import { batch } from 'react-redux'
import { createSelector, createSlice, PayloadAction, unwrapResult } from '@reduxjs/toolkit'

import {
	ChatbotActionContactPropertyName,
	ChatbotActionMessageData,
	ChatbotActionType,
	ChatbotCategory,
	ChatbotGroupType,
	HandoverType,
} from 'models'
import { AppLang } from 'shared/types'
import { AppThunkAction, DashboardState, Dictionary } from 'types'
import { TranslationService as T } from 'shared/services'
import { flashMessage } from 'shared/utils'
import { routes } from 'configuration/routes'
import { navigateTo } from 'utils'
import { normalize } from 'utils/normalize'
import {
	chatbotDetailSelectors,
	findInteractionActionByType,
	generateAiWelcomeMessageTitle,
} from 'modules/chatbotDetail'
import { fetchChatbot, postChatbot, updateChatbot } from 'modules/chatbotDetail/actions'
import { chatbotSelectors, fetchChatbotsOnboarding } from 'modules/chatbots'
import {
	chatbotTemplatesSelectors,
	fetchChatbotOnboardingTemplate,
	fetchChatbotTemplate,
	fetchChatbotTemplates,
} from 'modules/chatbotTemplates'
import { WIDGET_LANG_DEFAULT } from 'modules/widget'

import { DEFAULT_STEP, messageStepTextsMap, replyStepTextsMap } from './constants'
import {
	ChatbotQuestionNode,
	ChatbotQuestionNodeInteraction,
	ChatbotWizardReplyFormData,
	ChatbotWizardSource,
	ChatbotWizardStep,
	ChatbotWizardStepType,
	CreateChatbotFromWizardConfig,
} from './types'
import { mapChatbotToQuestionNode, mapQuestionNodesToInteractions } from './utils'

export type ChatbotWizardRootState = Pick<DashboardState, 'chatbotWizard'>
export type ChatbotWizardState = typeof initialState

export const initialState = {
	step: DEFAULT_STEP,
	isDirty: false,
	isTemplate: false,
	isInitialized: false,
	chatbotData: null as null | ChatbotQuestionNode,
	interactions: null as null | Dictionary<ChatbotQuestionNodeInteraction>,
	stepsArray: [] as ChatbotWizardStep[],
	lastStepIndex: 0,
	source: ChatbotWizardSource.Url,
	hasCustomReplies: false,
	chatbotLang: WIDGET_LANG_DEFAULT as AppLang,
	error: false,
}

export const initChatbotWizard =
	(source: ChatbotWizardSource, lang: AppLang): AppThunkAction =>
	async (dispatch, getState) => {
		let isTemplate = true
		dispatch(setIsInitialized(false))
		try {
			// fetch all chatbots
			const responseChatbots = await dispatch(fetchChatbotsOnboarding())
			await unwrapResult(responseChatbots)
			const chatbot = chatbotSelectors.getChatbotOnboarding(getState())

			// test if exists Onboarding bot
			if (chatbot) {
				// fetch existing chatbot
				const chatbotId = chatbot.id
				const responseChatbotDetail = await dispatch(fetchChatbot(chatbotId))
				await unwrapResult(responseChatbotDetail)
				isTemplate = false
			}

			// fetch template
			const responseTemplate = await dispatch(fetchChatbotOnboardingTemplate(lang))
			await unwrapResult(responseTemplate)

			const template = chatbotTemplatesSelectors.getOnboardingChatbotTemplate(getState())
			if (!template) {
				throw new Error('error')
			}

			const wizardData = getDataForChatbotWizard(getState())
			if (!wizardData) {
				throw new Error('error')
			}

			const { data, hasCustomReplies } = wizardData

			batch(() => {
				dispatch(initWizardSteps(data))
				dispatch(setChatbotData({ data, isTemplate, hasCustomReplies }))
				dispatch(initWizardSource(source))
				dispatch(setIsInitialized(true))
			})
		} catch {
			dispatch(setError({ error: true }))
		}
	}

export const initMessageWizard = (): AppThunkAction => async (dispatch, getState) => {
	try {
		await dispatch(fetchChatbotTemplates())
		const template = chatbotTemplatesSelectors.getCustomTemplatesAutoMessage(getState())
		if (!template) {
			throw new Error('error')
		}

		const { id } = template[0]
		await dispatch(fetchChatbotTemplate(id))

		const wizardData = getDataForChatbotWizard(getState())
		if (!wizardData) {
			throw new Error('error')
		}

		const { data } = wizardData

		batch(() => {
			dispatch(initWizardSteps(data))
			dispatch(setChatbotData({ data, isTemplate: true, hasCustomReplies: false }))
			dispatch(initWizardSource(ChatbotWizardSource.AiOnboarding))
			dispatch(setIsInitialized(true))
		})
	} catch {
		dispatch(setError({ error: true }))
	}
}

export const initWizardSteps =
	(data: ChatbotQuestionNode): AppThunkAction =>
	(dispatch) => {
		const stepsArray: ChatbotWizardStep[] = []
		stepsArray.push({ type: ChatbotWizardStepType.Message, id: data.id })

		data.interactions.forEach((interaction) => {
			stepsArray.push({ type: ChatbotWizardStepType.Reply, id: interaction.id })
		})

		dispatch(setStepsArray({ stepsArray }))
	}

// TODO: TEMP - creating auto message instead of chatbot (changed type and title)
export const createChatbotFromWizard =
	({ isActive, onSuccess, appendReplies = true }: CreateChatbotFromWizardConfig): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const selectedChatbot = chatbotDetailSelectors.getSelectedChatbot(state)
		const selectedTemplate = chatbotTemplatesSelectors.getSelectedTemplate(state)
		const chatbotData = getChatbotData(state)
		const repliesWizard = appendReplies ? getInteractions(state) : {}
		const isTemplate = getIsTemplate(state)

		const baseChatbotData = isTemplate ? selectedTemplate : selectedChatbot

		if (!baseChatbotData || !chatbotData || !repliesWizard) return

		const createdChatbot = {
			...baseChatbotData,
			isActive,
			interactions: mapQuestionNodesToInteractions({
				interactions: baseChatbotData.interactions,
				questionNodes: Object.values(repliesWizard),
				welcomeText: chatbotData.textMessage,
			}),
		}

		const actionResult = isTemplate
			? await dispatch(
					postChatbot({
						...createdChatbot,
						groupType: ChatbotGroupType.Message,
						type: ChatbotCategory.Faq,
						templateType: ChatbotCategory.ChatbotOnboarding,
						title: T.translate('autoMessage.title.firstMessage'),
					}),
				)
			: await dispatch(updateChatbot({ botId: chatbotData.id, changes: createdChatbot }))

		if (postChatbot.fulfilled.match(actionResult) || updateChatbot.fulfilled.match(actionResult)) {
			onSuccess(actionResult.payload.id)

			const wizardData = getDataForChatbotWizard(getState())
			if (!wizardData) {
				return
			}
			const { data, hasCustomReplies } = wizardData
			dispatch(setChatbotData({ data, isTemplate: false, hasCustomReplies }))
		} else {
			dispatch(setError({ error: true }))
		}
	}

export const createAutoMessageFromWizard = (): AppThunkAction => async (dispatch, getState) => {
	try {
		const state = getState()
		const selectedTemplate = chatbotTemplatesSelectors.getSelectedTemplate(state)
		const chatbotData = getChatbotData(state)
		if (!chatbotData || !selectedTemplate) return
		const title = generateAiWelcomeMessageTitle()
		await dispatch(
			postChatbot({
				...selectedTemplate,
				title,
				type: selectedTemplate.botType,
				templateType: selectedTemplate.type,
				isActive: true,
				interactions: mapQuestionNodesToInteractions({
					interactions: selectedTemplate?.interactions,
					questionNodes: [],
					welcomeText: chatbotData.textMessage,
				}),
			}),
		)
		navigateTo(routes.aiAutomations.path)
	} catch {
		flashMessage.error('general.error')
	}
}

export const goToNextWizardStep = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const isExistNextStep = getIsExistNextStep(state)
	const currentStep = getWizardStep(state)
	if (isExistNextStep) {
		dispatch(setWizardStep({ step: currentStep + 1 }))
	}
}

export const goToPrevWizardStep = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const isExistPrevStep = getIsExistPrevStep(state)
	const currentStep = getWizardStep(state)
	if (isExistPrevStep) {
		dispatch(setWizardStep({ step: currentStep - 1 }))
	}
}

const chatbotWizardSlice = createSlice({
	name: 'chatbotWizard',
	initialState,
	reducers: {
		initWizardStep: (state) => {
			state.step = DEFAULT_STEP
		},
		initWizardSource: (state, { payload }: PayloadAction<ChatbotWizardSource>) => {
			state.source = payload
		},
		setChatbotData: (
			state,
			{ payload }: PayloadAction<{ data: ChatbotQuestionNode; isTemplate: boolean; hasCustomReplies: boolean }>,
		) => {
			const { data, isTemplate, hasCustomReplies } = payload
			state.chatbotData = data
			state.interactions = normalize('id', data.interactions)
			state.isTemplate = isTemplate
			state.isDirty = false
			state.hasCustomReplies = hasCustomReplies
		},
		setIsInitialized: (state, { payload }: PayloadAction<boolean>) => {
			state.isInitialized = payload
		},
		setStepsArray: (state, { payload }: PayloadAction<{ stepsArray: ChatbotWizardStep[] }>) => {
			const { stepsArray } = payload
			state.stepsArray = stepsArray
			state.lastStepIndex = stepsArray.length - 1
		},
		setWizardStep: (state, { payload }: PayloadAction<{ step: number }>) => {
			const { step } = payload
			state.step = step
		},
		setTextMessage: (state, { payload }: PayloadAction<{ message: string }>) => {
			const { message } = payload
			if (!state.chatbotData) return
			state.chatbotData.textMessage = message
			state.isDirty = true
		},
		setQuestionAndAnswer: (
			state,
			{ payload }: PayloadAction<{ id: string; data: Partial<ChatbotWizardReplyFormData> }>,
		) => {
			const { id, data } = payload
			if (state.interactions && state.interactions[id]) {
				state.interactions[id] = { ...state.interactions[id], ...data }
				state.isDirty = true
			}
		},
		setError: (state, { payload }: PayloadAction<{ error: boolean }>) => {
			const { error } = payload
			state.error = error
		},
		setChatbotLang: (state, { payload }: PayloadAction<{ lang: AppLang }>) => {
			const { lang } = payload
			state.chatbotLang = lang
		},
	},
})

const { reducer, actions } = chatbotWizardSlice
export default reducer
export const {
	setWizardStep,
	initWizardStep,
	setStepsArray,
	setChatbotData,
	setQuestionAndAnswer,
	setTextMessage,
	setError,
	initWizardSource,
	setChatbotLang,
	setIsInitialized,
} = actions

const getWizardStep = (state: ChatbotWizardRootState) => state.chatbotWizard.step
const getWizardSource = (state: ChatbotWizardRootState) => state.chatbotWizard.source
const getIsDirty = (state: ChatbotWizardRootState) => state.chatbotWizard.isDirty
const getChatbotData = (state: ChatbotWizardRootState) => state.chatbotWizard.chatbotData
const getStepsArray = (state: ChatbotWizardRootState) => state.chatbotWizard.stepsArray
const getLastStepIndex = (state: ChatbotWizardRootState) => state.chatbotWizard.lastStepIndex
const getInteractions = (state: ChatbotWizardRootState) => state.chatbotWizard.interactions
const getIsTemplate = (state: ChatbotWizardRootState) => state.chatbotWizard.isTemplate
const getIsError = (state: ChatbotWizardRootState) => state.chatbotWizard.error
const getIsInitialized = (state: ChatbotWizardRootState) => state.chatbotWizard.isInitialized
const getHasCustomReplies = (state: ChatbotWizardRootState) => state.chatbotWizard.hasCustomReplies
const getChatbotLang = (state: DashboardState) => state.chatbotWizard.chatbotLang

const getIsExistNextStep = createSelector([getWizardStep, getLastStepIndex], (currentStep, lastStepIndex) => {
	return currentStep < lastStepIndex
})

const getIsExistPrevStep = createSelector([getWizardStep], (currentStep) => {
	return currentStep > 0
})

const getIsLastWizardStep = createSelector([getWizardStep, getLastStepIndex], (currentStep, lastStepIndex) => {
	return currentStep === lastStepIndex
})

const getReplyStepTexts = createSelector([getWizardStep, getWizardSource], (currentStep, source) => {
	if (source === ChatbotWizardSource.AiOnboarding)
		return {
			title: '',
			text: '',
			buttonText: '',
		}

	return replyStepTextsMap[source][currentStep]
})

const getMessageStepTexts = createSelector([getWizardSource], (source) => {
	return messageStepTextsMap[source]
})

const getRepliesCount = createSelector([getStepsArray], (steps) => {
	if (!steps) return 0

	return steps.filter((step) => step.type === ChatbotWizardStepType.Reply).length
})

const getTextMessage = createSelector([getChatbotData], (data) => {
	if (!data) return ''
	return data.textMessage
})

const getQuestionAndAnswer = createSelector(
	[getWizardStep, getStepsArray, getInteractions],
	(currentStep, steps, interactions) => {
		const { id } = steps[currentStep]

		if (!interactions || !interactions[id] || steps.length === 0) return null
		return { id, answer: interactions[id].answer, question: interactions[id].question }
	},
)

const getCurrentReplyId = createSelector([getWizardStep, getStepsArray], (currentStep, steps) => {
	if (steps.length === 0) return null
	return steps[currentStep].id
})

const getCurrentStepType = createSelector([getWizardStep, getStepsArray], (currentStep, steps) => {
	if (steps.length === 0) return null
	return steps[currentStep].type
})

const getDataForChatbotWizard = createSelector(
	[chatbotDetailSelectors.getSelectedChatbot, chatbotTemplatesSelectors.getSelectedTemplate],
	(chatbot, template): { data: ChatbotQuestionNode; hasCustomReplies: boolean } | null => {
		if (chatbot) {
			const chatbotMessageAction = findInteractionActionByType(chatbot.interactions[0], ChatbotActionType.Message)
			if (chatbotMessageAction) {
				const chatbotMessageActionData = chatbotMessageAction.data as ChatbotActionMessageData
				const hasCustomReplies = chatbotMessageActionData.replies.length > 0
				const { id, interactions, isActive } = chatbot
				if (hasCustomReplies) {
					return {
						data: mapChatbotToQuestionNode({ id, isActive, interactions }),
						hasCustomReplies,
					}
				}

				if (template) {
					const templateMessageAction = findInteractionActionByType(template.interactions[0], ChatbotActionType.Message)
					if (templateMessageAction) {
						const data = templateMessageAction.data as ChatbotActionMessageData
						const templateReplies = data.replies
						const newInteractions = [
							{
								...interactions[0],
								actions: [
									{
										...templateMessageAction,
										data: {
											text: chatbotMessageActionData.text,
											replies: templateReplies,
											group: null,
											appId: '',
											actionId: '',
											tagIds: [],
											botId: '',
											property: ChatbotActionContactPropertyName.Name,
											url: null,
											online: { type: HandoverType.Inbox, message: '' },
											offline: { type: HandoverType.Inbox, message: '' },
										},
									},
								],
							},
							template.interactions[1],
							template.interactions[2],
							template.interactions[3],
						]

						return {
							data: mapChatbotToQuestionNode({ id, isActive, interactions: newInteractions }),
							hasCustomReplies,
						}
					}
				}
			}
		}

		if (template) {
			const { id, interactions } = template
			return {
				data: mapChatbotToQuestionNode({ id, interactions, isActive: false }),
				hasCustomReplies: false,
			}
		}

		return null
	},
)

export const chatbotWizardSelectors = {
	getIsExistNextStep,
	getWizardStep,
	getIsDirty,
	getStepsArray,
	getIsLastWizardStep,
	getTextMessage,
	getReplyStepTexts,
	getRepliesCount,
	getQuestionAndAnswer,
	getIsTemplate,
	getIsError,
	getInteractions,
	getCurrentReplyId,
	getChatbotData,
	getIsInitialized,
	getWizardSource,
	getDataForChatbotWizard,
	getMessageStepTexts,
	getHasCustomReplies,
	getCurrentStepType,
	getChatbotLang,
}
