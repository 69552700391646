import { batch } from 'react-redux'
import { createSelector, createSlice, isFulfilled, PayloadAction } from '@reduxjs/toolkit'

import {
	ChatbotTrigger,
	ChatbotTriggerCondition,
	ChatbotTriggerConditionName,
	ChatbotTriggerConditionOp,
	ChatbotTriggerConditionOpNumber,
	ChatbotTriggerConditionOpString,
	ChatbotTriggerType,
} from 'models'
import { AppThunkAction, DashboardState } from 'types'
import { flashMessage } from 'shared/utils'
import { chatbotDetailSelectors, PublishButtonPopoverType, setPublishButtonPopover } from 'modules/chatbotDetail'
import { fetchChatbot, updateChatbot } from 'modules/chatbotDetail/actions'
import { getAutomationTranslation } from 'modules/chatbots'
import {
	ChatbotAudienceGroupConditions,
	ChatbotTriggerDateGroupConditions,
	ChatbotTriggerDeviceTypeValue,
	ChatbotTriggerGroupConditions,
	ChatbotTriggerTimeRangeObject,
	chatbotTriggerVisitsCountValue,
	DAYS_IN_WEEK,
	DEFAULT_NUMBER_OF_VISITS_FROM,
	DEFAULT_NUMBER_OF_VISITS_TO,
	getArrayOfConditionGroupValuesBySection,
	getDeviceTypeValue,
	getSortedDateItemsStrings,
	getTimeIntervals,
	getTimesConditionGroup,
	getVisitsCountCondition,
	mapTriggerToSummary,
	MONTHS_IN_YEAR,
	VisitsCountObject,
	VisitsCountRange,
	VisitsCountRangeType,
} from 'modules/chatbotSettings'
import { groupsSelectors } from 'modules/groups'

import { fetchChatbotTrigger, updateChatbotTriggerThunk } from './actions'
import {
	ChatbotSettingsSection,
	ChatbotTriggerAccountStatusValue,
	ChatbotTriggerFormPageDisplayValue,
	chatbotTriggerNewVisitorCondition,
	DEFAULT_TRIGGER_TYPE,
} from './constants'
import { ChatbotTriggerFormData } from './types'
import {
	getSchedulingConditionGroup,
	isAccountStatusCondition,
	isNotAccountStatusCondition,
	isVisitsCountCondition,
} from './utils'

export type ChatbotSettingsRootState = Pick<DashboardState, 'chatbotSettings'>
export type ChatbotSettingsState = typeof initialState

export const initialState = {
	trigger: null as null | ChatbotTrigger,
	activeSection: ChatbotSettingsSection.When,
	isChatbotTriggerUpdatePending: false,
	isTriggerFormDirty: false,
	disableInput: false,
	isAllowedWhenChatIsTriggered: false,
}

export const submitChatbotTriggerForm =
	({
		type,
		delay,
		pageConditions,
		audienceConditions,
		accountStatus,
		visitsCount,
		visitsRange,
		disableInput,
		isAllowedWhenChatIsTriggered,
		pageConditionsOp,
		audienceConditionsOp,
		deviceType,
		months,
		days,
		times,
	}: ChatbotTriggerFormData): AppThunkAction =>
	(dispatch) => {
		batch(() => {
			dispatch(actions.updateTriggerType(type))
			dispatch(actions.updateTriggerOptionDelay(delay))
			dispatch(actions.updateAccountStatusCondition(accountStatus))
			dispatch(actions.updateDeviceTypeCondition(deviceType))
			dispatch(actions.updateVisitsCount({ type: visitsCount, range: visitsRange }))
			dispatch(
				actions.updateTriggerConditions({
					pageConditions: [...pageConditions],
					audienceConditions: [...audienceConditions],
					pageConditionsOp,
					audienceConditionsOp,
				}),
			)
			dispatch(actions.updateSchedulingConditions({ months, days, times }))
			dispatch(actions.updateBehavior({ disableInput, isAllowedWhenChatIsTriggered }))
			dispatch(updateChatbotTrigger())
		})
	}

const updateChatbotTrigger = (): AppThunkAction => async (dispatch, getState) => {
	const state = getState()
	const chatbot = chatbotDetailSelectors.getSelectedChatbot(state)
	const trigger = getTrigger(state)
	const disableInput = getDisabledInput(state)
	const isAllowedWhenChatIsTriggered = getIsAllowedWhenChatIsTriggered(state)
	const isSelectedChatbotActive = chatbotDetailSelectors.getIsSelectedChatbotActive(state)
	const isAutoMessage = chatbotDetailSelectors.getIsSelectedAutoMessage(state)
	const isAiChatbot = chatbotDetailSelectors.getIsSelectedAiChatbot(state)

	if (!chatbot || !trigger) return

	const actionResult = await dispatch(updateChatbotTriggerThunk({ botId: chatbot.id, changes: trigger }))
	dispatch(
		updateChatbot({
			botId: chatbot.id,
			changes: { isActive: isAiChatbot ? undefined : false, disableInput, isAllowedWhenChatIsTriggered },
		}),
	)
	if (updateChatbotTriggerThunk.fulfilled.match(actionResult)) {
		flashMessage.success(
			isSelectedChatbotActive
				? getAutomationTranslation('chatbot.formEditSaved.success', isAutoMessage)
				: 'chatbot.save.success',
			{
				duration: 3000,
				testId: 'flashMessage-success-chatbot-trigger-update',
			},
		)
		if (isSelectedChatbotActive) {
			dispatch(setPublishButtonPopover({ type: PublishButtonPopoverType.SavedAsDraft, show: true }))
		}
	} else {
		flashMessage.error('general.error', { testId: 'flashMessage-error-chatbot-trigger-update' })
	}
}

const chatbotSettingsSlice = createSlice({
	name: 'chatbotSettings',
	initialState,
	reducers: {
		setActiveSection: (state, { payload }: PayloadAction<ChatbotSettingsSection>) => {
			state.activeSection = payload
		},
		setTriggerFormDirty: (state, { payload }: PayloadAction<boolean>) => {
			state.isTriggerFormDirty = payload
		},
		updateTriggerType: (state, { payload }: PayloadAction<ChatbotTriggerType>) => {
			const { trigger } = state
			if (!trigger) return
			trigger.type = payload
		},
		updateTriggerOptionDelay: (state, { payload }: PayloadAction<number>) => {
			const { trigger } = state
			if (!trigger?.options) return
			trigger.options.delay = payload
		},
		updateAccountStatusCondition: (state, { payload }: PayloadAction<string>) => {
			const { trigger } = state
			if (!trigger) return
			const conditions = trigger.conditions.filter(isNotAccountStatusCondition)
			trigger.conditions = [...conditions]
			if (payload !== ChatbotTriggerAccountStatusValue.Any) {
				trigger.conditions.push({
					name: ChatbotTriggerConditionName.AccountStatus,
					op: ChatbotTriggerConditionOpString.Eq,
					value: payload,
				})
			}
		},
		updateTriggerConditions: (state, { payload }: PayloadAction<ChatbotTriggerGroupConditions>) => {
			const { trigger } = state
			if (!trigger) return
			const conditions = trigger.conditions.filter((c) => c.name !== ChatbotTriggerConditionName.ConditionGroup)

			const hasAudienceConditions = payload.audienceConditions.length > 0
			const hasPageConditions = payload.pageConditions.length > 0

			trigger.conditions = [
				...(hasAudienceConditions
					? [
							{
								name: ChatbotTriggerConditionName.ConditionGroup,
								op: payload.audienceConditionsOp,
								section: ChatbotSettingsSection.Audience,
								value: [...payload.audienceConditions],
							},
						]
					: []),
				...(hasPageConditions
					? [
							{
								name: ChatbotTriggerConditionName.ConditionGroup,
								op: payload.pageConditionsOp,
								section: ChatbotSettingsSection.Where,
								value: [...payload.pageConditions],
							},
						]
					: []),
				...conditions,
			]
		},
		updateVisitsCount: (state, { payload }: PayloadAction<{ type: number; range: VisitsCountRange }>) => {
			const { trigger } = state
			if (!trigger) return
			const conditions = trigger.conditions.filter((c) => c.name !== ChatbotTriggerConditionName.VisitsCount)
			trigger.conditions = [...conditions]
			if (payload.type === chatbotTriggerVisitsCountValue.New) {
				trigger.conditions.push(chatbotTriggerNewVisitorCondition)
			}
			if (payload.type === chatbotTriggerVisitsCountValue.Returning) {
				const { from, to, type } = payload.range

				if (type === VisitsCountRangeType.Greater) {
					trigger.conditions.push(getVisitsCountCondition(from, ChatbotTriggerConditionOpNumber.Gt))
				}

				if (type === VisitsCountRangeType.Interval) {
					trigger.conditions.push(
						getVisitsCountCondition(from, ChatbotTriggerConditionOpNumber.Gte),
						getVisitsCountCondition(to, ChatbotTriggerConditionOpNumber.Lte),
					)
				}
			}
		},
		updateSchedulingConditions: (state, { payload }: PayloadAction<ChatbotTriggerDateGroupConditions>) => {
			const { trigger } = state
			if (!trigger) return
			const conditions = trigger.conditions.filter((c) => c.section !== ChatbotSettingsSection.Scheduling)
			trigger.conditions = [...conditions]
			const { months, days, times } = payload
			if (months.length > 0 && months.length < MONTHS_IN_YEAR) {
				trigger.conditions.push(getSchedulingConditionGroup(months, ChatbotTriggerConditionName.MonthOfYear))
			}
			if (days.length > 0 && days.length < DAYS_IN_WEEK) {
				trigger.conditions.push(getSchedulingConditionGroup(days, ChatbotTriggerConditionName.DayOfWeek))
			}
			if (times.length > 0) {
				trigger.conditions.push(getTimesConditionGroup(times))
			}
		},
		updateBehavior: (
			state,
			{ payload }: PayloadAction<{ disableInput: boolean; isAllowedWhenChatIsTriggered: boolean }>,
		) => {
			state.disableInput = payload.disableInput
			state.isAllowedWhenChatIsTriggered = payload.isAllowedWhenChatIsTriggered
		},
		updateDeviceTypeCondition: (state, { payload }: PayloadAction<ChatbotTriggerDeviceTypeValue>) => {
			const { trigger } = state
			if (!trigger) return
			const conditions = trigger.conditions.filter((c) => c.name !== ChatbotTriggerConditionName.DeviceType)
			trigger.conditions = [...conditions]
			if (payload !== ChatbotTriggerDeviceTypeValue.Both) {
				trigger.conditions.push({
					name: ChatbotTriggerConditionName.DeviceType,
					op: ChatbotTriggerConditionOpString.Eq,
					value: payload,
				})
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchChatbotTrigger.fulfilled, (state, { payload }) => {
			state.trigger = payload
		})
		builder
			.addCase(updateChatbotTriggerThunk.pending, (state) => {
				state.isChatbotTriggerUpdatePending = true
			})
			.addCase(updateChatbotTriggerThunk.fulfilled, (state) => {
				state.isChatbotTriggerUpdatePending = false
			})
			.addCase(updateChatbotTriggerThunk.rejected, (state) => {
				state.isChatbotTriggerUpdatePending = false
			})
		builder.addMatcher(isFulfilled(fetchChatbot, updateChatbot), (state, { payload }) => {
			state.trigger = payload.trigger
			state.disableInput = payload.disableInput
			state.isAllowedWhenChatIsTriggered = payload.isAllowedWhenChatIsTriggered
		})
	},
})

const getTrigger = (state: ChatbotSettingsRootState) => state.chatbotSettings.trigger
const getTriggerConditions = (state: ChatbotSettingsRootState) => state.chatbotSettings.trigger?.conditions
const getActiveSection = (state: ChatbotSettingsRootState) => state.chatbotSettings.activeSection
const getIsTriggerFormDirty = (state: ChatbotSettingsRootState) => state.chatbotSettings.isTriggerFormDirty
const getIsUpdateChatbotTriggerPending = (state: ChatbotSettingsRootState) =>
	state.chatbotSettings.isChatbotTriggerUpdatePending
const getDisabledInput = (state: ChatbotSettingsRootState) => state.chatbotSettings.disableInput
const getIsAllowedWhenChatIsTriggered = (state: ChatbotSettingsRootState) =>
	state.chatbotSettings.isAllowedWhenChatIsTriggered

const getTriggerType = createSelector([getTrigger], (trigger): ChatbotTriggerType => {
	if (!trigger) return DEFAULT_TRIGGER_TYPE
	return trigger.type
})

const getTriggerOptionDelay = createSelector([getTrigger], (trigger): number => {
	return trigger?.options?.delay ?? 0
})

const getTriggerPageConditions = createSelector([getTriggerConditions], (conditions): ChatbotTriggerCondition[] => {
	if (!conditions) return []
	const { value } = getArrayOfConditionGroupValuesBySection(ChatbotSettingsSection.Where, conditions)
	return value
})

const getAudiencePageConditions = createSelector([getTriggerConditions], (conditions): ChatbotTriggerCondition[] => {
	if (!conditions) return []
	const { value } = getArrayOfConditionGroupValuesBySection(ChatbotSettingsSection.Audience, conditions)
	return value
})

const getAudienceGroupConditionsState = createSelector(
	[getAudiencePageConditions, groupsSelectors.getGroups],
	(audience, groups): ChatbotAudienceGroupConditions => {
		const groupConditions = audience.filter((c) => c.name === ChatbotTriggerConditionName.VisitorGroup)

		let groupStrings: string[] = []

		groupConditions.forEach((condition) => {
			if (typeof condition.value === 'string') groupStrings = [...groupStrings, condition.value]
		})

		let isGroupInvalid = false
		groupStrings.forEach((groupString) => {
			if (!groups.some((group) => group.key === groupString)) {
				isGroupInvalid = true
			}
		})

		return {
			hasGroupConditions: groupConditions.length > 0,
			hasInvalidGroupConditions: isGroupInvalid,
		}
	},
)

const getAudienceConditionsOp = createSelector(
	[getTriggerConditions],
	(conditions): ChatbotTriggerConditionOp | null => {
		if (!conditions) return null
		const cond = conditions.find((c) => c.section === ChatbotSettingsSection.Audience)

		return cond ? cond.op : null
	},
)

const getPageConditionsOp = createSelector([getTriggerConditions], (conditions): ChatbotTriggerConditionOp | null => {
	if (!conditions) return null
	const cond = conditions.find((c) => c.section === ChatbotSettingsSection.Where)

	return cond ? cond.op : null
})

const getTriggerAccountStatusConditions = createSelector(
	[getTriggerConditions],
	(conditions): ChatbotTriggerAccountStatusValue => {
		if (!conditions) return ChatbotTriggerAccountStatusValue.Any
		const condition = conditions.find(isAccountStatusCondition)
		if (condition?.value) return condition.value as ChatbotTriggerAccountStatusValue
		return ChatbotTriggerAccountStatusValue.Any
	},
)

const getTriggerFormPageDisplayValue = createSelector(
	[getTriggerPageConditions],
	(pageConditions): ChatbotTriggerFormPageDisplayValue => {
		return Array.isArray(pageConditions) && pageConditions.length > 0
			? ChatbotTriggerFormPageDisplayValue.Specific
			: ChatbotTriggerFormPageDisplayValue.All
	},
)

const getVisitsCount = createSelector([getTriggerConditions], (conditions): VisitsCountObject => {
	if (!conditions)
		return {
			triggerType: chatbotTriggerVisitsCountValue.All,
			range: {
				from: DEFAULT_NUMBER_OF_VISITS_FROM,
				to: DEFAULT_NUMBER_OF_VISITS_TO,
				type: VisitsCountRangeType.Greater,
			},
		}
	const visitCountConditions = conditions.filter(isVisitsCountCondition)
	const visitsConditionFrom = visitCountConditions.find(
		(condition) =>
			condition.op === ChatbotTriggerConditionOpNumber.Gte || condition.op === ChatbotTriggerConditionOpNumber.Gt,
	)?.value
	const visitsConditionTo = visitCountConditions.find(
		(condition) => condition.op === ChatbotTriggerConditionOpNumber.Lte,
	)?.value

	let visitRangeType = VisitsCountRangeType.Greater
	let visitTriggerType = chatbotTriggerVisitsCountValue.All
	if (!visitCountConditions || visitCountConditions.length === 0) {
		visitTriggerType = chatbotTriggerVisitsCountValue.All
	} else if (visitCountConditions.length === 1) {
		const visitCountCondition = visitCountConditions[0]
		visitTriggerType =
			visitCountCondition.op === ChatbotTriggerConditionOpNumber.Eq && visitCountCondition.value === 1
				? chatbotTriggerVisitsCountValue.New
				: chatbotTriggerVisitsCountValue.Returning
	} else if (visitCountConditions.length > 1) {
		visitTriggerType = chatbotTriggerVisitsCountValue.Returning
		visitRangeType = VisitsCountRangeType.Interval
	}
	return {
		triggerType: visitTriggerType,
		range: {
			type: visitRangeType,
			from: visitsConditionFrom ? Number(visitsConditionFrom) : DEFAULT_NUMBER_OF_VISITS_FROM,
			to: visitsConditionTo ? Number(visitsConditionTo) : DEFAULT_NUMBER_OF_VISITS_TO,
		},
	}
})

const getDeviceType = createSelector([getTriggerConditions], (conditions): ChatbotTriggerDeviceTypeValue => {
	if (!conditions) return ChatbotTriggerDeviceTypeValue.Both
	return getDeviceTypeValue(conditions)
})

const getMonths = createSelector([getTriggerConditions], (conditions): string[] => {
	return getSortedDateItemsStrings(ChatbotTriggerConditionName.MonthOfYear, conditions)
})

const getDays = createSelector([getTriggerConditions], (conditions): string[] => {
	return getSortedDateItemsStrings(ChatbotTriggerConditionName.DayOfWeek, conditions)
})

const getTimes = createSelector([getTriggerConditions], (conditions): ChatbotTriggerTimeRangeObject[] => {
	return getTimeIntervals(conditions)
})

const makeGetSummaryBySection = (section: string) => {
	return createSelector(
		[getTrigger, getDisabledInput, getIsAllowedWhenChatIsTriggered, groupsSelectors.getGroups],
		(trigger, isInputDisabled, isAllowedWhenChatIsTriggered, groups) => {
			return mapTriggerToSummary(trigger, isInputDisabled, isAllowedWhenChatIsTriggered, groups)[section]
		},
	)
}

const { actions, reducer } = chatbotSettingsSlice

export const { setActiveSection, setTriggerFormDirty } = actions

export const chatbotSettingsSelectors = {
	getActiveSection,
	getTriggerType,
	getTrigger,
	getTriggerConditions,
	getIsTriggerFormDirty,
	getTriggerOptionDelay,
	getIsUpdateChatbotTriggerPending,
	getTriggerPageConditions,
	getAudiencePageConditions,
	getTriggerFormPageDisplayValue,
	getVisitsCount,
	getDeviceType,
	getTriggerAccountStatusConditions,
	getDisabledInput,
	makeGetSummaryBySection,
	getAudienceConditionsOp,
	getPageConditionsOp,
	getAudienceGroupConditionsState,
	getMonths,
	getDays,
	getTimes,
	getIsAllowedWhenChatIsTriggered,
}

export default reducer
