import { createSelector } from '@reduxjs/toolkit'

import { DashboardState } from 'types'
import { CurrencyCode } from 'shared/models/Currency'
import {
	PackageName,
	PackageNameUltimate,
	PackagePricing,
	PackagesFeature,
	PackagesFeaturesTableV2Package,
	PackagesPackageName,
} from 'shared/models/Package'
import { packageSelectors } from 'modules/package'
import { AllowedPackageNames, PackagesFeaturesSetFiltered, PackagesRootState } from 'modules/packages'

const getIsFetching = (state: DashboardState) => state.packages.isFetching
const getIsError = (state: DashboardState) => state.packages.isError
const getPackages = (state: DashboardState) => state.packages.packages
const getPackageName = (state: DashboardState, packageName: PackagesPackageName) => packageName
const getIsConfirmFreeModalOpen = (state: DashboardState) => state.packages.showConfirmFreeModal
const getIsFetchingFeatureSet = (state: DashboardState) => state.packages.isFetchingFeatureSet
const getIsFetchingFeatureSetV2 = (state: DashboardState) => state.packages.isFetchingFeatureSetV2
const getFeatureSet = (state: DashboardState) => state.packages.featureSet
const getFeatureSetV2 = (state: DashboardState) => state.packages.featureSetV2
const getPackagesInterval = (state: PackagesRootState) => state.packages.interval
const getSelectedPackagesType = (state: PackagesRootState) => state.packages.selectedPackageType
const getBasePriceMonthly = (state: PackagesRootState) => state.packages.packages.basePriceMonthly
const getBasePriceYearly = (state: PackagesRootState) => state.packages.packages.basePriceYearly

const getPackagesFeatureSet = createSelector([getFeatureSet], (featureSet): PackagesFeature[] => {
	if (!featureSet) return []
	return featureSet
})

const getLivechatPackagesFeatureSetV2BlackFriday = createSelector(
	[getFeatureSetV2],
	(featureSet): PackagesFeaturesTableV2Package[] => {
		if (!featureSet) return []
		const exclude: Set<PackagesPackageName> = new Set([PackageName.Mini, PackageName.Free, PackageNameUltimate])
		return featureSet.packages.filter((item) => !exclude.has(item.code))
	},
)

const getLivechatPackagesFeatureSetV2 = createSelector(
	[getFeatureSetV2, packageSelectors.getPackageInfo, packageSelectors.getIsShopifyAccount],
	(featureSet, packageInfo, isShopifyAccount): PackagesFeaturesTableV2Package[] => {
		if (!featureSet) return []
		const exclude: PackagesPackageName[] = [PackageName.Mini]
		if (packageInfo && (packageInfo.isPaid || packageInfo.isDemo)) exclude.push(PackageName.Free)
		if (isShopifyAccount) exclude.push(PackageNameUltimate)
		return featureSet.packages.filter((item) => !exclude.includes(item.code))
	},
)

const getLivechatPackagesFeatureSetV2BillingProfile = createSelector(
	[getLivechatPackagesFeatureSetV2, packageSelectors.getPackageInfo],
	(featureSet, packageInfo): PackagesFeaturesTableV2Package[] => {
		if (!featureSet) return []
		if (!packageInfo?.isTrial) return featureSet
		return featureSet.filter((item) => item.code !== PackageName.Free)
	},
)

const getAiPackagesFeatureSetV2 = createSelector(
	[getFeatureSetV2],
	(featureSet): PackagesFeaturesTableV2Package | undefined => {
		if (!featureSet) return undefined
		return featureSet.packages.find((item) => item.code === PackageName.Mini)
	},
)

const getCurrencyCode = createSelector([getPackages], (packages): CurrencyCode => {
	return packages[PackageName.Basic].currencyCode
})

const getIsPackagesInitialized = createSelector([getPackages], (packages) => {
	return Object.values(packages).length > 0
})

export const makeGetPackagePricingByName = () =>
	createSelector([getPackages, getPackageName], (packages, packageName): PackagePricing | null => {
		return packages[packageName] ?? null
	})

export const makeGetPackageFeatureSetByName = (packageName: AllowedPackageNames, onlyEnabled = false) =>
	createSelector([getPackagesFeatureSet], (featureSet): PackagesFeaturesSetFiltered[] => {
		const fullFeatureSet = featureSet.map((item) => {
			const valuesByPackage = item.packages[packageName] ?? []
			return {
				code: item.code,
				title: item.title,
				value: valuesByPackage,
			}
		})

		if (onlyEnabled) return fullFeatureSet.filter((item) => item.value.enabled)
		return fullFeatureSet
	})

const getMaxDiscountYearly = createSelector([getPackages], (packages): number => {
	const discountBasic = packages[PackageName.Basic]?.discountYearly ?? 0
	const discountPro = packages[PackageName.Pro]?.discountYearly ?? 0
	return Math.max(discountBasic, discountPro)
})

const getChatbotConversationsUpgradePackageName = createSelector(
	[packageSelectors.getPackageInfo, getPackages],
	(packageInfo, packages): PackageName => {
		if (!packageInfo) return PackageName.Basic
		const limit = packageInfo.chatbotConversationsLimit
		const packageLimits = packages[packageInfo.name]

		if (packageLimits) {
			const { chatbotConversationsMax } = packageLimits
			if (limit === null || chatbotConversationsMax === null || limit === 0) return PackageName.Basic

			const hasMaxLimit = limit >= chatbotConversationsMax
			if (packageInfo.name === PackageName.Basic) {
				if (hasMaxLimit) return PackageName.Pro
				return PackageName.Basic
			}

			return PackageName.Pro
		}
		return PackageName.Basic
	},
)

const getAiConversationsUpgradePackageName = createSelector(
	[packageSelectors.getPackageInfo, getPackages],
	(packageInfo, packages): PackageName => {
		if (!packageInfo) return PackageName.Basic
		const limit = packageInfo.aiConversationsLimit
		const packageLimits = packages[packageInfo.name]

		if (packageLimits) {
			const { aiConversationsMax } = packageLimits
			if (limit === null || aiConversationsMax === null) return PackageName.Mini
			return packageInfo.name
		}
		return PackageName.Mini
	},
)

const getLivechatConversationsUpgradePackageName = createSelector(
	[packageSelectors.getPackageInfo, getPackages],
	(packageInfo, packages): PackageName => {
		if (!packageInfo) return PackageName.Basic
		const limit = packageInfo.livechatConversationsLimit
		const packageLimits = packages[packageInfo.name]

		if (packageLimits) {
			const { livechatConversationsMax } = packageLimits
			if (limit === null || livechatConversationsMax === null || packageInfo.name === PackageName.Mini)
				return PackageName.Basic

			const hasMaxLimit = limit >= livechatConversationsMax
			if (packageInfo.name === PackageName.Basic) {
				if (hasMaxLimit) return PackageName.Pro
				return PackageName.Basic
			}

			return PackageName.Pro
		}
		return PackageName.Basic
	},
)

const getAgentsUpgradePackageName = createSelector(
	[packageSelectors.getPackageInfo, getPackages],
	(packageInfo, packages): PackageName => {
		if (!packageInfo) return PackageName.Basic
		const limit = packageInfo.agents
		const packageLimits = packages[packageInfo.name]

		if (packageLimits) {
			const { maxAgentsCount } = packageLimits
			if (limit === null || maxAgentsCount === null) return PackageName.Basic

			const hasMaxLimit = limit >= maxAgentsCount
			if (packageInfo.name === PackageName.Basic) {
				if (hasMaxLimit) return PackageName.Pro
				return PackageName.Basic
			}

			return PackageName.Pro
		}
		return PackageName.Basic
	},
)

const getAiChatAssistUpgradeLimit = createSelector(
	[packageSelectors.getPackageInfo, getPackages],
	(packageInfo, packages): number | null => {
		if (!packageInfo) return null

		const getPackageToUpgrade = (): PackageName | null => {
			switch (packageInfo.name) {
				case PackageName.Trial:
				case PackageName.Free: {
					return PackageName.Basic
				}
				case PackageName.Basic: {
					return PackageName.Pro
				}
				case PackageName.Pro: {
					return null // as Ultimate package
				}
			}
			return null
		}

		const packageToUpgrade = getPackageToUpgrade()
		if (packageToUpgrade) {
			return packages[packageToUpgrade].aiAssistsLimit
		}

		return null
	},
)

export const packagesSelectors = {
	getIsFetching,
	getIsError,
	getIsPackagesInitialized,
	getPackages,
	getPackagesFeatureSet,
	getFeatureSetV2,
	getAiPackagesFeatureSetV2,
	getLivechatPackagesFeatureSetV2,
	getLivechatPackagesFeatureSetV2BlackFriday,
	getLivechatPackagesFeatureSetV2BillingProfile,
	getCurrencyCode,
	makeGetPackageFeatureSetByName,
	makeGetPackagePricingByName,
	getIsConfirmFreeModalOpen,
	getIsFetchingFeatureSet,
	getIsFetchingFeatureSetV2,
	getSelectedPackagesType,
	getChatbotConversationsUpgradePackageName,
	getLivechatConversationsUpgradePackageName,
	getAiConversationsUpgradePackageName,
	getAgentsUpgradePackageName,
	getPackagesInterval,
	getAiChatAssistUpgradeLimit,
	getMaxDiscountYearly,
	getBasePriceMonthly,
	getBasePriceYearly,
}
