import {
	createAsyncThunk,
	createEntityAdapter,
	createSelector,
	createSlice,
	isFulfilled,
	PayloadAction,
} from '@reduxjs/toolkit'

import { ClosedChatsFilterType } from 'models'
import { DashboardState, Dictionary } from 'types'
import {
	Chatbot,
	ChatbotGroupType,
	ChatbotSimple,
	ListQueryGroupType,
	SelectedChatbotSimple,
	SelectedChatbotSimpleWithStatistics,
} from 'shared/models/Chatbot'
import { Source } from 'shared/models/Source'
import { aiChatbotStatisticsSelectors } from 'modules/aiChatbotStatistics'
import {
	automationsApi,
	AutomationSorting,
	defaultSorting,
	fetchAllBots,
	makeSortAutomations,
	transformSimpleChatbot,
} from 'modules/automations'
import { deleteChatbot, postChatbot, updateChatbot, updateChatbotTitleThunk } from 'modules/chatbotDetail/actions'
import { getFilterChatbotIds } from 'modules/chatbots'
import { chatsFilterSelectors, sortAutomations } from 'modules/chatsFilter'
import { packageSelectors } from 'modules/package'

import { AiOnboardingSourcesData, AiOnboardingStep } from './aiOnboarding/types'
import { getOnboardingStep } from './aiOnboarding/utils'
import { getSimpleAiChatbotsWithStatistics } from './utils'

export type AiChatbotsState = Pick<DashboardState, 'aiChatbots'>

const aiChatbotsEntityAdapter = createEntityAdapter<ChatbotSimple>()

const initialState = aiChatbotsEntityAdapter.getInitialState({
	lastFetchedTime: null as null | number,
	isFetchingAiChatbots: false,
	activeChatbots: 0,
	sorting: defaultSorting,
	onboarding: {
		isSaving: false,
		isButtonDisabled: false,
		isModalOpen: false,
		step: AiOnboardingStep.Introduction,
		chatbot: null as null | Chatbot,
		sources: {
			web: null as null | Source['id'],
			xml: null as null | Source['id'],
		},
		sourceCheck: {
			isValid: true,
			size: 0,
		},
		identity: null as null | Chatbot['identityId'],
		feedFormData: null as null | AiOnboardingSourcesData,
	},
})

export const fetchAiChatbots = createAsyncThunk(
	'aiChatbots/FETCH_CHATBOTS',
	() => {
		return automationsApi.getBotsList({ groupType: ListQueryGroupType.Ai })
	},
	{
		condition: (_, { getState }) => {
			const state = getState() as DashboardState
			const lastFetchedTime = getAiChatbotsLastFetchedTime(state)
			const currentTime = Date.now()
			return !lastFetchedTime || currentTime - lastFetchedTime > 10000
		},
	},
)

export const fetchAiChatbotsCounts = createAsyncThunk('aiChatbots/FETCH_COUNTS', () => {
	return automationsApi.getBotsCounts({ groupType: ChatbotGroupType.Ai })
})

const entitySelectors = aiChatbotsEntityAdapter.getSelectors<AiChatbotsState>((state) => state.aiChatbots)
const getAiChatbotsList = (state: AiChatbotsState) => entitySelectors.selectAll(state)
const getAiChatbotsLastFetchedTime = (state: AiChatbotsState) => state.aiChatbots.lastFetchedTime
const getAiChatbotsActiveCount = (state: AiChatbotsState) => state.aiChatbots.activeChatbots
const getIsFetchingAiChatbots = (state: AiChatbotsState) => state.aiChatbots.isFetchingAiChatbots
const getAiChatbotsSorting = (state: AiChatbotsState) => state.aiChatbots.sorting

export const aiChatbotsSlice = createSlice({
	name: 'aiChatbots',
	initialState,
	reducers: {
		setAiChatbotSorting: (state, { payload }: PayloadAction<AutomationSorting>) => {
			state.sorting = payload
		},
		/* ONBOARDING */
		continueOnboarding: (state) => {
			const currentStep = state.onboarding.step
			state.onboarding.sourceCheck = { isValid: true, size: 0 }
			state.onboarding.step = getOnboardingStep(currentStep, 'next')
		},
		returnOnboarding: (state) => {
			const currentStep = state.onboarding.step
			state.onboarding.sourceCheck = { isValid: true, size: 0 }
			state.onboarding.step = getOnboardingStep(currentStep, 'previous')
		},
		setOnboardingStep: (state, { payload }: PayloadAction<AiOnboardingStep>) => {
			state.onboarding.step = payload
		},
		setOnboardingChatbot: (state, { payload }: PayloadAction<Chatbot>) => {
			state.onboarding.chatbot = payload
		},
		setOnboardingIdentity: (state, { payload }: PayloadAction<Chatbot['identityId']>) => {
			state.onboarding.identity = payload
		},
		setOnboardingSources: (
			state,
			{ payload }: PayloadAction<{ web?: Source['id'] | null; xml?: Source['id'] | null }>,
		) => {
			if (payload.web !== undefined) {
				state.onboarding.sources.web = payload.web
			}
			if (payload.xml !== undefined) {
				state.onboarding.sources.xml = payload.xml
			}
		},
		setOnboardingIsSaving: (state, { payload }: PayloadAction<boolean>) => {
			state.onboarding.isSaving = payload
		},
		setOnboardingIsButtonDisabled: (state, { payload }: PayloadAction<boolean>) => {
			state.onboarding.isButtonDisabled = payload
		},
		setOnboardingIsModalOpen: (state, { payload }: PayloadAction<boolean>) => {
			state.onboarding.isModalOpen = payload
			if (!payload) {
				state.onboarding.step = AiOnboardingStep.Introduction
				state.onboarding.chatbot = null
				state.onboarding.identity = null
				state.onboarding.sources = { web: null, xml: null }
				state.onboarding.sourceCheck = { isValid: true, size: 0 }
			}
		},
		setOnboardingSourceCheck: (state, { payload }: PayloadAction<{ isValid: boolean; size: number }>) => {
			state.onboarding.sourceCheck = payload
		},
		setOnboardingSourceFormData: (state, { payload }: PayloadAction<AiOnboardingSourcesData | null>) => {
			if (payload) {
				// creating copy instead of assigning reference, otherwise form values might get frozen and stucking the form
				state.onboarding.feedFormData = {
					...state.onboarding.feedFormData,
					...payload,
				}
			} else {
				state.onboarding.feedFormData = payload
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAiChatbots.pending, (state) => {
				state.isFetchingAiChatbots = true
			})
			.addCase(fetchAiChatbots.fulfilled, (state, { payload }) => {
				state.isFetchingAiChatbots = false
				aiChatbotsEntityAdapter.setAll(state, payload)
				state.lastFetchedTime = Date.now()
			})
			.addCase(fetchAiChatbots.rejected, (state) => {
				state.isFetchingAiChatbots = false
			})
		builder.addCase(postChatbot.fulfilled, (state, { payload }) => {
			if (payload.groupType === ChatbotGroupType.Ai) {
				aiChatbotsEntityAdapter.addOne(state, payload)
			}
		})
		builder.addCase(updateChatbot.fulfilled, (state, { payload }) => {
			aiChatbotsEntityAdapter.updateOne(state, { id: payload.id, changes: payload })
		})
		builder.addCase(updateChatbotTitleThunk.fulfilled, (state, { payload }) => {
			aiChatbotsEntityAdapter.updateOne(state, { id: payload.id, changes: payload })
		})
		builder.addCase(deleteChatbot.fulfilled, (state, { meta }) => {
			aiChatbotsEntityAdapter.removeOne(state, meta.arg)
		})
		builder.addCase(fetchAllBots.fulfilled, (state, { payload }) => {
			const filteredPayload = payload.filter((bot) => bot.groupType === ChatbotGroupType.Ai)
			aiChatbotsEntityAdapter.setAll(state, filteredPayload)
			state.isFetchingAiChatbots = false
		})
		builder.addCase(fetchAllBots.pending, (state) => {
			state.isFetchingAiChatbots = true
		})
		builder.addCase(fetchAllBots.rejected, (state) => {
			state.isFetchingAiChatbots = false
		})
		builder.addCase(fetchAiChatbotsCounts.fulfilled, (state, { payload }) => {
			state.activeChatbots = payload.active
		})

		builder.addMatcher(isFulfilled(postChatbot, deleteChatbot, fetchAiChatbots), (state) => {
			state.activeChatbots = Object.values(state.entities).filter((e) => e?.isActive).length
		})
		builder.addMatcher(isFulfilled(updateChatbot, updateChatbotTitleThunk), (state, { payload }) => {
			if (payload.groupType === ChatbotGroupType.Chatbot) return
			state.activeChatbots = Object.values(state.entities).filter((e) => e?.isActive).length
		})
	},
})

const getAiChatbots = createSelector([getAiChatbotsList], (aiChatbots): SelectedChatbotSimple[] => {
	return aiChatbots.map(transformSimpleChatbot)
})

const getAiChatbotsWithStatistics = createSelector(
	[getAiChatbots, aiChatbotStatisticsSelectors.getBots],
	(aiChatbots, aiChatbotsStats): Dictionary<SelectedChatbotSimpleWithStatistics> => {
		return getSimpleAiChatbotsWithStatistics(aiChatbots, aiChatbotsStats)
	},
)

const getSortedAiChatbots = createSelector(
	[getAiChatbotsWithStatistics, getAiChatbotsSorting],
	(aiChatbots, sorting): SelectedChatbotSimpleWithStatistics[] => {
		return Object.values(aiChatbots).sort(makeSortAutomations(sorting))
	},
)

const getIsAiChatbotCreated = createSelector([getAiChatbotsList], (aiChatbots) => {
	return aiChatbots.length > 0
})

const getHasFetchedAiChatbots = createSelector([getAiChatbotsLastFetchedTime], (lastFetched) => {
	return !!lastFetched
})

const getAiBotsIds = createSelector([getAiChatbots], (aiBots) => {
	return aiBots.map((bot) => bot.id)
})

const getAiBotIdsInFilter = createSelector(
	[getAiBotsIds, chatsFilterSelectors.getClosedChatsFilterBotIds],
	(aiBotsIds, filterBotsIds) => {
		return getFilterChatbotIds(filterBotsIds, aiBotsIds)
	},
)

const getAiBotsCount = createSelector([getAiChatbots], (aiBots) => {
	return aiBots.length
})

const getAiBotsLimits = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	return {
		aiBotsTotalLimit: packageInfo?.aiBotsTotalLimit || null,
		aiBotsPublishedLimit: packageInfo?.aiBotsPublishedLimit || null,
	}
})

const getCanPublishAiBot = createSelector(
	[getAiChatbotsActiveCount, getAiBotsLimits],
	(activeBotsCount, { aiBotsPublishedLimit }) => {
		if (!aiBotsPublishedLimit) return true
		return activeBotsCount < aiBotsPublishedLimit
	},
)

const getCanCreateAiBot = createSelector([getAiChatbots, getAiBotsLimits], (aiBots, { aiBotsTotalLimit }) => {
	if (!aiBotsTotalLimit) return true
	return aiBots.length < aiBotsTotalLimit
})

const getAiChatbotsSortedAlphabetically = createSelector([getAiChatbots], (aiBots) => {
	return sortAutomations(aiBots)
})

const getShouldRenderAiChatbotsChatsUpsell = createSelector(
	[getCanCreateAiBot, getAiChatbotsActiveCount, chatsFilterSelectors.getClosedChatsFilterByType],
	(canCreateAiBot, aiChatbotsActiveCount, activeTab) => {
		return !canCreateAiBot && aiChatbotsActiveCount === 0 && activeTab === ClosedChatsFilterType.Ai
	},
)

const { reducer, actions } = aiChatbotsSlice
export default reducer

export const { setAiChatbotSorting } = actions

export const aiChatbotsSelectors = {
	getIsFetchingAiChatbots,
	getSortedAiChatbots,
	getAiChatbotsSorting,
	getAiChatbots,
	getHasFetchedAiChatbots,
	getIsAiChatbotCreated,
	getAiBotIdsInFilter,
	getAiBotsCount,
	getCanPublishAiBot,
	getCanCreateAiBot,
	getAiChatbotsSortedAlphabetically,
	getAiChatbotsActiveCount,
	getShouldRenderAiChatbotsChatsUpsell,
}
