import WebsocketAgentClient from '@smartsupp/websocket-client-agent'
import { nanoid } from 'nanoid'

import { FeatureUsage, PackageInfo, User } from 'models'
import { AccountData, ConnectionData, IdentityData } from 'shared/types'
import { AppDispatch } from 'types'
import { Source } from 'shared/models/Source'
import { ConfigurationService, logException } from 'shared/services'
import { Logger } from 'services'
import { onChangeIsBlockedStatus } from 'modules/account'
import { onAgentConnected, onAgentDisconnected, onAgentUpdated } from 'modules/agents'
import { onError, onInitialized } from 'modules/app'
import { chatsActions } from 'modules/chats'
import { onChatVisitorTyping } from 'modules/chatsSlice'
import { onContactTagAdded, onContactTagRemoved, onContactUpdated } from 'modules/contacts'
import { updateDataLayerFromExternalEvents } from 'modules/dataLayer'
import { onChangeFeatureUsageUser } from 'modules/features'
import { onMessageDeleted, onMessageReceived, onMessageUpdated } from 'modules/messages'
import { fetchPackageBalance, onPackageChanged } from 'modules/package'
import { fetchPackages } from 'modules/packages'
import { fetchShortcuts } from 'modules/shortcuts'
import { onSourceStatusUpdated } from 'modules/sources'
import { setIsRenewalPendingForPackageUpdate } from 'modules/stripe'
import { openSystemModal, SystemModalType } from 'modules/systemModal'
import { showNotification } from 'modules/systemNotification'
import {
	checkUsageLimits,
	setAiBonusConversations,
	setAiConversations,
	setAiCustomResponses,
	setAiPreviewMessages,
	setAiReplyAssistCounter,
	setAiSourceFeedCount,
	setAiSourceFeedData,
	setAiSourceWebCount,
	setAiSourceWebData,
	setChatbotConversations,
	updateMonthlyServedConversations,
} from 'modules/usage'
import { onVisitorConnected, onVisitorDisconnected, onVisitorUpdated } from 'modules/visitors'

const TRANSPORT_CLOSE_ERROR = 'transport close'
const SERVER_DISCONNECT_ERROR = 'io server disconnect'

const CLIENT_DEVICE_TOKEN = 'ss-deviceToken'

const clientLogger = new Logger('AgentClient')

const getDeviceToken = () => {
	const token = window.localStorage.getItem(CLIENT_DEVICE_TOKEN)
	if (token) return token

	const newToken = nanoid(15)
	window.localStorage.setItem(CLIENT_DEVICE_TOKEN, newToken)
	return newToken
}

export const createAgentClient = (
	accountData: AccountData,
	identityData: IdentityData,
	connectionData: ConnectionData,
) => {
	const protocol = connectionData.protocol ? `${connectionData.protocol}:` : ''
	const options: WebsocketAgentClient.Options = {
		data: {
			id: `${identityData.id}`,
			key: accountData.key,
			deviceToken: getDeviceToken(),
		},
		connection: {
			url: `${protocol}//${connectionData.url}`,
			options: {
				path: '/socket',
				forceNew: true,
				transports: ['websocket'],
			},
		},
		authenticate: async (connectData) => {
			connectData.accessToken = await ConfigurationService.getRestAccessToken()
		},
	}

	return new WebsocketAgentClient(options)
}

const createSmartsuppAgentClient = (userData: User) => {
	// Don't create client when agent is disabled
	if (!userData.active) return null

	const accountData = ConfigurationService.getAccountData()
	const connectionData = ConfigurationService.getConnectionData()
	const identityData = ConfigurationService.getIdentityData()
	const dataToClient: { account: AccountData; connection: ConnectionData; identity: IdentityData } = JSON.parse(
		JSON.stringify({ account: accountData, connection: connectionData, identity: identityData }),
	)
	return createAgentClient(dataToClient.account, dataToClient.identity, dataToClient.connection)
}

const createAgentClientProvider = () => {
	let agentClient: WebsocketAgentClient | null = null

	const getAgentClient = () => {
		return agentClient
	}

	const initAgentClient = async (dispatch: AppDispatch, userData: User, isBlocked: boolean) => {
		await agentClient?.disconnect()
		agentClient = createSmartsuppAgentClient(userData)
		if (!agentClient) return

		initBaseEvents(agentClient, dispatch)
		if (isBlocked) {
			initBlockedClientEvents(agentClient, dispatch, userData)
		} else {
			initClientEvents(agentClient, dispatch, userData)
		}

		try {
			await agentClient.connect()
		} catch {
			logException(new Error('error.connect'))
		}
	}

	return Object.freeze({
		getAgentClient,
		initAgentClient,
	})
}

const initClientEvents = (client: WebsocketAgentClient, dispatch: AppDispatch, userData: User) => {
	// Agent events
	client.on('agent.connected', (data) => {
		dispatch(onAgentConnected(data))
	})
	client.on('agent.disconnected', (data) => {
		dispatch(onAgentDisconnected(data))
	})
	client.on('agent.updated', (data) => {
		dispatch(onAgentUpdated(data))
	})
	client.on<FeatureUsage>('agent.feature_usage_changed', (data) => {
		dispatch(onChangeFeatureUsageUser(data))
	})

	// Visitor events
	client.on('visitor.connected', (data) => {
		clientLogger.log('visitor.connected', data)
		dispatch(onVisitorConnected(data))
	})
	client.on('visitor.disconnected', (data) => {
		clientLogger.log('visitor.disconnected', data)
		dispatch(onVisitorDisconnected(data))
	})
	client.on('visitor.updated', (data) => {
		clientLogger.log('visitor.updated', data)
		dispatch(onVisitorUpdated(data))
	})

	// Chat events
	client.on('chat.agent_joined', (data) => {
		dispatch(chatsActions.onChatAgentJoined(data))
	})
	client.on('chat.agent_left', (data) => {
		dispatch(chatsActions.onChatAgentLeft(data))
	})
	client.on('chat.agent_assigned', (data) => {
		dispatch(chatsActions.onChatAgentAssigned(data))
	})
	client.on('chat.agent_unassigned', (data) => {
		dispatch(chatsActions.onChatAgentUnassigned(data))
	})
	client.on('chat.agent_read', (data) => {
		dispatch(chatsActions.onChatAgentRead(data))
	})
	client.on('chat.agent_unread', (data) => {
		dispatch(chatsActions.onChatAgentUnread(data))
	})
	client.on('chat.contact_read', (data) => {
		dispatch(chatsActions.onChatContactRead(data))
	})
	client.on('chat.visitor_typing', (data) => {
		dispatch(onChatVisitorTyping(data))
	})
	client.on('chat.message_received', (data) => {
		dispatch(onMessageReceived(data))
	})
	client.on('chat.message_deleted', (data) => {
		dispatch(onMessageDeleted(data))
	})
	client.on('chat.opened', (data) => {
		dispatch(chatsActions.onChatOpened(data))
	})
	// closed by agent (Resolve)
	client.on('chat.closed', (data) => {
		dispatch(chatsActions.onChatClosed(data))
	})
	client.on('chat.visitor_closed', (data) => {
		dispatch(chatsActions.onChatVisitorClosed(data))
	})
	client.on('chat.rated', (data) => {
		dispatch(chatsActions.onChatRated(data))
	})
	client.on('chat.updated', (data) => {
		dispatch(chatsActions.onChatUpdated(data))
	})
	client.on('chat.deleted', (data) => {
		dispatch(chatsActions.onChatDeleted(data))
	})
	client.on('chat.message_delivered', (data) => {
		dispatch(onMessageUpdated(data))
	})
	client.on('chat.message_delivery_failed', (data) => {
		dispatch(onMessageUpdated(data))
	})
	client.on('chat.message_seen', (data) => {
		dispatch(onMessageUpdated(data))
	})

	// Contact events
	client.on('contact.updated', (data) => {
		clientLogger.log('contact.updated', data)
		dispatch(onContactUpdated(data))
		dispatch(chatsActions.onContactDetailsUpdated(data))
	})

	client.on('contact.tag.added', (data) => {
		dispatch(onContactTagAdded(data))
	})

	client.on('contact.tag.removed', (data) => {
		dispatch(onContactTagRemoved(data))
	})

	// AI sources events
	client.on('ai.source.status.updated', (data) => {
		dispatch(onSourceStatusUpdated(data as unknown as Source))
	})

	// Account events
	client.on<PackageInfo>('account.package_changed', async (data) => {
		clientLogger.log('account.package_changed', data)
		await dispatch(fetchPackages())
		await dispatch(onPackageChanged(data))
		dispatch(fetchShortcuts())
		dispatch(fetchPackageBalance())
		dispatch(updateDataLayerFromExternalEvents())
		dispatch(setIsRenewalPendingForPackageUpdate(false))
	})

	client.on<FeatureUsage>('account.feature_usage_changed', () => {
		// temporary unused
		// dispatch(onChangeFeatureUsageAccount(data))
	})

	client.on('account.block_status_changed', (data) => {
		agentClientProvider.initAgentClient(dispatch, userData, data.isBlocked)
		dispatch(onChangeIsBlockedStatus(data))
	})

	// Usage events
	client.on('usage.updated', (data) => {
		const { name, usage, limit } = data
		switch (name) {
			case 'bot_conversation': {
				dispatch(setChatbotConversations(usage))
				dispatch(checkUsageLimits())
				break
			}
			case 'ai_assist': {
				dispatch(setAiReplyAssistCounter(usage))
				break
			}
			case 'served_conversation': {
				dispatch(updateMonthlyServedConversations(usage))
				break
			}
			case 'ai_conversation': {
				dispatch(setAiConversations({ usage, limit }))
				dispatch(checkUsageLimits())
				break
			}
			case 'ai_conversation_bonus': {
				dispatch(setAiBonusConversations({ usage, limit }))
				dispatch(checkUsageLimits())
				break
			}
			case 'ai_source_feed_count': {
				dispatch(setAiSourceFeedCount({ usage, limit }))
				break
			}
			case 'ai_source_website_count': {
				dispatch(setAiSourceWebCount({ usage, limit }))
				break
			}
			case 'ai_preview_message_count': {
				dispatch(setAiPreviewMessages({ usage, limit }))
				break
			}
			case 'ai_source_website_data': {
				dispatch(setAiSourceWebData({ usage, limit }))
				break
			}
			case 'ai_source_feed_data': {
				dispatch(setAiSourceFeedData({ usage, limit }))
				break
			}
			case 'ai_custom_response_count': {
				dispatch(setAiCustomResponses({ usage, limit }))
				break
			}
		}
	})

	client.on('usage.restarted', (data) => {
		const { name } = data
		if (name === 'bot_conversation') {
			dispatch(setChatbotConversations(0))
			// todo: hide product notifications for chatbot conversations limit
		}
	})
}

const initBaseEvents = (client: WebsocketAgentClient, dispatch: AppDispatch) => {
	// General events
	client.on('initialized', (data) => {
		dispatch(onInitialized(data))
	})
	client.on('error', (error) => {
		dispatch(onError(error))
	})

	// Connection events
	client.on('disconnect', (message: string) => {
		if (message === SERVER_DISCONNECT_ERROR) {
			dispatch(openSystemModal({ name: SystemModalType.Disconnected }))
			return
		}
		if (message === TRANSPORT_CLOSE_ERROR) {
			dispatch(
				showNotification({
					title: 'notifications.waitingForServer',
					type: 'warning',
				}),
			)
		}
	})
	client.on('reconnecting', () => {
		dispatch(
			showNotification({
				title: 'notifications.reconnecting',
				type: 'warning',
			}),
		)
	})
	client.on('reconnect', () => {
		dispatch(
			showNotification({
				title: 'notifications.reconnected',
				type: 'success',
			}),
		)
	})
}

const initBlockedClientEvents = (client: WebsocketAgentClient, dispatch: AppDispatch, userData: User) => {
	client.on('account.block_status_changed', (data) => {
		agentClientProvider.initAgentClient(dispatch, userData, data.isBlocked)
		dispatch(onChangeIsBlockedStatus(data))
	})
}

export const agentClientProvider = createAgentClientProvider()
