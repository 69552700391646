import { ChatbotGroupType } from 'shared/models/Chatbot'
import { PackageName } from 'shared/models/Package'

export const routes = {
	conversations: { path: '/conversations' },
	conversationDetail: { path: '/conversations/:chatId' },
	customers: { path: '/customers' },
	contacts: { path: '/customers/contacts', oldPath: '/contacts' },
	contactDetail: { path: '/customers/contacts/:contactId', oldPath: '/contacts/:contactId' },
	visitors: { path: '/customers/visitors', oldPath: '/visitors' },

	history: { path: '/history' },
	statistics: { path: '/statistics' },
	automations: { path: '/automations' },
	aiAutomations: { path: '/ai-automations/' },
	visitor: { path: '/visitor/:visitorId' },
	visitorHistory: { path: '/visitor/:visitorId/history' },
	visitorHistoryDetail: { path: '/visitor/:visitorId/history/:conversationId' },
	styleGuide: { path: '/style-guide' },
	smartHub: { path: '/home' },
	affiliate: { path: '/affiliate/:tabName?' },
	affiliateOverview: { path: '/affiliate/overview' },
	affiliateClients: { path: '/affiliate/clients' },
	affiliateRewards: { path: '/affiliate/rewards' },

	// Automations
	chatbots: { path: '/automations/chatbots', oldPath: '/chatbot', oldPathV2: '/chatbots' },
	chatbotDetail: { path: '/automations/chatbots/edit/:botId' },
	chatbotSettings: { path: '/automations/chatbots/settings/:botId' },
	chatbotStatistics: { path: '/automations/chatbots/statistics/:botId' },
	autoMessages: { path: '/automations/auto-messages' },
	autoMessageDetail: { path: '/automations/auto-messages/edit/:botId' },
	autoMessageSettings: { path: '/automations/auto-messages/settings/:botId' },
	autoMessageStatistics: { path: '/automations/auto-messages/statistics/:botId' },
	templates: { path: '/automations/templates/:tabName?' },
	templatesAll: { path: '/automations/templates/all' },
	templatesLeadgen: { path: '/automations/templates/leadgen' },
	templatesSales: { path: '/automations/templates/sales' },
	templatesCustomerCare: { path: '/automations/templates/customer-care' },
	templatesMarketing: { path: '/automations/templates/marketing' },

	aiChatbots: { path: '/ai-automations/ai-chatbots' },
	aiChatbotWorkflow: { path: '/ai-automations/ai-chatbots/workflow/:botId' },
	aiChatbotSettings: { path: '/ai-automations/ai-chatbots/settings/:botId' },
	aiSources: { path: '/ai-automations/sources' },
	aiSatisfaction: { path: '/ai-automations/satisfaction' },
	aiTraining: { path: '/ai-automations/training' },

	// Settings
	settings: { path: '/settings' },
	agents: { path: '/settings/agents' },
	agentEdit: { path: '/settings/agents/:agentId/edit' },
	groups: { path: '/settings/groups' },
	groupEdit: { path: '/settings/groups/:groupId/edit' },
	groupCreate: { path: '/settings/groups/:groupId/create' },
	googleAnalytics: { path: '/settings/integrations/google-analytics' },
	integrations: { path: '/settings/integrations' },
	integrationSettings: { path: '/settings/integrations/:integrationId/settings' },
	channelsFacebookMessenger: { path: '/settings/integrations/facebook-messenger' },
	channelsChatPage: { path: '/settings/integrations/chat-page' },
	mobileSdk: { path: '/settings/integrations/mobile-sdk/:tabName?' },
	profile: { path: '/settings/profile' },
	billingProfile: { path: '/settings/billing' },
	billingProfileTabs: { path: '/settings/billing/:tabName?' },
	billingProfileSubscription: { path: '/settings/billing/subscription' },
	billingProfileInformation: { path: '/settings/billing/information' },
	billingProfileInvoices: { path: '/settings/billing/invoices' },
	shortcuts: { path: '/settings/shortcuts' },
	shortcutsCreate: { path: '/settings/shortcuts/create' },
	shortcutEdit: { path: '/settings/shortcuts/:shortcutId/edit' },
	tags: { path: '/settings/tags' },
	tagsCreate: { path: '/settings/tags/create' },
	tagsEdit: { path: '/settings/tags/edit/:tagId' },
	properties: { path: '/settings/properties' },
	propertiesCreate: { path: '/settings/properties/create' },
	propertiesEdit: { path: '/settings/properties/edit/:propertyId' },
	notifications: { path: '/settings/notifications' },
	account: { path: '/settings/account/:tabName?' },
	accountGeneral: { path: '/settings/account/general' },
	accountSecurity: { path: '/settings/account/security' },
	accountOfficeHours: { path: '/settings/account/office-hours' },
	widget: { path: '/settings/chat-box/:tabName?' },
	widgetAppearance: { path: '/settings/chat-box/appearance' },
	widgetOptions: { path: '/settings/chat-box/options' },
	widgetAuthForm: { path: '/settings/chat-box/contact-form' },
	widgetInstallation: { path: '/settings/chat-box/installation' },
	widgetTranslationsForm: { path: '/settings/chat-box-translations' },

	// Billing
	billing: { path: '/billing' },
	packages: { path: '/billing/packages' },
	packagesFallback: { path: '/billing/invalid-offer' },
	packagesWithPackage: { path: '/billing/packages/:packageType' },
	packagesWithPackageBf: {
		path: '/billing/packages/:packageType/:blackFriday',
		url: '/billing/packages/livechat/blackFriday',
	},
	packagesAiBf: { path: '/billing/ai-bf/:blackFriday', url: '/billing/ai-bf/blackFriday' },
	checkout: { path: '/billing/checkout' },
	checkoutWithPackage: { path: '/billing/checkout/:packageName' },
	paymentResult: { path: '/billing/payment' },
	flashDeal: { path: '/flash-deal/:token?' },
	flashDealError: { path: '/flash-deal' },
	ultimate: { path: '/ultimate' },
	unpaidInvoice: { path: '/billing/unpaid-invoice' },
	draftInvoice: { path: '/billing/draft-invoice' },
}

const automationRoutesByType: Record<ChatbotGroupType, string> = {
	[ChatbotGroupType.Message]: 'auto-messages',
	[ChatbotGroupType.Ai]: 'ai-chatbots',
	[ChatbotGroupType.Chatbot]: 'chatbots',
}

export const createConversationLink = (chatId: string) => `/conversations/${chatId}`
export const createAgentEditLink = (agentId: number) => routes.agentEdit.path.replace(':agentId', String(agentId))
export const createShortcutEditLink = (shortcutId: number) =>
	routes.shortcutEdit.path.replace(':shortcutId', String(shortcutId))
export const createTagEditLink = (tagId: number) => routes.tagsEdit.path.replace(':tagId', String(tagId))
export const createGroupEditLink = (groupId: number) => routes.groupEdit.path.replace(':groupId', String(groupId))
export const createGroupCreateLink = (groupId: number) => routes.groupCreate.path.replace(':groupId', String(groupId))
export const createCheckoutLink = (packageName: PackageName) =>
	routes.checkoutWithPackage.path.replace(':packageName', String(packageName))
export const createAiChatbotWorkflowLink = (botId: string) => routes.aiChatbotWorkflow.path.replace(':botId', botId)
export const createGeneralTabLink = (tabName: string) => routes.account.path.replace(':tabName?', tabName)
export const createWidgetTabLink = (tabName: string) => routes.widget.path.replace(':tabName?', tabName)
export const createMobileSdkTabLink = (tabName: string) => routes.mobileSdk.path.replace(':tabName?', tabName)
export const createAffiliateTabLink = (tabName: string) => routes.affiliate.path.replace(':tabName?', tabName)
export const createTemplatesTabLink = (tabName: string) => routes.templates.path.replace(':tabName?', tabName)
export const createIntegrationSettingsLink = (integrationId: string) =>
	routes.integrationSettings.path.replace(':integrationId', String(integrationId))
export const createPropertyEditLink = (propertyId: string) =>
	routes.propertiesEdit.path.replace(':propertyId', propertyId)
export const createContactDetailsLink = (contactId: string) =>
	routes.contactDetail.path.replace(':contactId', contactId)

export const createAutomationSettingsLink = (botId: string, groupType: ChatbotGroupType) => {
	switch (groupType) {
		case ChatbotGroupType.Ai: {
			return routes.aiChatbotSettings.path.replace(':botId', botId)
		}
		default: {
			return `/automations/${automationRoutesByType[groupType]}/settings/${botId}`
		}
	}
}
export const createAutomationStatisticsLink = (botId: string, groupType: ChatbotGroupType) =>
	`/automations/${automationRoutesByType[groupType]}/statistics/${botId}`
export const createAutomationEditLink = (botId: string, groupType: ChatbotGroupType) =>
	`/automations/${automationRoutesByType[groupType]}/edit/${botId}`
export const createBillingPackagesLink = (packageType: string) =>
	routes.packagesWithPackage.path.replace(':packageType', packageType)
