import { mdiCartOutline, mdiWeb } from '@mdi/js'

import { SourceStatus } from 'shared/models/Source'

import { SourceFormData, SourceFormType } from './types'

export const MAX_URL_LENGTH = 256
export const MAX_NAME_LENGTH = 50

export const SOURCE_NAME = 'title'
export const SOURCE_TYPE = 'parser.type' as const
export const SOURCE_URL = 'parser.url' as const
export const SOURCE_CURRENCY = 'parser.currency' as const
export const SOURCE_FORM_TYPE = 'formType'

export const DEFAULT_FORM_VALUES: SourceFormData = {
	id: null,
	title: '',
	parser: { type: null, url: '', currency: undefined },
	formType: SourceFormType.Web,
	categories: [],
}

export const sourcesDataMap: Record<
	SourceFormType,
	{
		type: SourceFormType
		name: string
		icon: string
		image: string
		label: string
		formLabel: string
		inputDescription: string
		placeholder: string
		description: string
		emoji: string
	}
> = {
	web: {
		type: SourceFormType.Web,
		name: 'sources.type.web.label',
		icon: mdiWeb,
		image: 'assets/images/ai/ai-sources-scrape.webp',
		label: 'sources.type.web.url.label',
		formLabel: 'sources.type.web.formLabel',
		inputDescription: 'sources.type.web.url.description',
		placeholder: 'mywebsite.com/contact',
		description: 'sources.type.web.description',
		emoji: '🖥️',
	},
	xml: {
		type: SourceFormType.Xml,
		name: 'sources.type.xml.label',
		icon: mdiCartOutline,
		image: 'assets/images/ai/ai-sources-xml.webp',
		label: 'sources.type.xml.url.label',
		formLabel: 'sources.type.xml.formLabel',
		inputDescription: 'sources.type.xml.url.description',
		placeholder: 'https://example.com/file_001.xml',
		description: 'sources.type.xml.description',
		emoji: '🛍️',
	},
}

export const sourcesStatusMap: Record<
	SourceStatus,
	{ label: string; color: string; showLoader?: boolean; tooltip?: string }
> = {
	[SourceStatus.Creating]: {
		label: 'sources.status.creating',
		color: 'blue',
		tooltip: 'sources.status.creating.tooltip',
		showLoader: true,
	},
	[SourceStatus.Updating]: {
		label: 'sources.status.updating',
		color: 'blue',
		tooltip: 'sources.status.updating.tooltip',
		showLoader: true,
	},
	[SourceStatus.Ready]: {
		label: 'sources.status.ready',
		color: 'green',
		tooltip: 'sources.status.ready.tooltip',
	},
	[SourceStatus.Warning]: {
		label: 'sources.status.warning',
		color: 'yellow',
		tooltip: 'sources.status.warning.tooltip',
	},
	[SourceStatus.Failed]: {
		label: 'sources.status.failed',
		color: 'red',
		tooltip: 'sources.status.failed.tooltip',
	},
	[SourceStatus.Pending]: {
		label: 'sources.status.pending',
		color: 'gray',
		tooltip: 'sources.status.pending.tooltip',
		showLoader: true,
	},
}

export const sourcesTestIds = {
	modal: {
		continueButton: 'sources-modal-continue-button',
		backButton: 'sources-modal-back-button',
		dataInput: 'sources-modal-data-input',
		descriptionIcon: 'sources-modal-description-icon',
		descriptionText: 'sources-modal-description-text',
		descriptionTitle: 'sources-modal-description-title',
		heading: 'sources-modal-heading',
		nameInput: 'sources-modal-name-input',
		typeSelect: 'sources-modal-type-select',
	},
	list: {
		addNew: 'sources-list-add-new',
		item: 'sources-list-item',
		itemTitle: 'sources-list-item-title',
		itemStatus: 'sources-list-item-status',
		itemType: 'sources-list-item-type',
		itemUrl: 'sources-list-item-url',
		itemPublishedAt: 'sources-list-item-published-at',
	},
	banner: 'sources-banner',
}
