import { lazy, ReactNode, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useRouteMatch } from 'react-router-dom'

import { routes } from 'configuration/routes'
import { useAppTheme } from 'styles/theme'
import { integrationsSelectors } from 'modules/integrations'

import { Sidebar } from 'components/sidebar'
import { Box } from 'shared/components/core/Box'
import { Grid } from 'shared/components/core/Layout'
import { Loader } from 'shared/components/core/Loader'

const AgentInactiveOverlay = lazy(
	() => import(/* webpackChunkName: "overlays" */ 'components/layout/overlay/AgentInactiveOverlay'),
)
const PackageExpiredOverlay = lazy(
	() => import(/* webpackChunkName: "overlays" */ 'components/upsell/packageExpiredOverlay/PackageExpiredOverlay'),
)
const NotificationBar = lazy(
	() => import(/* webpackChunkName: "notificationBar" */ 'components/notifications/notificationBar/NotificationBar'),
)
const SubmenuCustomers = lazy(() => import(/* webpackChunkName: "submenu" */ './submenu/SubmenuCustomers'))
const SubmenuLayout = lazy(() => import(/* webpackChunkName: "submenu" */ './SubmenuLayout'))
const SubmenuStatistics = lazy(() => import(/* webpackChunkName: "submenu" */ './submenu/SubmenuStatistics'))
const SubmenuSettings = lazy(() => import(/* webpackChunkName: "submenu" */ './submenu/SubmenuSettings'))
const SubmenuAiAutomations = lazy(() => import(/* webpackChunkName: "submenu" */ './submenu/SubmenuAiAutomations'))
const SubmenuAutomations = lazy(() => import(/* webpackChunkName: "submenu" */ './submenu/SubmenuAutomations'))

type Props = {
	children?: ReactNode
}

const submenuSettingsRoutes = new Set([
	routes.settings.path,
	routes.profile.path,
	routes.shortcuts.path,
	routes.notifications.path,
	routes.accountGeneral.path,
	routes.accountOfficeHours.path,
	routes.accountSecurity.path,
	routes.agents.path,
	routes.groups.path,
	routes.widgetAppearance.path,
	routes.widgetOptions.path,
	routes.widgetAuthForm.path,
	routes.widgetInstallation.path,
	routes.billingProfile.path,
	routes.billingProfileSubscription.path,
	routes.billingProfileInformation.path,
	routes.billingProfileInvoices.path,
	routes.tags.path,
	routes.properties.path,
	routes.integrations.path,
])

const submenuAutomationsRoutes = new Set([
	routes.automations.path,
	routes.chatbots.path,
	routes.autoMessages.path,
	routes.templatesAll.path,
	routes.templatesCustomerCare.path,
	routes.templatesLeadgen.path,
	routes.templatesMarketing.path,
	routes.templatesSales.path,
])
const submenuStatisticsRoutes = new Set([routes.statistics.path])
const submenuAiChatbotsRoutes = new Set([
	routes.aiAutomations.path,
	routes.aiChatbots.path,
	routes.aiSources.path,
	routes.aiSatisfaction.path,
	routes.aiTraining.path,
])
const submenuCustomersRoutes = new Set([
	routes.customers.path,
	routes.visitors.path,
	routes.contacts.path,
	routes.contactDetail.path,
])

const AppLayoutContent = ({ children }: Props) => {
	const location = useLocation()
	const path = location.pathname
	const integrations = useSelector(integrationsSelectors.getIntegrations)
	const matchesCustomerRoutes = useRouteMatch([...submenuCustomersRoutes])

	integrations.length > 0 && submenuSettingsRoutes.add(routes.integrations.path)

	if (submenuSettingsRoutes.has(path)) {
		return (
			<Suspense fallback={<Loader />}>
				<SubmenuLayout menu={<SubmenuSettings />}>{children}</SubmenuLayout>
			</Suspense>
		)
	}

	if (submenuAutomationsRoutes.has(path)) {
		return (
			<Suspense fallback={<Loader />}>
				<SubmenuLayout
					menu={<SubmenuAutomations />}
					overlay={
						<>
							<AgentInactiveOverlay />
							<PackageExpiredOverlay />
						</>
					}
				>
					{children}
				</SubmenuLayout>
			</Suspense>
		)
	}
	if (submenuStatisticsRoutes.has(path)) {
		return (
			<Suspense fallback={<Loader />}>
				<SubmenuLayout menu={<SubmenuStatistics />} overlay={<PackageExpiredOverlay />}>
					{children}
				</SubmenuLayout>
			</Suspense>
		)
	}
	if (submenuAiChatbotsRoutes.has(path)) {
		return (
			<Suspense fallback={<Loader />}>
				<SubmenuLayout
					menu={<SubmenuAiAutomations />}
					overlay={
						<>
							<AgentInactiveOverlay />
							<PackageExpiredOverlay />
						</>
					}
				>
					{children}
				</SubmenuLayout>
			</Suspense>
		)
	}
	if (matchesCustomerRoutes) {
		return (
			<Suspense fallback={<Loader />}>
				<SubmenuLayout
					menu={<SubmenuCustomers />}
					overlay={
						<>
							<AgentInactiveOverlay />
							<PackageExpiredOverlay />
						</>
					}
				>
					{children}
				</SubmenuLayout>
			</Suspense>
		)
	}

	return <>{children}</>
}

export const AppLayout = ({ children }: Props) => {
	const theme = useAppTheme()

	return (
		<Grid
			id="app-layout"
			h="100vh"
			templateRows="min-content 1fr"
			templateColumns={`${theme.sizes.sidebarWidth} 1fr`}
			templateAreas="'upsell upsell' 'sidebar content'"
		>
			<Box gridArea="upsell">
				<Suspense fallback={<Loader />}>
					<NotificationBar />
				</Suspense>
			</Box>
			<Sidebar gridArea="sidebar" />
			<Box gridArea="content" overflow="hidden" zIndex="appLayoutContent">
				<AppLayoutContent>{children}</AppLayoutContent>
			</Box>
		</Grid>
	)
}
