import { ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { TranslationService as T } from 'shared/services'
import { getRequestDemoUrl } from 'configuration/links'
import { userSelectors } from 'modules/user'

import {
	Box,
	CButton,
	CButtonProps,
	CPopover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Stack,
} from 'core'

export enum DemoRequestSource {
	Smarthub = 'dash-smarthub',
	ContactsEmpty = 'dash-contacts_empty',
	Packages = 'dash-checkout-packages',
	BillingProfileAddon = 'dash-billing-profile-addon',
	BillingProfilePackages = 'dash-billing-profile-packages',
	BillingProfileAgents = 'dash-billing-profile-agents',
	BillingProfileChatbot = 'dash-billing-profile-chatbot',
	BillingProfileLivechatConversations = 'dash-billing-profile-livechat-conversations',
	BillingProfileAiConversations = 'dash-billing-profile-ai-conversations',
	BillingProfileCustomization = 'dash-billing-profile-customization',
	Upsell = 'dash-upsell',
	ContactsCrm = 'dash-contacts-crm',
	AiReplyAssist = 'dash-ai-reply-assist',
	AiChatbotBuilder = 'dash-ai-chatbot-builder',
	Integrations = 'dash-settings-integrations',
	TrialExpiredCard = 'dash-trial-expired',
}

type Param = {
	key: string
	value: string
}

type Props = {
	onClick?: () => void
	title?: string
	source: DemoRequestSource
	params?: Param[]
} & CButtonProps

export const DemoRequestButton = ({ onClick, title, source, params, ...rest }: Props) => {
	const userEmail = useSelector(userSelectors.getUserEmail)

	const queryParams = new URLSearchParams()
	queryParams.append('email', userEmail ?? '')
	source && queryParams.append('source', source)
	params && params.forEach(({ key, value }) => queryParams.append(key, value))

	const handleClick = () => {
		onClick && onClick()
		window.open(`${getRequestDemoUrl()}?${queryParams.toString()}`, '_blank')
	}

	return (
		<CButton onClick={handleClick} colorScheme="blue" {...rest}>
			{T.translate(title ?? 'billing.configuration.requestDemo')}
		</CButton>
	)
}

type PopoverProps = {
	source: DemoRequestSource
	popoverTrigger: ReactNode
}

export const DemoRequestPopover = ({ source, popoverTrigger }: PopoverProps) => {
	return (
		<CPopover trigger="hover" closeOnBlur={false}>
			<PopoverTrigger>
				<Box>{popoverTrigger}</Box>
			</PopoverTrigger>
			<PopoverContent minW="min-content" whiteSpace="normal">
				<PopoverArrow />
				<PopoverBody>
					<Stack spacing={2}>
						<Box lineHeight="1.25" textAlign="center">
							{T.translate('billing.packages.addon.tooltip')}
						</Box>
						<DemoRequestButton title="billing.requestDemo.button" source={source} />
					</Stack>
				</PopoverBody>
			</PopoverContent>
		</CPopover>
	)
}
