import { batch } from 'react-redux'
import {
	createAsyncThunk,
	createEntityAdapter,
	createSelector,
	createSlice,
	isFulfilled,
	PayloadAction,
} from '@reduxjs/toolkit'

import { AgentEvents } from 'models/Agent'
import { AppThunkAction, DashboardState } from 'types'
import { ChatsSearchItem } from 'shared/models/Chat'
import {
	BinaryQueryOptions,
	Contact,
	ContactBanResponse,
	ContactChanges,
	ContactFilterOptions,
	ContactQueryOptions,
	ContactsExportBody,
	ContactsFindBody,
	ContactsListBody,
	ContactsListResponse,
	ContactsQuery,
	ContactsQueryName,
	ContactsQueryOp,
	ContactsQuerySort,
	ContactsSortOrder,
	ContactUnbanResponse,
	ReducedQueryOptions,
} from 'shared/models/Contact'
import { Properties } from 'shared/models/Property'
import { ApiError, flashMessage } from 'shared/utils'
import { routes } from 'configuration/routes'
import { getTrimmedLength, navigateTo } from 'utils'
import { chatsSelectors } from 'modules/chats'
import { openSystemModal, SystemModalType } from 'modules/systemModal'

import { contactsApi } from './api'
import { TagContactParams, UpdateContactParams } from './types'
import { addTag, removeTag } from './utils'

export type ContactsRootState = Pick<DashboardState, 'contacts'>

type FilterValues = {
	value?: string
	option?: ContactsQueryOp
}

type FilterOptions = {
	[key: string]: FilterValues
}

type FilterQuery = {
	name: ContactsQueryName
	op: ContactsQueryOp
	value: string
}

type ContactsFetch = {
	data: ContactsListBody
	sortBy: ContactsQuerySort
}

type FilterSetterState = {
	name: ContactQueryOptions
	isOpen: boolean
}

type FilterValueState = {
	name: ReducedQueryOptions
	isUnfit: boolean
}

type BooleanFilterOptions = {
	name: BinaryQueryOptions
	value: boolean | null
}

const contactsAdapter = createEntityAdapter<Contact>()

export const initialState = contactsAdapter.getInitialState({
	total: 0,
	lastResponseAfter: null as null | ContactsListResponse['after'],
	selectedContact: null as null | Contact,
	selectedContactChatsHistory: [] as ChatsSearchItem[],
	isInitialized: false,
	isFetchingContact: false,
	selectedMenuItems: [] as string[],
	isFetchingContactsList: false,
	searchValue: '',
	filterOptions: { name: {}, phone: {}, email: {} } as FilterOptions,
	filterTags: [] as string[],
	isDrawerOpen: false,
	isFilterSetterOpen: {
		name: false,
		phone: false,
		email: false,
		tags: false,
		gdprApproved: false,
		isUnseen: false,
	},
	isFilterValueUnfit: {
		name: false,
		phone: false,
		email: false,
	},
	sortBy: ContactsSortOrder.Newest as ContactsQuerySort,
	booleanFilterOptions: {
		gdprApproved: null as null | boolean,
		isUnseen: null as null | boolean,
	},
	unseenContactsTotal: 0,
	shouldResetOnRemovalCancel: false,
	isCrmModalOpen: false,
})

export const fetchContactsList = createAsyncThunk('contacts/FETCH_LIST', ({ data, sortBy }: ContactsFetch) => {
	return contactsApi.fetchContactsLead({
		...data,
		size: 50,
		sort: [
			{
				field: 'updatedAt',
				order: sortBy,
			},
		],
	})
})

export const exportContacts = createAsyncThunk('contacts/EXPORT', (data: ContactsExportBody) => {
	return contactsApi.exportContacts({
		...data,
	})
})

// only for fetching data (without store into state management)
export const fetchContactData = createAsyncThunk('contacts/FETCH_DATA', (id: Contact['id']) => {
	return contactsApi.getContact(id)
})

export const fetchContact = createAsyncThunk('contacts/FETCH', (id: Contact['id']) => {
	return contactsApi.getContact(id)
})

export const addTagContact = createAsyncThunk('contacts/ADD_TAG', (data: TagContactParams) => {
	return contactsApi.addTagContact(data)
})

export const removeTagContact = createAsyncThunk('contacts/REMOVE_TAG', (data: TagContactParams) => {
	return contactsApi.removeTagContact(data)
})

export const setContactsSeen = createAsyncThunk('contacts/SET_SEEN', (ids: string[]) => {
	return contactsApi.setContactsSeen(ids)
})

export const setAllContactsSeen = createAsyncThunk('contacts/SET_ALL_SEEN', () => {
	return contactsApi.setContactsSeen(null)
})

export const deleteContact = createAsyncThunk('contacts/DELETE', (id: Contact['id']) => {
	return contactsApi.deleteContact(id)
})

export const getUnseenCount = createAsyncThunk('contacts/UNSEEN_COUNT', () => {
	return contactsApi.getUnseenCount()
})

export const updateContact = createAsyncThunk<Contact, UpdateContactParams, { rejectValue: ApiError }>(
	'contacts/UPDATE',
	async (data: UpdateContactParams, { rejectWithValue }) => {
		try {
			return await contactsApi.updateContact(data)
		} catch (error) {
			const emailUpdated = 'email' in data
			flashMessage.error(emailUpdated ? 'invalid_email' : 'general.error')
			return rejectWithValue(error as ApiError)
		}
	},
)

export const banContact = createAsyncThunk<ContactBanResponse, Contact['id'], { rejectValue: ApiError }>(
	'contacts/BAN',
	async (id: Contact['id'], { rejectWithValue }) => {
		try {
			return await contactsApi.banContact(id)
		} catch (error) {
			flashMessage.error('general.error')
			return rejectWithValue(error as ApiError)
		}
	},
)

export const unbanContact = createAsyncThunk<ContactUnbanResponse, Contact['id'], { rejectValue: ApiError }>(
	'contacts/UNBAN',
	async (id: Contact['id'], { rejectWithValue }) => {
		try {
			return await contactsApi.unbanContact(id)
		} catch (error) {
			flashMessage.error('general.error')
			return rejectWithValue(error as ApiError)
		}
	},
)

export const findContact = createAsyncThunk('contacts/FIND', (data: ContactsFindBody) => {
	return contactsApi.findContact(data)
})

export const onContactUpdated =
	({ id, changes }: AgentEvents.ContactUpdated): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const selectedChat = chatsSelectors.getSelectedChat(state)
		const isAnySpecificFilterApplied = getIsSpecificFilterApplied(state)
		const searchValue = getSearchValue(state)
		const isSearchFilterApplied = searchValue.length > 2
		const shouldTriggerContactMatch = isAnySpecificFilterApplied || isSearchFilterApplied

		if (changes.properties) {
			const properties = changes.properties ?? {}
			dispatch(updatePropertiesFromEvent({ id, changes: properties }))
		} else {
			dispatch(updateContactChanges({ id, changes }))
		}

		shouldTriggerContactMatch && dispatch(fetchDoesContactMatchFilters(id))

		if ('bannedAt' in changes && selectedChat && selectedChat.contactId === id) {
			const isBanned = changes.bannedAt !== null
			if (isBanned) {
				flashMessage.warning('chat.visitorBlocked', { testId: 'flashMessage-warning-blocked-visitor' })
			} else {
				flashMessage.warning('chat.visitorUnblocked', { testId: 'flashMessage-warning-unblocked-visitor' })
			}
		}
	}

export const onContactTagAdded =
	({ id, tag: { key } }: AgentEvents.ContactTagEvent): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const contact = getContactById(state, id)
		const selectedContact = getSelectedContact(state)

		if (selectedContact && selectedContact.id === id) {
			dispatch(updateContactTags({ id, tags: addTag(selectedContact.tags, key) }))
		} else if (contact) {
			dispatch(updateContactTags({ id, tags: addTag(contact.tags, key) }))
		}
	}

export const onContactTagRemoved =
	({ id, tag: { key } }: AgentEvents.ContactTagEvent): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const contact = getContactById(state, id)
		const selectedContact = getSelectedContact(state)
		const selectedMenuItems = getSelectedMenuItems(state)
		const shouldTriggerFetchWithFilter = selectedMenuItems.includes(ContactQueryOptions.Tags)
		shouldTriggerFetchWithFilter && dispatch(filterAndFetch())

		if (selectedContact && selectedContact.id === id) {
			dispatch(updateContactTags({ id, tags: removeTag(selectedContact.tags, key) }))
		} else if (contact) {
			dispatch(updateContactTags({ id, tags: removeTag(contact.tags, key) }))
		}
	}

export const fetchMoreContacts = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const after = getContactsAfter(state)
	const data = contactsSelectors.getCreateQuery(state)
	const sortBy = contactsSelectors.getSortBy(state)

	if (after) {
		dispatch(fetchContactsList({ data: { ...data, after }, sortBy }))
	}
}

export const filterAndFetch = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const data = contactsSelectors.getCreateQuery(state)
	const sortBy = contactsSelectors.getSortBy(state)
	dispatch(fetchContactsList({ data, sortBy }))
}

export const fetchDoesContactMatchFilters =
	(contactId: string): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const { query } = contactsSelectors.getCreateQuery(state)
		const findContactQueryData = {
			id: contactId,
			query: query || [],
		}
		dispatch(findContact(findContactQueryData))
	}

export const resetFilterAndShowContacts =
	(key: string): AppThunkAction =>
	(dispatch) => {
		batch(() => {
			dispatch(removeAllFilterOptions())
			dispatch(removeAllFilterTags())
			dispatch(setSearchValue(''))
			dispatch(setSelectedMenuItems([ContactQueryOptions.Tags]))
			dispatch(addFilterTags(key))
		})
		navigateTo(routes.contacts.path)
	}

export const updateContactProperty =
	(key: string): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const contact = contactsSelectors.getSelectedContact(state)
		const properties = contactsSelectors.getSortedContactProperties(state)
		if (!properties || !contact) return

		const resultAction = await dispatch(updateContact({ id: contact.id, changes: { properties: { [key]: null } } }))
		if (updateContact.fulfilled.match(resultAction)) {
			flashMessage.success('properties.flashMessage.delete.success', {
				testId: 'flashMessage-success-property-remove',
			})
		} else flashMessage.error('general.error', { testId: 'flashMessage-error-property-remove' })
	}

export const onCancelRemove =
	(key: string): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const contact = contactsSelectors.getSelectedContact(state)
		const properties = contactsSelectors.getSortedContactProperties(state)
		if (!contact || !properties) return

		dispatch(updateContactProperties({ id: contact.id, key, value: properties[key] as string }))
		dispatch(setShouldResetOnRemovalCancel(true))
	}

export const removeProperty =
	(key: string): AppThunkAction =>
	(dispatch) => {
		dispatch(
			openSystemModal({
				name: SystemModalType.Delete,
				data: {
					title: 'properties.delete.modal.title',
					text: 'properties.delete.modal.description',
					onConfirm: () => dispatch(updateContactProperty(key)),
					onCancel: () => dispatch(onCancelRemove(key)),
				},
			}),
		)
	}

const contactsSlice = createSlice({
	name: 'contacts',
	initialState,
	reducers: {
		resetSelectedContact: (state) => {
			state.selectedContact = null
			state.selectedContactChatsHistory = []
		},
		setSelectedContactChatHistory: (state, { payload }: PayloadAction<ChatsSearchItem[]>) => {
			state.selectedContactChatsHistory = payload
		},
		initContacts: (state) => {
			state.isInitialized = false
			state.isDrawerOpen = false
		},
		setIsDrawerOpen: (state, { payload }: PayloadAction<boolean>) => {
			state.isDrawerOpen = payload
		},
		updateContactChanges: (state, { payload }: PayloadAction<{ id: Contact['id']; changes: ContactChanges }>) => {
			const { id, changes } = payload
			const { selectedContact } = state

			contactsAdapter.updateOne(state, { id, changes })

			if (selectedContact && id === selectedContact.id) {
				state.selectedContact = { ...selectedContact, ...changes }
			}
		},
		updatePropertiesFromEvent: (state, { payload }: PayloadAction<{ id: Contact['id']; changes: Properties }>) => {
			const { id, changes } = payload
			const { selectedContact } = state

			const existingProperties = selectedContact?.properties
			const updatedProperties = {
				...existingProperties,
				...changes,
			}

			const properties: Properties = Object.fromEntries(
				Object.entries(updatedProperties).filter(([_, value]) => value !== null),
			)

			contactsAdapter.updateOne(state, { id, changes: { properties } })

			if (selectedContact && id === selectedContact.id) {
				state.selectedContact = { ...selectedContact, properties }
			}
		},
		updateContactTags: (state, { payload }: PayloadAction<{ id: Contact['id']; tags: Contact['tags'] }>) => {
			const { id, tags } = payload
			const { selectedContact } = state

			contactsAdapter.updateOne(state, { id, changes: { tags } })

			if (selectedContact && id === selectedContact.id) {
				state.selectedContact = { ...selectedContact, tags }
			}
		},
		updateContactProperties: (
			state,
			{ payload }: PayloadAction<{ id: Contact['id']; key: string; value: string | boolean }>,
		) => {
			const { id, key, value } = payload
			const { selectedContact } = state
			const existingProperties = selectedContact?.properties
			const properties = {
				...existingProperties,
				[key]: value,
			}

			contactsAdapter.updateOne(state, { id, changes: { properties } })

			if (selectedContact && id === selectedContact.id) {
				state.selectedContact = { ...selectedContact, properties }
			}
		},
		setSelectedMenuItems: (state, { payload }: PayloadAction<string[]>) => {
			state.selectedMenuItems = payload
		},
		removeSelectedItem: (state, { payload }: PayloadAction<string>) => {
			state.selectedMenuItems = state.selectedMenuItems.filter((item) => item !== payload)
		},
		setSearchValue: (state, { payload }: PayloadAction<string>) => {
			state.searchValue = payload
		},
		addFilterOption: (state, { payload }: PayloadAction<FilterQuery>) => {
			state.filterOptions[payload.name].option = payload.op
			state.filterOptions[payload.name].value = payload.value
		},
		setFilterValue: (state, { payload }: PayloadAction<Omit<FilterQuery, 'op'>>) => {
			state.filterOptions[payload.name].value = payload.value
		},
		removeFilterOption: (state, { payload }: PayloadAction<ContactQueryOptions>) => {
			state.filterOptions[payload] = {}
		},
		removeAllFilterOptions: (state) => {
			state.filterOptions = { name: {}, phone: {}, email: {} }
			state.booleanFilterOptions = { gdprApproved: null, isUnseen: null }
		},
		addFilterTags: (state, { payload }: PayloadAction<string>) => {
			state.filterTags = [...state.filterTags, payload]
		},
		removeFilterTag: (state, { payload }: PayloadAction<string>) => {
			state.filterTags = state.filterTags.filter((tag) => tag !== payload)
		},
		removeAllFilterTags: (state) => {
			state.filterTags = []
		},
		setIsFilterSetterOpen: (state, { payload }: PayloadAction<FilterSetterState>) => {
			state.isFilterSetterOpen[payload.name] = payload.isOpen
		},
		closeAllFilterSetters: (state) => {
			state.isFilterSetterOpen = {
				name: false,
				phone: false,
				email: false,
				tags: false,
				gdprApproved: false,
				isUnseen: false,
			}
		},
		setIsFilterValueUnfit: (state, { payload }: PayloadAction<FilterValueState>) => {
			state.isFilterValueUnfit[payload.name] = payload.isUnfit
		},
		setSortBy: (state, { payload }: PayloadAction<ContactsQuerySort>) => {
			state.sortBy = payload
		},
		setBooleanFilterOptions: (state, { payload }: PayloadAction<BooleanFilterOptions>) => {
			state.booleanFilterOptions[payload.name] = payload.value
		},
		setShouldResetOnRemovalCancel: (state, { payload }: PayloadAction<boolean>) => {
			state.shouldResetOnRemovalCancel = payload
		},
		setIsCrmModalOpen: (state, { payload }: PayloadAction<boolean>) => {
			state.isCrmModalOpen = payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchContactsList.pending, (state) => {
				state.isFetchingContactsList = true
			})
			.addCase(fetchContactsList.fulfilled, (state, { payload, meta }) => {
				const { items, after, total } = payload
				const loadMoreContacts = !!meta?.arg?.data?.after
				state.isFetchingContactsList = false
				if (loadMoreContacts) {
					contactsAdapter.addMany(state, items)
				} else {
					contactsAdapter.setAll(state, items)
					state.isInitialized = true
				}

				state.total = total
				state.lastResponseAfter = after
			})
			.addCase(fetchContactsList.rejected, (state) => {
				state.isFetchingContactsList = false
			})
		builder
			.addCase(fetchContact.pending, (state) => {
				state.isFetchingContact = true
			})
			.addCase(fetchContact.fulfilled, (state, { payload }) => {
				state.isFetchingContact = false
				contactsAdapter.updateOne(state, { id: payload.id, changes: payload })
				state.selectedContact = payload
			})
			.addCase(fetchContact.rejected, (state) => {
				state.isFetchingContact = false
			})

		builder.addCase(updateContact.fulfilled, (state, { payload, meta }) => {
			const contactId = meta.arg.id
			const { selectedContact } = state
			contactsAdapter.updateOne(state, { id: payload.id, changes: payload })

			if (selectedContact && contactId === selectedContact.id) {
				state.selectedContact = { ...selectedContact, ...payload }
			}
		})

		builder.addCase(deleteContact.fulfilled, (state, { meta }) => {
			contactsAdapter.removeOne(state, meta.arg)
			state.total -= 1
		})
		builder.addCase(setAllContactsSeen.fulfilled, (state) => {
			state.unseenContactsTotal = 0
			const notSeenContactsIds = contactsAdapter
				.getSelectors()
				.selectAll(state)
				.filter((contact) => contact.isUnseen)
				.map((contact) => contact.id)
			const isFilterBySeenApplied = state.booleanFilterOptions.isUnseen !== null
			if (isFilterBySeenApplied) {
				contactsAdapter.removeMany(state, notSeenContactsIds)
				state.total -= notSeenContactsIds.length
			}

			const updatedContacts = state.ids.map((id) => {
				return {
					id,
					changes: { isUnseen: false },
				}
			})
			contactsAdapter.updateMany(state, updatedContacts)
		})
		builder.addCase(setContactsSeen.fulfilled, (state, { meta }) => {
			const isFilterBySeenApplied = state.booleanFilterOptions.isUnseen !== null
			if (isFilterBySeenApplied) {
				contactsAdapter.removeOne(state, meta.arg[0])
				state.total -= 1
			}
			state.unseenContactsTotal -= 1
		})
		builder.addCase(getUnseenCount.fulfilled, (state, { payload }) => {
			state.unseenContactsTotal = payload.count
		})
		builder.addCase(findContact.rejected, (state, { meta }) => {
			contactsAdapter.removeOne(state, meta.arg.id)
			state.total -= 1
		})
		builder.addMatcher(isFulfilled(banContact, unbanContact), (state, { payload, meta }) => {
			const contactId = meta.arg
			const { selectedContact } = state

			contactsAdapter.updateOne(state, { id: contactId, changes: payload })

			if (selectedContact && contactId === selectedContact.id) {
				state.selectedContact = { ...selectedContact, ...payload }
			}
		})
	},
})

const { reducer, actions } = contactsSlice
export const {
	setSelectedContactChatHistory,
	updateContactChanges,
	resetSelectedContact,
	initContacts,
	setSelectedMenuItems,
	removeSelectedItem,
	addFilterOption,
	setFilterValue,
	removeFilterOption,
	removeAllFilterOptions,
	setSearchValue,
	setIsDrawerOpen,
	addFilterTags,
	removeFilterTag,
	removeAllFilterTags,
	setIsFilterSetterOpen,
	closeAllFilterSetters,
	updateContactTags,
	setIsFilterValueUnfit,
	setSortBy,
	setBooleanFilterOptions,
	updateContactProperties,
	updatePropertiesFromEvent,
	setShouldResetOnRemovalCancel,
	setIsCrmModalOpen,
} = actions
export default reducer

const entitySelectors = contactsAdapter.getSelectors<ContactsRootState>((state) => state.contacts)
const getContacts = (state: ContactsRootState) => entitySelectors.selectAll(state)
const getContactsTotal = (state: ContactsRootState) => state.contacts.total
const getContactsAfter = (state: ContactsRootState) => state.contacts.lastResponseAfter
const getSelectedContact = (state: ContactsRootState) => state.contacts.selectedContact
const getSelectedContactChatHistory = (state: ContactsRootState) => state.contacts.selectedContactChatsHistory
const getIsInitialized = (state: ContactsRootState) => state.contacts.isInitialized
const getIsDrawerOpen = (state: ContactsRootState) => state.contacts.isDrawerOpen
const getContactById = (state: ContactsRootState, id: Contact['id']) => entitySelectors.selectById(state, id)
const getSelectedMenuItems = (state: ContactsRootState) => state.contacts.selectedMenuItems
const getFilterOptions = (state: ContactsRootState) => state.contacts.filterOptions
const getSearchValue = (state: ContactsRootState) => state.contacts.searchValue
const getFilterTags = (state: ContactsRootState) => state.contacts.filterTags
const getIsFetching = (state: ContactsRootState) => state.contacts.isFetchingContactsList
const getIsFilterSetterOpen = (state: ContactsRootState) => state.contacts.isFilterSetterOpen
const getIsFilterValueUnfit = (state: ContactsRootState) => state.contacts.isFilterValueUnfit
const getSortBy = (state: ContactsRootState) => state.contacts.sortBy
const getBooleanFilterOptions = (state: ContactsRootState) => state.contacts.booleanFilterOptions
const getUnseenContactsCount = (state: ContactsRootState) => state.contacts.unseenContactsTotal
const getShouldResetOnRemovalCancel = (state: ContactsRootState) => state.contacts.shouldResetOnRemovalCancel
const getIsCrmModalOpen = (state: ContactsRootState) => state.contacts.isCrmModalOpen

const getContactByIndex = (index: number) => {
	return createSelector([getContacts], (contacts): Contact | null => {
		return contacts[index] ?? null
	})
}

const getLoadedContactsCount = createSelector([getContacts], (contacts) => {
	return contacts.length
})

const hasContactsNextPage = createSelector([getLoadedContactsCount, getContactsTotal], (count, total) => {
	return count < total
})

const getIsAnyFilterValueUnfit = createSelector([getIsFilterValueUnfit], (values) => {
	const isAnyValueUnfitForSearch = Object.values(values).includes(true)
	return isAnyValueUnfitForSearch
})

const getFilterValue = (queryOptions?: ContactQueryOptions) => {
	return createSelector([getSearchValue, getFilterOptions], (searchValue, options): string => {
		if (!queryOptions) return searchValue
		const filterValue = options[queryOptions].value
		if (!filterValue) return ''
		return filterValue
	})
}

const getShouldDisplayAsShortInput = createSelector(
	[getSearchValue, getIsAnyFilterValueUnfit],
	(searchValue, isFilterValueUnfit) => {
		const isSearchValueUnfit = getTrimmedLength(searchValue) > 0 && getTrimmedLength(searchValue) < 3
		return isFilterValueUnfit || isSearchValueUnfit
	},
)

const getCreateFilterQuery = createSelector(
	[getFilterOptions, getFilterTags, getBooleanFilterOptions],
	(options, tags, booleanFilterOptions) => {
		const { gdprApproved, isUnseen } = booleanFilterOptions
		const query: ContactsQuery[] = Object.entries(options)
			.filter(([_, { value }]) => value && value.length > 2)
			.map(([name, { option, value }]) => ({
				name: name as ContactsQueryName,
				op: option as ContactsQueryOp,
				value,
			}))

		const tagsQuery: ContactsQuery[] = tags.map((tag) => {
			return {
				name: ContactQueryOptions.Tags,
				op: ContactFilterOptions.Equal,
				value: tag,
			}
		})

		const gdprQuery: ContactsQuery | null =
			gdprApproved === null
				? null
				: {
						name: ContactQueryOptions.GdprApproved,
						op: gdprApproved ? ContactFilterOptions.Equal : ContactFilterOptions.NotEq,
						value: true,
					}

		const isUnseenQuery: ContactsQuery | null =
			isUnseen === null
				? null
				: {
						name: ContactQueryOptions.IsUnseen,
						op: isUnseen ? ContactFilterOptions.Equal : ContactFilterOptions.NotEq,
						value: true,
					}

		return [...query, ...tagsQuery, ...(gdprQuery ? [gdprQuery] : []), ...(isUnseenQuery ? [isUnseenQuery] : [])]
	},
)

const getCreateQuery = createSelector([getSearchValue, getCreateFilterQuery], (searchValue, filterQuery) => {
	const searchQuery = {
		op: ContactFilterOptions.Or,
		value: [
			{
				name: ContactQueryOptions.Name,
				op: ContactFilterOptions.Match,
				value: searchValue,
			},
			{
				name: ContactQueryOptions.Email,
				op: ContactFilterOptions.Contains,
				value: searchValue,
			},
			{
				name: ContactQueryOptions.Phone,
				op: ContactFilterOptions.Contains,
				value: searchValue,
			},
		],
	}

	const searchData: ContactsListBody = {
		query: getTrimmedLength(searchValue) > 2 ? [searchQuery, ...filterQuery] : filterQuery,
	}

	return searchData
})

const getIsAnyFilterApplied = createSelector(
	[getSearchValue, getCreateFilterQuery],
	(searchValue, filterQuery): boolean => {
		const isAnyFilterApplied = getTrimmedLength(searchValue) > 2 || filterQuery.length > 0
		return isAnyFilterApplied
	},
)

const getIsSpecificFilterSetterOpen = (title: ContactQueryOptions) => {
	return createSelector([getIsFilterSetterOpen], (filterSetter) => {
		return filterSetter[title]
	})
}

const getIsAnyContactUnseen = createSelector([getUnseenContactsCount], (total) => {
	const isAnyContactUnseen = total > 0
	return isAnyContactUnseen
})

const getIsSpecificFilterApplied = createSelector([getSelectedMenuItems], (selectedMenuItems) => {
	const specificFilters = new Set<string>([
		ContactQueryOptions.Name,
		ContactQueryOptions.Email,
		ContactQueryOptions.Phone,
	])
	const isAnySpecificFilterApplied = selectedMenuItems.some((item) => specificFilters.has(item))
	return isAnySpecificFilterApplied
})

const getSortedContactProperties = createSelector([getSelectedContact], (contact): Properties | null => {
	if (!contact?.properties) return null
	const { properties } = contact
	const sortedKeys = Object.keys(properties).sort()
	const sortedEntries = sortedKeys.map((key) => [key, properties[key]])
	return Object.fromEntries(sortedEntries)
})

export const contactsSelectors = {
	getContacts,
	getContactById,
	getSelectedContact,
	getSelectedContactChatHistory,
	getSelectedMenuItems,
	getContactByIndex,
	getContactsTotal,
	getLoadedContactsCount,
	hasContactsNextPage,
	getIsInitialized,
	getFilterOptions,
	getSearchValue,
	getCreateFilterQuery,
	getFilterValue,
	getIsDrawerOpen,
	getCreateQuery,
	getFilterTags,
	getIsAnyFilterApplied,
	getIsFetching,
	getIsSpecificFilterSetterOpen,
	getShouldDisplayAsShortInput,
	getIsFilterValueUnfit,
	getIsAnyFilterValueUnfit,
	getIsAnyContactUnseen,
	getSortBy,
	getIsSpecificFilterApplied,
	getBooleanFilterOptions,
	getUnseenContactsCount,
	getSortedContactProperties,
	getShouldResetOnRemovalCancel,
	getIsCrmModalOpen,
}
