import {
	createAsyncThunk,
	createEntityAdapter,
	createSelector,
	createSlice,
	isFulfilled,
	isPending,
	isRejected,
	PayloadAction,
} from '@reduxjs/toolkit'

import { AppThunkAction, DashboardState } from 'types'
import {
	CreateSourceBody,
	ExtendedSourceType,
	PatchSourceBody,
	Source,
	SourceCategory,
	SourceStatus,
} from 'shared/models/Source'
import { flashMessage } from 'shared/utils'
import { trackAiSourcesIncompleteSourceCreated } from 'utils'

import { sourcesApi } from './api'
import { DEFAULT_FORM_VALUES } from './constants'
import { SourceFormData, SourceFormError, SourceFormType, SourcesModalSection, SourcesModalType } from './types'
import { countCategoriesSize, getIsProcessingSource, getSelectedSourceFormType } from './utils'

export type SourcesRootState = Pick<DashboardState, 'sources'>
export type SourcesState = typeof initialState

const sourcesAdapter = createEntityAdapter<Source>()

export const initialState = sourcesAdapter.getInitialState({
	lastFetchedTime: null as null | number,
	isFetching: false,
	isSaving: false,
	modalData: {
		isOpen: false,
		selectedSource: null as null | Source,
		sourceCheck: {
			isValid: true,
			size: 0,
		},
		modalType: SourcesModalType.Create,
		modalSection: SourcesModalSection.Type,
	},
	contentModalData: {
		isOpen: false,
		sourceContent: null as null | string,
		isFetching: false,
	},
	categories: [] as SourceCategory[],
	selectedCategories: [] as string[],
})

export const fetchSources = createAsyncThunk(
	'sources/FETCH',
	() => {
		return sourcesApi.searchSources()
	},
	{
		condition: (_, { getState }) => {
			const state = getState() as SourcesRootState
			const lastFetchedTime = getLastFetchedTime(state)
			const currentTime = Date.now()
			return !lastFetchedTime || currentTime - lastFetchedTime > 10000
		},
	},
)

export const createSource = createAsyncThunk('sources/CREATE', (data: CreateSourceBody) => {
	return sourcesApi.createSource(data)
})

export const editSource = createAsyncThunk('sources/EDIT', (data: { id: Source['id']; changes: PatchSourceBody }) => {
	return sourcesApi.editSource(data.id, data.changes)
})

export const deleteSource = createAsyncThunk('sources/DELETE', (id: Source['id']) => {
	return sourcesApi.deleteSource(id)
})

export const refreshSource = createAsyncThunk('sources/REFRESH', (id: Source['id']) => {
	return sourcesApi.refreshSource(id)
})

export const fetchSourceContent = createAsyncThunk(
	'sources/FETCH_CONTENT',
	(data: { id: Source['id']; advanced: boolean }) => {
		return sourcesApi.getContent(data.id, data.advanced)
	},
)

export const checkSource = createAsyncThunk('sources/CHECK', (parser: Source['parser']) => {
	return sourcesApi.checkSource(parser)
})

export const submitSourceForm =
	(data: SourceFormData, onError: (code?: string) => void, onCreate?: (id: Source['id']) => void): AppThunkAction =>
	async (dispatch, getState) => {
		const { modalType, modalSection } = sourcesSelectors.getModalData(getState())
		const { id, formType, parser, title } = data
		const { url, type, currency } = parser
		const selectedCategories = sourcesSelectors.getSelectedCategories(getState())
		const isModalSectionCategories = modalSection === SourcesModalSection.Categories
		const shouldCheck = modalType === SourcesModalType.Create && !isModalSectionCategories

		const parserType = formType === SourceFormType.Web ? ExtendedSourceType.WebsiteCrawl : type
		const parserCurrency = parserType === ExtendedSourceType.HeurekaFeed ? currency : undefined // Send currency only for Heureka feed

		if (!parserType) return

		if (shouldCheck) {
			const resultCheck = await dispatch(checkSource({ type: parserType, url }))
			if (checkSource.fulfilled.match(resultCheck)) {
				if (!resultCheck.payload.fileValid) {
					onError(formType === SourceFormType.Xml ? 'type_not_matching' : 'invalid_web')
					return
				}
				if (resultCheck.payload.limitReached) {
					dispatch(setIsSourceValid({ isValid: false, size: resultCheck.payload.size }))
					if (formType === SourceFormType.Xml) {
						dispatch(setCategories(resultCheck.payload.categoryList))
						dispatch(changeModalSection(SourcesModalSection.Categories))
					}
					return
				}
			}
			if (checkSource.rejected.match(resultCheck)) {
				flashMessage.error('general.error')
				return
			}
		}

		const requestData = {
			title,
			parser: {
				type: parserType,
				url,
				currency: parserCurrency,
				...(isModalSectionCategories && { categories: selectedCategories }),
			},
		}

		const getErrorMessage = (errorCode?: string): string => {
			switch (errorCode) {
				case SourceFormError.TITLE_NOT_UNIQUE: {
					return 'title_not_unique'
				}
				case SourceFormError.URL_HOST_MISMATCH: {
					return 'url_host_mismatch'
				}
				default: {
					return 'general.error'
				}
			}
		}

		if (modalType === SourcesModalType.Create) {
			const resultCreate = await dispatch(createSource(requestData))
			if (createSource.fulfilled.match(resultCreate)) {
				isModalSectionCategories && trackAiSourcesIncompleteSourceCreated()
				flashMessage.success('sources.create.flashmessage.success')
				dispatch(closeModal())
				onCreate && onCreate(resultCreate.payload.id)
			} else {
				flashMessage.error(getErrorMessage(resultCreate.error.code))
				onError(resultCreate.error.code)
			}
		} else if (modalType === SourcesModalType.Edit && id) {
			const resultEdit = await dispatch(editSource({ id, changes: requestData }))
			if (editSource.fulfilled.match(resultEdit)) {
				flashMessage.success('sources.edit.flashmessage.success')
				dispatch(closeModal())
			} else {
				flashMessage.error(getErrorMessage(resultEdit.error.code))
				onError(resultEdit.error.code)
			}
		}
	}

export const deleteSourceItem =
	(id: Source['id']): AppThunkAction =>
	async (dispatch) => {
		const resultAction = await dispatch(deleteSource(id))
		if (deleteSource.fulfilled.match(resultAction)) {
			flashMessage.success('sources.delete.flashmessage.success')
		} else {
			flashMessage.error('general.error')
		}
	}

export const onSourceStatusUpdated =
	(data: Source): AppThunkAction =>
	async (dispatch) => {
		dispatch(actions.updateSourceStatus(data))
	}

const sourcesSlice = createSlice({
	name: 'sources',
	initialState,
	reducers: {
		openCreateModal: (state, { payload }: PayloadAction<SourcesModalSection | undefined>) => {
			state.modalData = {
				isOpen: true,
				selectedSource: null,
				sourceCheck: {
					isValid: true,
					size: 0,
				},
				modalType: SourcesModalType.Create,
				modalSection: payload ?? SourcesModalSection.Type,
			}
			state.categories = []
			state.selectedCategories = []
		},
		openEditModal: (state, { payload }: PayloadAction<Source>) => {
			state.modalData = {
				isOpen: true,
				selectedSource: payload,
				sourceCheck: {
					isValid: true,
					size: 0,
				},
				modalType: SourcesModalType.Edit,
				modalSection: SourcesModalSection.Details,
			}
		},
		closeModal: (state) => {
			state.modalData.isOpen = false
			state.modalData.selectedSource = null
		},
		changeModalSection: (state, { payload }: PayloadAction<SourcesModalSection>) => {
			state.modalData.modalSection = payload
		},
		setIsSourceValid: (state, { payload }: PayloadAction<{ isValid: boolean; size: number }>) => {
			state.modalData.sourceCheck = payload
		},
		openContentModal: (state) => {
			state.contentModalData.isOpen = true
		},
		closeContentModal: (state) => {
			state.contentModalData.isOpen = false
		},
		updateSourceStatus: (state, { payload }: PayloadAction<Source>) => {
			const { id, ...rest } = payload
			sourcesAdapter.updateOne(state, { id, changes: rest })
		},
		selectCategory: (state, { payload }: PayloadAction<string>) => {
			state.selectedCategories = [...state.selectedCategories, payload]
		},
		unselectCategory: (state, { payload }: PayloadAction<string>) => {
			state.selectedCategories = state.selectedCategories.filter((category) => category !== payload)
		},
		selectAllCategories: (state) => {
			state.selectedCategories = state.categories.map((e) => e.name)
		},
		unselectAllCategories: (state) => {
			state.selectedCategories = []
		},
		setCategories: (state, { payload }) => {
			state.categories = payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchSources.fulfilled, (state, { payload }) => {
				sourcesAdapter.setAll(state, payload)
				state.lastFetchedTime = Date.now()
				state.isFetching = false
			})
			.addCase(fetchSources.rejected, (state) => {
				state.isFetching = false
			})
			.addCase(fetchSources.pending, (state) => {
				state.isFetching = true
			})
		builder
			.addCase(fetchSourceContent.fulfilled, (state, { payload }) => {
				state.contentModalData.sourceContent = JSON.stringify(JSON.parse(payload.content), null, 4)
				state.contentModalData.isFetching = false
			})
			.addCase(fetchSourceContent.rejected, (state) => {
				state.contentModalData.isFetching = false
			})
			.addCase(fetchSourceContent.pending, (state) => {
				state.contentModalData.isFetching = true
			})
		builder.addCase(createSource.fulfilled, (state, { payload }) => {
			sourcesAdapter.addOne(state, payload)
		})
		builder.addCase(editSource.fulfilled, (state, { payload }) => {
			sourcesAdapter.updateOne(state, { id: payload.id, changes: payload })
		})
		builder.addCase(deleteSource.fulfilled, (state, { meta }) => {
			sourcesAdapter.removeOne(state, meta.arg)
		})
		builder.addCase(refreshSource.fulfilled, (state, { payload }) => {
			sourcesAdapter.updateOne(state, { id: payload.id, changes: payload })
		})
		builder.addMatcher(isPending(editSource, createSource, checkSource), (state) => {
			state.isSaving = true
		})
		builder.addMatcher(isRejected(editSource, createSource, checkSource), (state) => {
			state.isSaving = false
		})
		builder.addMatcher(isFulfilled(editSource, createSource, checkSource), (state) => {
			state.isSaving = false
		})
	},
})

const entitiesSelectors = sourcesAdapter.getSelectors<SourcesRootState>((state) => state.sources)
const getModalData = (state: SourcesRootState) => state.sources.modalData
const getSources = (state: SourcesRootState) => entitiesSelectors.selectAll(state)
const getLastFetchedTime = (state: SourcesRootState) => state.sources.lastFetchedTime
const getIsFetchingSources = (state: SourcesRootState) => state.sources.isFetching
const getIsSavingSource = (state: SourcesRootState) => state.sources.isSaving
const getContentModalData = (state: SourcesRootState) => state.sources.contentModalData
const getSelectedCategories = (state: SourcesRootState) => state.sources.selectedCategories
const getCategories = (state: SourcesRootState) => state.sources.categories

const getHasSources = createSelector([getSources], (sources): boolean => {
	return sources.length > 0
})

const getSourceFormData = createSelector([getModalData, getCategories], (modalData, categories): SourceFormData => {
	const { selectedSource } = modalData

	if (!selectedSource) return DEFAULT_FORM_VALUES

	return {
		id: selectedSource.id,
		title: selectedSource.title,
		parser: selectedSource.parser,
		formType: getSelectedSourceFormType(selectedSource.parser.type),
		categories,
	}
})

const getPendingSources = createSelector([getSources], (sources): Source['id'][] => {
	return sources.filter((source) => getIsProcessingSource(source.status)).map((source) => source.id)
})

const getFailedSources = createSelector([getSources], (sources): Source['id'][] => {
	return sources.filter((source) => source.status === SourceStatus.Failed).map((source) => source.id)
})

const getAllCategoriesSizesSum = createSelector([getCategories], (categories) => {
	return countCategoriesSize(categories)
})

const getSelectedCategoriesSize = createSelector(
	[getSelectedCategories, getCategories],
	(selectedCategories, categories) => {
		const filteredCategories = categories.filter((category) => selectedCategories.includes(category.name))
		return countCategoriesSize(filteredCategories)
	},
)

const getCategoriesSizes = createSelector([getCategories], (categories) => {
	return categories.map((category) => category.size)
})

const getCategoriesCount = createSelector([getCategories], (categories) => {
	return categories.length
})

const getSelectedCategoriesCount = createSelector([getSelectedCategories], (selectedCategories) => {
	return selectedCategories.length
})

const getAreSelectedCategoriesEmpty = createSelector([getSelectedCategoriesCount], (selectedCategoriesCount) => {
	return selectedCategoriesCount === 0
})

const getAreCategoriesEmpty = createSelector([getCategoriesCount], (categoriesCount) => {
	return categoriesCount === 0
})

const getWebscrapeSourcesIds = createSelector([getSources], (sources): Source['id'][] => {
	return sources.filter((source) => source.parser.type === ExtendedSourceType.WebsiteCrawl).map((source) => source.id)
})

const { actions, reducer } = sourcesSlice

export const sourcesSelectors = {
	getSources,
	getLastFetchedTime,
	getIsFetchingSources,
	getModalData,
	getSourceFormData,
	getIsSavingSource,
	getHasSources,
	getContentModalData,
	getPendingSources,
	getFailedSources,
	getSelectedCategories,
	getCategories,
	getSelectedCategoriesSize,
	getAllCategoriesSizesSum,
	getCategoriesSizes,
	getCategoriesCount,
	getSelectedCategoriesCount,
	getAreSelectedCategoriesEmpty,
	getAreCategoriesEmpty,
	getWebscrapeSourcesIds,
}

export const {
	openCreateModal,
	openEditModal,
	closeModal,
	changeModalSection,
	setIsSourceValid,
	openContentModal,
	closeContentModal,
	selectAllCategories,
	unselectCategory,
	selectCategory,
	unselectAllCategories,
	setCategories,
} = actions
export default reducer
