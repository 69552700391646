// TODO use types from mira-types spec

export enum SourceStatus {
	Creating = 'creating',
	Updating = 'updating',
	Ready = 'ready',
	Failed = 'failed',
	Warning = 'warning',
	Pending = 'pending',
}
export enum SourceErrorCodes {
	SOURCE_NOT_UNIQUE = 'source_not_unique',
	SOURCE_INVALID_URL = 'invalid_url',
	SOURCE_INVALID_FORMAT = 'invalid_format',
	SOURCE_PARSING_FAILED = 'parsing_failed',
	SOURCE_TIMEOUT = 'timeout',
	SOURCE_BUILD_FAILED = 'build_failed',
	SOURCE_LIMIT_REACHED = 'limit_reached',
}

export enum SourceErrorMessages {
	SOURCE_LIMIT_REACHED = 'limit-reached',
}

export interface SourceStatusError {
	code: SourceErrorCodes
	message: string
}

export enum ExtendedSourceType {
	WEB = 'web',
	XML = 'xml',
	WebsiteCrawl = 'website_crawl',
	HeurekaFeed = 'heureka_feed',
	GoogleFeed = 'google_feed',
}

interface SourceParser {
	url: string
	type: ExtendedSourceType
	currency?: string
}

export interface Source {
	id: number
	accountId: string
	title: string
	parser: SourceParser
	status: SourceStatus
	error?: SourceStatusError
	itemsCount?: number
	publishedAt?: string
	categories: SourceCategory[]
}

export type SourceCategory = {
	name: string
	items: number
	size: number
}

export type SourceContent = {
	sourceId: number
	content: string
}

export interface CreateSourceBody {
	title: Source['title']
	parser: Source['parser']
}

export interface PatchSourceBody {
	title?: Source['title']
	parser?: Source['parser']
}

export type SourceCheckResult = {
	fileValid: boolean
	limitReached: boolean
	size: number
	categoryList: SourceCategory[]
}
