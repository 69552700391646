import { createSelector, createSlice, PayloadAction, unwrapResult } from '@reduxjs/toolkit'

import {
	BraintreeThreeDSecureParams,
	PackageConfiguration,
	PackageConfigurationStripe,
	PackageInterval,
	PackageIntervalAvailable,
	PackageLogAction,
	PackageLogEventRequest,
	PackageName,
	PaymentGateway,
	PaymentType,
	StripeSubscriptionCalculationRequest,
	StripeSubscriptionOrderItem,
	StripeSubscriptionOrderItemCode,
	StripeSubscriptionSaveRequest,
	StripeSubscriptionSaveRequestInterval,
	SubscriptionCalculateRequest,
	SubscriptionCalculation,
	SubscriptionCreateRequest,
} from 'models'
import { AppThunkAction, DashboardState } from 'types'
import { StripeSubscriptionCalculationRequestPackageCode } from 'shared/models/Billing'
import { isNumber } from 'shared/utils'
import { createCheckoutLink, routes } from 'configuration/routes'
import { CheckoutStartAction } from 'constants/checkout'
import { navigateTo, redirectTo } from 'utils'
import { billingInfoSelectors } from 'modules/billingInfo'
import { fetchPackage, getTransferPackageConfiguration, logPackageEvent, packageSelectors } from 'modules/package'
import { packagesSelectors } from 'modules/packages/selectors'
import {
	fetchPaymentMethod,
	paymentMethodSelectors,
	postPaymentError,
	updatePaymentMethod as updatePaymentMethodThunk,
} from 'modules/paymentMethod'
import { calculateStripeSubscription } from 'modules/stripe/actions'
import { stripeSharedSelectors } from 'modules/stripe/sharedSelectors'
import { resolveStripeInterval, resolveStripePackageName } from 'modules/stripe/utils'
import {
	calculateSubscription,
	createSubscriptionFromTransfer,
	resumeSubscription,
	subscriptionSelectors,
} from 'modules/subscription'
import { notificationSelectors, setShowAiYearlyNotification } from 'modules/systemNotification'
import { usageSelectors } from 'modules/usage'

import {
	CalculationTrigger,
	DELAY_5_SEC,
	MAX_CHECKOUT_AGENTS,
	MIN_CHECKOUT_AGENTS,
	newSubscriptionSteps,
	shopifySubscriptionSteps,
	updateSubscriptionSteps,
} from './constants'
import {
	CheckoutAllowedPaymentMethod,
	CheckoutConfigurationAllowedPackageName,
	CheckoutStatusData,
	CheckoutStatusModalLogData,
	CheckoutStep,
	CheckoutStepInfo,
	CheckoutType,
	Discount,
	DiscountType,
} from './types'
import {
	getAllowedCheckoutIntervalsFromGateways,
	getConversationAvailableValue,
	getDefaultAllowedCheckoutInterval,
	isCheckoutAgentsCountValid,
	isCheckoutAiConversationsCountValid,
	isCheckoutChatbotConversationsCountValid,
	isCheckoutIntervalValid,
	isCheckoutLivechatConversationsCountValid,
	sanitizeBraintreeParamString,
} from './utils'

const DEFAULT_CHECKOUT_STEP = newSubscriptionSteps[CheckoutStep.Configuration].number
const DEFAULT_CHECKOUT_INTERVAL = PackageInterval.Year
const DEFAULT_PAYMENT_METHOD = PaymentType.CreditCard
const DEFAULT_CHECKOUT_AGENTS = 0
const DEFAULT_CHECKOUT_CHATBOT_CONVERSATIONS = 0
const DEFAULT_CHECKOUT_LIVECHAT_CONVERSATIONS = 0
const DEFAULT_CHECKOUT_AI_CONVERSATIONS = 0

export const initialState = {
	currentStep: DEFAULT_CHECKOUT_STEP,
	package: PackageName.Basic as CheckoutConfigurationAllowedPackageName,
	interval: DEFAULT_CHECKOUT_INTERVAL as PackageIntervalAvailable,
	defaultInterval: DEFAULT_CHECKOUT_INTERVAL as PackageIntervalAvailable,
	agents: DEFAULT_CHECKOUT_AGENTS as number,
	defaultAgents: DEFAULT_CHECKOUT_AGENTS as number,
	chatbotConversations: DEFAULT_CHECKOUT_CHATBOT_CONVERSATIONS,
	defaultChatbotConversations: DEFAULT_CHECKOUT_CHATBOT_CONVERSATIONS,
	livechatConversations: DEFAULT_CHECKOUT_LIVECHAT_CONVERSATIONS,
	defaultLivechatConversations: DEFAULT_CHECKOUT_LIVECHAT_CONVERSATIONS,
	aiConversations: DEFAULT_CHECKOUT_AI_CONVERSATIONS,
	defaultAiConversations: DEFAULT_CHECKOUT_AI_CONVERSATIONS,
	aiConversationsInitialized: false,
	paymentMethod: DEFAULT_PAYMENT_METHOD as CheckoutAllowedPaymentMethod,
	checkoutStatusData: null as null | CheckoutStatusData,
	calculation: null as null | SubscriptionCalculation,
	isCalculationValid: false,
	isConfigurationDirty: false, // If user changed some config values, don't reset them to defaults
	calculationTrigger: CalculationTrigger.Default,
	isLivechatPackagesConfigurationCollapsed: false,
	initialPackage: {
		packageName: null as null | PackageName,
		aiConversations: null as null | number,
	},
	redirectUri: null as null | string,
	isPaymentProcessing: false,
}

export type CheckoutRootState = Pick<DashboardState, 'checkout'>
export type CheckoutState = typeof initialState

/// TODO: Refactor after BF !!
export const openCheckout =
	(packageName: PackageName, interval?: PackageInterval, promoCode?: string): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const packageInfo = packageSelectors.getPackageInfo(state)
		const isStripeAllowed = packageSelectors.getIsAllowedStripePaymentGateway(state)
		const promoCodeParam = promoCode ? { promo_code: promoCode } : {}
		if (!packageInfo) return

		const hasAiConversations = isNumber(packageInfo.aiConversationsLimit) && packageInfo.aiConversationsLimit > 0
		dispatch(initCheckout(interval))
		if (
			packageName === PackageName.Mini &&
			(packageInfo.name === PackageName.Pro || packageInfo.name === PackageName.Basic)
		) {
			if (hasAiConversations && !promoCode) {
				navigateTo(createCheckoutLink(packageInfo.name), promoCodeParam)
			} else {
				navigateTo(createCheckoutLink(packageInfo.name), {
					checkout_action: CheckoutStartAction.InitAiConversations,
					...promoCodeParam,
				})
			}
		} else if (packageName === PackageName.Mini) {
			if (!isStripeAllowed) {
				// Braintree/Shopify/Wire Transfer - Free/Trial - Fallback
				navigateTo(createCheckoutLink(PackageName.Basic), {
					checkout_action: CheckoutStartAction.InitAiConversations,
					...promoCodeParam,
				})
			} else if (promoCode && packageInfo.isPaid) {
				navigateTo(createCheckoutLink(packageName), {
					checkout_action: CheckoutStartAction.InitAiConversations,
					...promoCodeParam,
				})
			} else {
				navigateTo(createCheckoutLink(packageName), promoCodeParam)
			}
		} else {
			navigateTo(createCheckoutLink(packageName), promoCodeParam)
		}
	}
export const logCheckoutEvent =
	(logData: PackageLogEventRequest): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const calculation = getCheckoutCalculation(state)
		const hasEnabledThreeDSecure = paymentMethodSelectors.getIsThreeDSecureEnabled(state)
		const isThreeDSecureError = logData.action === PackageLogAction.ThreeDSecurePaymentError

		const amountValue = calculation && calculation.priceTotalUntaxed > 0 ? calculation.priceTotalUntaxed : 0
		const amountCurrency = calculation && calculation.currencyCode ? calculation.currencyCode : '-'
		const { data } = logData

		const extendedData = {
			...data,
			amount: {
				valueUntaxed: amountValue,
				currency: amountCurrency,
			},
			useThreeDSecure: hasEnabledThreeDSecure,
		}

		const paramData = { ...logData, data: extendedData }

		if (isThreeDSecureError) {
			dispatch(postPaymentError(paramData))
		} else {
			dispatch(logPackageEvent(paramData))
		}
	}

export const initCheckout =
	(interval?: PackageInterval): AppThunkAction =>
	(dispatch) => {
		dispatch(actions.initCheckout({ interval }))
	}

export const setCheckoutPackageConfiguration =
	(packageName: PackageName, action?: string | null): AppThunkAction =>
	(dispatch, getState) => {
		if (!packageName || packageName === PackageName.Trial) {
			redirectTo(routes.packages.path)
			return
		}
		if (packageName === PackageName.Mini) {
			dispatch(showAiYearlyNotification())
		}
		dispatch(actions.setCheckoutPackage(packageName))

		dispatch(initCheckoutPackageConfiguration())
		switch (action) {
			case CheckoutStartAction.InitAiConversations: {
				const state = getState()
				const aiConversationsBatchSize = getCheckoutAiConversationsBatchSize(state)
				const aiConversations = getCheckoutAiConversations(state)
				const maxAiConversations = getMaxCheckoutAiConversations(state)
				if (maxAiConversations && aiConversations < maxAiConversations) {
					dispatch(setCheckoutAiConversations(aiConversations + (aiConversationsBatchSize ?? 0)))
				}
				dispatch(showAiYearlyNotification())
				break
			}
		}
	}

export const setCheckoutInterval =
	(interval: PackageIntervalAvailable): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const allowedIntervals = packageSelectors.getAllowedCheckoutIntervals(state)

		const isAllowed = allowedIntervals[interval]
		if (!isAllowed) {
			throw new Error(`Interval '${interval}' is not allowed`)
		}

		dispatch(actions.setCheckoutInterval(interval))
	}

export const setNextCheckoutStep = (): AppThunkAction => (dispatch, getState) => {
	const currentStep: number = getCurrentCheckoutStep(getState())
	const nextStep = currentStep < 3 ? currentStep + 1 : 0
	dispatch(actions.setCurrentCheckoutStep(nextStep))
}

export const setPrevCheckoutStep = (): AppThunkAction => (dispatch, getState) => {
	const currentStep: number = getCurrentCheckoutStep(getState())
	const nextStep = currentStep > 1 ? currentStep - 1 : 0
	if (nextStep === 0) {
		navigateTo(routes.packages.path)
	} else {
		dispatch(actions.setCurrentCheckoutStep(nextStep))
	}
}

export const setCheckoutStep =
	(newStep: number): AppThunkAction =>
	(dispatch, getState) => {
		const currentStep: number = getCurrentCheckoutStep(getState())
		if (newStep < 0 || newStep >= currentStep) return

		if (newStep === 0) {
			navigateTo(routes.packages.path)
		} else {
			dispatch(actions.setCurrentCheckoutStep(newStep))
		}
	}

export const updatePaymentMethodThreeDSecure =
	(nonce: string): AppThunkAction =>
	async (dispatch) => {
		// Resume subscription
		const resumeActionResult = await dispatch(resumeSubscription({ nonce_3ds: nonce }))
		unwrapResult(resumeActionResult)

		// Fetch payment method to show updated credit card data
		const fetchActionResult = await dispatch(fetchPaymentMethod())
		unwrapResult(fetchActionResult)
	}

export const updatePaymentMethod =
	(nonce: string): AppThunkAction =>
	async (dispatch) => {
		// Update payment method
		const updateActionResult = await dispatch(updatePaymentMethodThunk(nonce))
		unwrapResult(updateActionResult)

		// Resume subscription
		const resumeActionResult = await dispatch(resumeSubscription({}))
		unwrapResult(resumeActionResult)
	}

export const updateTransferPaymentMethod =
	(data: { nonce?: string; nonce_3ds?: string }): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const packageInfo = packageSelectors.getPackageInfo(state)
		if (!packageInfo) return

		// Use data from pending invoice if it's available instead of package info
		const configuration = getTransferPackageConfiguration(packageInfo)
		const createRequestData: SubscriptionCreateRequest = {
			...(data.nonce && { payment_method_nonce: data.nonce }),
			...(data.nonce_3ds && { nonce_3ds: data.nonce_3ds }),
			paymentGateway: PaymentGateway.Braintree,
			...configuration,
		}

		// When changing payment method gateway from transfer to Braintree, new subscription must be created instead of just update payment method
		const createActionResult = await dispatch(createSubscriptionFromTransfer(createRequestData))
		unwrapResult(createActionResult)
	}

export const initCheckoutChatbotConversations = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const minChatbotConversations = getMinCheckoutChatbotConversations(state)
	const maxChatbotConversations = getMaxCheckoutChatbotConversations(state)
	const chatbotConversationsBatchSize = getCheckoutChatbotConversationsBatchSize(state)
	const checkoutChatbotConversations = getCheckoutChatbotConversations(state)

	if (maxChatbotConversations && minChatbotConversations && chatbotConversationsBatchSize) {
		dispatch(
			setCheckoutChatbotConversations(
				getConversationAvailableValue(
					minChatbotConversations,
					maxChatbotConversations,
					chatbotConversationsBatchSize,
					checkoutChatbotConversations,
				),
			),
		)
	}
}

export const initCheckoutLivechatConversations = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const minConversations = getMinCheckoutLivechatConversations(state)
	const maxConversations = getMaxCheckoutLivechatConversations(state)
	const batchSize = getCheckoutLivechatConversationsBatchSize(state)
	const conversations = getCheckoutLivechatConversations(state)

	if (maxConversations && minConversations && batchSize) {
		dispatch(
			setCheckoutLivechatConversations(
				getConversationAvailableValue(minConversations, maxConversations, batchSize, conversations),
			),
		)
	}
}

export const initCheckoutAiConversations = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const minConversations = getMinCheckoutAiConversations(state)
	const maxConversations = getMaxCheckoutAiConversations(state)
	const batchSize = getCheckoutAiConversationsBatchSize(state)
	const conversations = getCheckoutAiConversations(state)

	if (maxConversations && minConversations !== null && batchSize) {
		dispatch(
			setCheckoutAiConversations(
				getConversationAvailableValue(minConversations, maxConversations, batchSize, conversations),
			),
		)
	}
}

export const initCheckoutAgents = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const minCheckoutAgents = checkoutSelectors.getMinCheckoutAgents(state)
	const checkoutAgents = checkoutSelectors.getCheckoutAgents(state)
	const maxCheckoutAgents = checkoutSelectors.getMaxCheckoutAgents(state)

	if (minCheckoutAgents && checkoutAgents < minCheckoutAgents) {
		dispatch(setCheckoutAgents(minCheckoutAgents))
	}
	if (maxCheckoutAgents && checkoutAgents > maxCheckoutAgents) {
		dispatch(setCheckoutAgents(maxCheckoutAgents))
	}
}

export const initCheckoutPackageConfiguration = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()

	const shouldInitChatbotConversations = checkoutSelectors.getIsCheckoutChatbotConversationAllowed(state)
	const shouldInitAgentsConversations = checkoutSelectors.getIsCheckoutAgentsAllowed(state)
	const shouldInitLivechatConversations = checkoutSelectors.getIsCheckoutLivechatConversationAllowed(state)
	const shouldInitAiConversations = checkoutSelectors.getIsCheckoutAiConversationAllowed(state)
	const checkoutPackage = checkoutSelectors.getCheckoutPackage(state)

	shouldInitChatbotConversations && dispatch(initCheckoutChatbotConversations())
	if (shouldInitLivechatConversations || checkoutPackage === PackageName.Mini) {
		dispatch(initCheckoutLivechatConversations())
	}
	shouldInitAiConversations && dispatch(initCheckoutAiConversations())
	shouldInitAgentsConversations && dispatch(initCheckoutAgents())
}

export const showAiYearlyNotification = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const packageInfo = packageSelectors.getPackageInfo(state)
	const isNotificationShowed = notificationSelectors.getAiYearlyNotificationShowed(state)

	if (!packageInfo) return
	const hasAiConversations = isNumber(packageInfo.aiConversationsLimit) && packageInfo.aiConversationsLimit > 0

	if (
		packageInfo.interval !== PackageInterval.Year ||
		isNotificationShowed ||
		!packageInfo.isPaid ||
		hasAiConversations
	)
		return

	setTimeout(() => {
		dispatch(setShowAiYearlyNotification(true))
	}, DELAY_5_SEC)
}

const slice = createSlice({
	name: 'checkout',
	initialState,
	reducers: {
		setCurrentCheckoutStep: (state, { payload }: PayloadAction<number>) => {
			state.currentStep = payload
		},
		setCheckoutPackage: (state, { payload }: PayloadAction<CheckoutConfigurationAllowedPackageName>) => {
			state.package = payload
		},
		setCheckoutInterval: (state, { payload }: PayloadAction<PackageIntervalAvailable>) => {
			state.interval = payload
			state.isConfigurationDirty = true
		},
		setCheckoutChatbotConversations: (state, { payload }: PayloadAction<number>) => {
			state.chatbotConversations = payload
			state.isConfigurationDirty = true
		},
		setCheckoutAgents: (state, { payload }: PayloadAction<number>) => {
			if (isCheckoutAgentsCountValid(payload)) {
				state.agents = payload
				state.isConfigurationDirty = true
			}
		},
		setCheckoutLivechatConversations: (state, { payload }: PayloadAction<number>) => {
			state.livechatConversations = payload
			state.isConfigurationDirty = true
		},
		setCheckoutAiConversations: (state, { payload }: PayloadAction<number>) => {
			state.aiConversations = payload
			state.aiConversationsInitialized = true
			state.isConfigurationDirty = true
		},
		incrementCheckoutAgents: (state) => {
			if (state.agents < MAX_CHECKOUT_AGENTS) {
				state.agents += 1
				state.isConfigurationDirty = true
			}
		},
		decrementCheckoutAgents: (state) => {
			if (state.agents > MIN_CHECKOUT_AGENTS) {
				state.agents -= 1
				state.isConfigurationDirty = true
			}
		},
		setCalculationTrigger: (state, { payload }: PayloadAction<CalculationTrigger>) => {
			state.calculationTrigger = payload
		},
		setCheckoutPaymentMethod: (state, { payload }: PayloadAction<CheckoutAllowedPaymentMethod>) => {
			state.paymentMethod = payload
		},
		initCheckout: (state, { payload }: PayloadAction<{ interval?: PackageInterval }>) => {
			const { interval } = payload
			state.currentStep = DEFAULT_CHECKOUT_STEP
			state.calculation = null
			state.isConfigurationDirty = false
			state.agents = state.defaultAgents
			state.chatbotConversations = state.defaultChatbotConversations
			state.aiConversations = state.defaultAiConversations
			state.chatbotConversations = state.defaultChatbotConversations
			state.calculationTrigger = CalculationTrigger.Default
			state.checkoutStatusData = null
			state.isCalculationValid = false
			state.aiConversationsInitialized = false

			// set init interval from Packages list
			if (interval && isCheckoutIntervalValid(interval)) {
				state.interval = interval
				state.isConfigurationDirty = true
			} else {
				state.interval = state.defaultInterval
			}
		},
		setCheckoutPaymentStatus: (state, { payload }: PayloadAction<CheckoutStatusData>) => {
			state.checkoutStatusData = payload
		},
		resetCheckoutPaymentStatus: (state) => {
			state.checkoutStatusData = null
		},
		setIsLivechatPackagesConfigurationCollapsed: (state, { payload }: PayloadAction<boolean>) => {
			state.isLivechatPackagesConfigurationCollapsed = payload
		},
		setRedirectUri: (state, { payload }: PayloadAction<string>) => {
			state.redirectUri = payload
		},
		setIsPaymentProcessing: (state, { payload }: PayloadAction<boolean>) => {
			state.isPaymentProcessing = payload
		},
		setInitialPackage: (
			state,
			{ payload }: PayloadAction<{ packageName: PackageName; aiConversations: number | null }>,
		) => {
			state.initialPackage = {
				packageName: payload.packageName,
				aiConversations: payload.aiConversations,
			}
		},
	},
	extraReducers: (builder) => {
		// Checkout calculation
		builder.addCase(calculateSubscription.fulfilled, (state, { payload }) => {
			state.calculation = payload.result
			state.calculationTrigger = CalculationTrigger.Default
			state.isCalculationValid = true
		})

		builder.addCase(calculateStripeSubscription.fulfilled, (state) => {
			state.calculationTrigger = CalculationTrigger.Default
			state.isCalculationValid = true
		})

		// Fetch package
		builder.addCase(fetchPackage.fulfilled, (state, { payload }) => {
			const {
				interval,
				agents,
				chatbotConversationsLimit,
				name,
				allowedPaymentGateways,
				livechatConversationsLimit,
				aiConversationsLimit,
			} = payload

			const allowedIntervals = getAllowedCheckoutIntervalsFromGateways(allowedPaymentGateways)
			const isDefaultIntervalAllowed = allowedIntervals[state.interval]

			if (!state.isConfigurationDirty) {
				state.initialPackage = {
					packageName: name,
					aiConversations: aiConversationsLimit,
				}
			}

			if (!isDefaultIntervalAllowed) {
				// Set new default interval if original is not allowed
				const defaultInterval = getDefaultAllowedCheckoutInterval(allowedIntervals)
				state.defaultInterval = defaultInterval
				state.interval = defaultInterval
			}

			// Set interval from current package
			if (isCheckoutIntervalValid(interval)) {
				state.defaultInterval = interval
				if (!state.isConfigurationDirty) {
					state.interval = interval
				}
			}

			// Set agents from current package
			if (isCheckoutAgentsCountValid(agents)) {
				state.defaultAgents = agents
				if (!state.isConfigurationDirty) {
					state.agents = agents
				}
			}

			// Set chatbotConversations from current package
			if (isCheckoutChatbotConversationsCountValid(chatbotConversationsLimit, name)) {
				state.defaultChatbotConversations = chatbotConversationsLimit
				if (!state.isConfigurationDirty) {
					state.chatbotConversations = chatbotConversationsLimit
				}
			}

			// Set livechatConversations from current package
			if (isCheckoutLivechatConversationsCountValid(livechatConversationsLimit, name)) {
				state.defaultLivechatConversations = livechatConversationsLimit
				if (!state.isConfigurationDirty) {
					state.livechatConversations = livechatConversationsLimit
				}
			}

			// Set aiConversations from current package
			if (isCheckoutAiConversationsCountValid(aiConversationsLimit, name)) {
				state.defaultAiConversations = aiConversationsLimit
				if (!state.isConfigurationDirty) {
					state.aiConversations = aiConversationsLimit
				}
			}
		})
	},
})

const { actions, reducer } = slice
export const {
	incrementCheckoutAgents,
	decrementCheckoutAgents,
	setCheckoutAgents,
	setCheckoutChatbotConversations,
	setCheckoutPaymentMethod,
	setCalculationTrigger,
	setCheckoutPaymentStatus,
	resetCheckoutPaymentStatus,
	setCheckoutLivechatConversations,
	setCheckoutAiConversations,
	setRedirectUri,
	setInitialPackage,
	setIsLivechatPackagesConfigurationCollapsed,
	setIsPaymentProcessing,
} = actions
export default reducer

const getCurrentCheckoutStep = (state: CheckoutRootState) => state.checkout.currentStep
const getCheckoutPackage = (state: CheckoutRootState) => state.checkout.package
const getCheckoutInterval = (state: CheckoutRootState) => state.checkout.interval
const getCheckoutAgents = (state: CheckoutRootState) => state.checkout.agents
const getCheckoutChatbotConversations = (state: CheckoutRootState) => state.checkout.chatbotConversations
const getCheckoutLivechatConversations = (state: CheckoutRootState) => state.checkout.livechatConversations
const getCheckoutAiConversations = (state: CheckoutRootState) => state.checkout.aiConversations
const getCheckoutPaymentMethod = (state: CheckoutRootState) => state.checkout.paymentMethod
const getCheckoutStatusData = (state: CheckoutRootState) => state.checkout.checkoutStatusData
const getCheckoutCalculation = (state: CheckoutRootState) => state.checkout.calculation
const getIsLivechatPackagesConfigurationCollapsed = (state: CheckoutRootState) =>
	state.checkout.isLivechatPackagesConfigurationCollapsed
const getCalculationTrigger = (state: CheckoutRootState) => state.checkout.calculationTrigger
const getIsCheckoutCalculationValid = (state: CheckoutRootState) => state.checkout.isCalculationValid
const getInitialPackage = (state: CheckoutRootState) => state.checkout.initialPackage
const getIsAiConversationsInitialized = (state: CheckoutRootState) => state.checkout.aiConversationsInitialized
const getRedirectUri = (state: CheckoutRootState) => state.checkout.redirectUri
const getIsPaymentProcessingData = (state: CheckoutRootState) => state.checkout.isPaymentProcessing

const getMinCheckoutAgents = createSelector(
	[packagesSelectors.getPackages, getCheckoutPackage],
	(packages, checkoutPackage): number | null => {
		const pckg = packages[checkoutPackage]
		if (!pckg) return null
		return pckg.minAgentsCount
	},
)

const getMaxCheckoutAgents = createSelector(
	[packagesSelectors.getPackages, getCheckoutPackage],
	(packages, checkoutPackage): number | null => {
		const pckg = packages[checkoutPackage]
		if (!pckg) return null
		return pckg.maxAgentsCount
	},
)

const getIsCheckoutAgentsAllowed = createSelector(
	[getMinCheckoutAgents, getMaxCheckoutAgents],
	(minCheckoutAgents, maxCheckoutAgents) => {
		// pricings V1-5 have maxCheckoutAgents = null (unlimited)
		return !!minCheckoutAgents || !!maxCheckoutAgents
	},
)

const getIsMaxCheckoutAgentsReached = createSelector(
	[getCheckoutAgents, getMaxCheckoutAgents],
	(checkoutAgents, maxCheckoutAgents): boolean => {
		if (maxCheckoutAgents === null) return false
		return !!maxCheckoutAgents && checkoutAgents >= maxCheckoutAgents
	},
)

const hasLimitedMaxCheckoutAgents = createSelector([getMaxCheckoutAgents], (maxCheckoutAgents): boolean => {
	return maxCheckoutAgents !== null
})

const getCheckoutPaymentGateway = createSelector(
	[packageSelectors.getIsShopifyAccount],
	(isShopifyGatewayAllowed): PaymentGateway => {
		return isShopifyGatewayAllowed ? PaymentGateway.Shopify : PaymentGateway.Braintree
	},
)

const getCheckoutType = createSelector(
	[
		packageSelectors.getIsShopifyAccount,
		paymentMethodSelectors.getHasBraintreePaymentMethod,
		packageSelectors.getPackageInfo,
		packageSelectors.getIsAllowedStripePaymentGateway,
		packageSelectors.getIsAllowedDifferentPaymentGateway,
	],
	(
		isShopifyGatewayAllowed,
		hasBraintreePaymentMethod,
		packageInfo,
		isAllowedStripePaymentGateway,
		isAllowedDifferentPaymentGateway,
	): CheckoutType => {
		if (isShopifyGatewayAllowed) return CheckoutType.ShopifySubscription

		// Show update flow when BT payment method is saved and package is paid (excludes demo packages)
		const shouldUseBTUpdate = hasBraintreePaymentMethod && !!packageInfo?.isPaid && !isAllowedDifferentPaymentGateway

		if (shouldUseBTUpdate) return CheckoutType.UpdateSubscription

		if (isAllowedStripePaymentGateway) {
			return packageInfo?.isPaid && !isAllowedDifferentPaymentGateway
				? CheckoutType.StripeUpdateSubscription
				: CheckoutType.StripeNewSubscription
		}

		return CheckoutType.NewSubscription
	},
)

const getCurrentCheckoutStepInfo = createSelector(
	[getCurrentCheckoutStep, getCheckoutType],
	(step, type): CheckoutStepInfo | undefined => {
		const stepsMap = {
			[CheckoutType.NewSubscription]: newSubscriptionSteps,
			[CheckoutType.UpdateSubscription]: updateSubscriptionSteps,
			[CheckoutType.ShopifySubscription]: shopifySubscriptionSteps,
			[CheckoutType.StripeNewSubscription]: newSubscriptionSteps,
			[CheckoutType.StripeUpdateSubscription]: updateSubscriptionSteps,
		}

		const steps = stepsMap[type]
		return Object.values(steps).find((s) => s.number === step)
	},
)

const getBraintreeThreeDSecureParams = createSelector(
	[billingInfoSelectors.getBillingInfo, getCheckoutCalculation],
	(billingInfo, calculation): BraintreeThreeDSecureParams | null => {
		if (!billingInfo || !calculation) return null

		const amount = calculation.priceFinalTaxed > 0 ? calculation.priceFinalTaxed : 0

		return {
			amount: String(amount),
			email: billingInfo.email,
			billingAddress: {
				locality: sanitizeBraintreeParamString(billingInfo.city),
				streetAddress: sanitizeBraintreeParamString(billingInfo.street),
				postalCode: billingInfo.postalCode,
				countryCodeAlpha2: billingInfo.countryCode,
			},
		}
	},
)

const getBraintreeThreeDSecureParamsTemplate = createSelector(
	[getCheckoutCalculation],
	(calculation): BraintreeThreeDSecureParams | null => {
		if (!calculation) return null

		const amount = calculation.priceFinalTaxed > 0 ? calculation.priceFinalTaxed : 0

		return {
			amount: String(amount),
			billingAddress: {
				locality: '',
				streetAddress: '',
				postalCode: '',
				countryCodeAlpha2: '',
			},
		}
	},
)

const getCheckoutStatusModalType = createSelector(
	[getCheckoutStatusData],
	(data): CheckoutStatusData['status'] | null => {
		if (!data) return null
		return data.status
	},
)

const getCheckoutStatusModalErrorData = createSelector(
	[getCheckoutStatusData],
	(data): CheckoutStatusModalLogData | null => {
		if (!data) return null
		return {
			action: data.meta?.action ?? PackageLogAction.PaymentError,
			message: data.meta?.message ?? 'Payment error',
			data: data.meta?.data ?? undefined,
		}
	},
)

const getCheckoutCalculationMonthlyBasePrice = createSelector(
	[getCheckoutCalculation, getCheckoutInterval],
	(calculation, interval): number | null => {
		if (!calculation) return null

		return interval * (calculation?.monthlyBasePriceUntaxed ?? 0)
	},
)

const getCheckoutCalculationDiscounts = createSelector(
	[getCheckoutCalculation, getCheckoutCalculationMonthlyBasePrice],
	(calculation, monthlyBasePrice): Discount[] => {
		if (!calculation || !monthlyBasePrice) return []

		const discountTotalUntaxed = calculation ? calculation.discountTotalUntaxed : 0
		const remainingTotalUntaxed = calculation ? calculation.remainingTotalUntaxed : 0
		const yearlyDiscount = monthlyBasePrice - (calculation.basePriceTotalUntaxed ?? 0)

		const discountsArray = [
			{ type: DiscountType.Yearly, value: yearlyDiscount, title: 'billing.yearlyDiscount' },
			{ type: DiscountType.Individual, value: discountTotalUntaxed, title: 'billing.individualDiscount' },
			{ type: DiscountType.Remaining, value: remainingTotalUntaxed, title: 'billing.overpaymentDiscount' },
		]

		return discountsArray.filter((discount) => discount.value > 0)
	},
)

const getMinCheckoutChatbotConversations = createSelector(
	[packagesSelectors.getPackages, getCheckoutPackage],
	(packages, checkoutPackage): number | null => {
		const pckg = packages[checkoutPackage]
		if (!pckg) return null
		return pckg.chatbotConversationsMin
	},
)

const getMaxCheckoutChatbotConversations = createSelector(
	[packagesSelectors.getPackages, getCheckoutPackage],
	(packages, checkoutPackage): number | null => {
		const pckg = packages[checkoutPackage]
		if (!pckg) return null
		return pckg.chatbotConversationsMax
	},
)

const getCheckoutChatbotConversationsBatchSize = createSelector(
	[packagesSelectors.getPackages, getCheckoutPackage],
	(packages, checkoutPackage): number | null => {
		const pckg = packages[checkoutPackage]
		if (!pckg) return null
		return pckg.chatbotConversationsBatchSize
	},
)

const getIsCheckoutChatbotConversationAllowed = createSelector(
	[getMinCheckoutChatbotConversations, getMaxCheckoutChatbotConversations],
	(minCheckoutChatbotConversations, maxCheckoutChatbotConversations) => {
		return !!minCheckoutChatbotConversations && !!maxCheckoutChatbotConversations
	},
)

const hasLimitedMaxChatbotConversations = createSelector(
	[getMaxCheckoutChatbotConversations],
	(maxChatbotConversations): boolean => {
		return maxChatbotConversations !== null
	},
)

const getIsMaxCheckoutChatbotConversationsReached = createSelector(
	[getCheckoutChatbotConversations, getMaxCheckoutChatbotConversations],
	(checkoutChatbotConversations, maxChatbotConversations): boolean => {
		if (maxChatbotConversations === null || checkoutChatbotConversations == null) return false
		return !!maxChatbotConversations && checkoutChatbotConversations >= maxChatbotConversations
	},
)

const getMinCheckoutLivechatConversations = createSelector(
	[packagesSelectors.getPackages, getCheckoutPackage],
	(packages, checkoutPackage): number | null => {
		const pckg = packages[checkoutPackage]
		if (!pckg) return null
		return pckg.livechatConversationsMin
	},
)

const getMaxCheckoutLivechatConversations = createSelector(
	[packagesSelectors.getPackages, getCheckoutPackage],
	(packages, checkoutPackage): number | null => {
		const pckg = packages[checkoutPackage]
		if (!pckg) return null
		return pckg.livechatConversationsMax
	},
)

const getIsCheckoutLivechatConversationAllowed = createSelector(
	[getMinCheckoutLivechatConversations, getMaxCheckoutLivechatConversations],
	(minCheckoutLivechatConversations, maxCheckoutLivechatConversations) => {
		return (
			!!minCheckoutLivechatConversations &&
			!!maxCheckoutLivechatConversations &&
			maxCheckoutLivechatConversations !== minCheckoutLivechatConversations
		)
	},
)

const getCheckoutLivechatConversationsBatchSize = createSelector(
	[packagesSelectors.getPackages, getCheckoutPackage],
	(packages, checkoutPackage): number | null => {
		const pckg = packages[checkoutPackage]
		if (!pckg) return null
		return pckg.livechatConversationsBatchSize
	},
)

const hasLimitedMaxLivechatConversations = createSelector(
	[getMaxCheckoutLivechatConversations],
	(maxConversations): boolean => {
		return maxConversations !== null
	},
)

const getIsMaxCheckoutLivechatConversationsReached = createSelector(
	[getCheckoutLivechatConversations, getMaxCheckoutLivechatConversations],
	(checkoutConversations, maxConversations): boolean => {
		if (maxConversations === null || checkoutConversations == null) return false
		return !!maxConversations && checkoutConversations >= maxConversations
	},
)

const getMinCheckoutAiConversations = createSelector(
	[packagesSelectors.getPackages, getCheckoutPackage],
	(packages, checkoutPackage): number | null => {
		const pckg = packages[checkoutPackage]
		if (!pckg) return null
		return pckg.aiConversationsMin
	},
)

const getMaxCheckoutAiConversations = createSelector(
	[packagesSelectors.getPackages, getCheckoutPackage],
	(packages, checkoutPackage): number | null => {
		const pckg = packages[checkoutPackage]
		if (!pckg) return null
		return pckg.aiConversationsMax
	},
)

const getCheckoutAiConversationsBatchSize = createSelector(
	[packagesSelectors.getPackages, getCheckoutPackage],
	(packages, checkoutPackage): number | null => {
		const pckg = packages[checkoutPackage]
		if (!pckg) return null
		return pckg.aiConversationsBatchSize
	},
)

const getIsMaxCheckoutAiConversationsReached = createSelector(
	[getCheckoutAiConversations, getMaxCheckoutAiConversations],
	(aiConversations, maxConversations): boolean => {
		if (maxConversations === null || aiConversations == null) return false
		return !!maxConversations && aiConversations >= maxConversations
	},
)

const getIsCheckoutAiConversationAllowed = createSelector(
	[getMinCheckoutAiConversations, getMaxCheckoutAiConversations],
	(minCheckoutAiConversations, maxCheckoutAiConversations) => {
		return minCheckoutAiConversations !== null && !!maxCheckoutAiConversations
	},
)

const getCheckoutPackageConfiguration = createSelector(
	[
		getCheckoutPackage,
		getCheckoutInterval,
		getCheckoutAgents,
		getCheckoutChatbotConversations,
		getCheckoutLivechatConversations,
		getCheckoutAiConversations,
		getIsCheckoutAgentsAllowed,
		getIsCheckoutChatbotConversationAllowed,
		getIsCheckoutLivechatConversationAllowed,
		getIsCheckoutAiConversationAllowed,
		getIsAiConversationsInitialized,
	],
	(
		pckg,
		interval,
		agents,
		chatbotConversations,
		livechatConversations,
		aiConversations,
		isCheckoutAgentsAllowed,
		isCheckoutChatbotConversationAllowed,
		isCheckoutLivechatConversationAllowed,
		isCheckoutAiConversationAllowed,
		isCheckoutAiConversationInitialized,
	): PackageConfiguration => {
		return {
			package: pckg,
			interval,
			...(isCheckoutAgentsAllowed && agents > 0 && { agents }),
			...(isCheckoutChatbotConversationAllowed && chatbotConversations > 0 && { chatbotConversations }),
			...(isCheckoutLivechatConversationAllowed && livechatConversations > 0 && { livechatConversations }),
			...(isCheckoutAiConversationAllowed && isCheckoutAiConversationInitialized && { aiConversations }),
		}
	},
)

const getCheckoutPackageConfigurationStripe = createSelector(
	[
		getCheckoutPackage,
		getCheckoutAgents,
		getCheckoutChatbotConversations,
		getCheckoutLivechatConversations,
		getCheckoutAiConversations,
	],
	(pckg, agents, chatbotConversations, livechatConversations, aiConversations): PackageConfigurationStripe => {
		return { package: pckg, agents, chatbotConversations, livechatConversations, aiConversations }
	},
)

const getIsPackageConfigurationSameAsCurrentPackage = createSelector(
	[getCheckoutPackageConfiguration, packageSelectors.getPackageInfo],
	(cfg, packageInfo) => {
		if (!packageInfo || packageInfo.isExpired) return false

		return (
			cfg.package === packageInfo.name &&
			cfg.interval === packageInfo.interval &&
			(!packageInfo.agents || !cfg.agents || cfg.agents === packageInfo.agents) &&
			(!packageInfo.chatbotConversationsLimit ||
				!cfg.chatbotConversations ||
				cfg.chatbotConversations === packageInfo.chatbotConversationsLimit) &&
			(!packageInfo.livechatConversationsLimit ||
				!cfg.livechatConversations ||
				cfg.livechatConversations === packageInfo.livechatConversationsLimit) &&
			(!isNumber(cfg.aiConversations) || cfg.aiConversations === packageInfo.aiConversationsLimit)
		)
	},
)

const getSubscriptionCalculateData = createSelector(
	[
		getCheckoutPackageConfiguration,
		getCheckoutPaymentGateway,
		billingInfoSelectors.getBillingInfo,
		billingInfoSelectors.getIsBillingInfoFilled,
		billingInfoSelectors.getIsBillingInfoDirty,
	],
	(
		configuration,
		paymentGateway,
		billingInfo,
		isBillingInfoFilled,
		isBillingInfoDirty,
	): SubscriptionCalculateRequest => {
		// Only use billing params when billing info isn't filled or user changed billing form
		const shouldUseBillingInfoParams = !isBillingInfoFilled || isBillingInfoDirty

		const { countryCode, vatNumberFormatted } = billingInfo
		const hasVatNumber = !!vatNumberFormatted && vatNumberFormatted.length > 0

		return {
			...configuration,
			paymentGateway,
			...(shouldUseBillingInfoParams && {
				...(countryCode && { countryCode }),
				...(hasVatNumber && { vatNumber: vatNumberFormatted }),
			}),
		}
	},
)

const getStripeSubscriptionCalculateData = createSelector(
	[
		getCheckoutPackageConfigurationStripe,
		getCheckoutChatbotConversationsBatchSize,
		stripeSharedSelectors.getStripePromoCode,
		stripeSharedSelectors.hasStripePromoCode,
		getIsCheckoutAgentsAllowed,
		getIsCheckoutChatbotConversationAllowed,
		getIsCheckoutLivechatConversationAllowed,
		getIsCheckoutAiConversationAllowed,
	],
	(
		configuration,
		chatbotBatchSize,
		promoCodes,
		hasPromoCode,
		isCheckoutAgentsAllowed,
		isCheckoutChatbotConversationAllowed,
		isCheckoutLivechatConfiguration,
		isCheckoutAiConversationAllowed,
	): StripeSubscriptionCalculationRequest => {
		const { agents, chatbotConversations, livechatConversations, aiConversations } = configuration
		const packageCode = resolveStripePackageName(configuration.package)
		const chatbotQuantity = chatbotBatchSize && chatbotConversations ? chatbotConversations / chatbotBatchSize : 1

		const products: StripeSubscriptionOrderItem[] = [{ code: StripeSubscriptionOrderItemCode.Package, quantity: 1 }]

		if (agents && isCheckoutAgentsAllowed) {
			products.push({ code: StripeSubscriptionOrderItemCode.Agent, quantity: agents })
		}

		if (chatbotConversations && isCheckoutChatbotConversationAllowed) {
			products.push({ code: StripeSubscriptionOrderItemCode.Chatbot, quantity: chatbotQuantity })
		}

		if (
			livechatConversations &&
			(isCheckoutLivechatConfiguration ||
				configuration.package === StripeSubscriptionCalculationRequestPackageCode.Mini)
		) {
			products.push({ code: StripeSubscriptionOrderItemCode.LivechatConversation, quantity: livechatConversations })
		}

		if (aiConversations && isCheckoutAiConversationAllowed) {
			products.push({ code: StripeSubscriptionOrderItemCode.AiConversation, quantity: aiConversations })
		}

		return {
			packageCode,
			products,
			...(hasPromoCode && { promoCodes }),
		}
	},
)

const getStripeSubscriptionCreateData = createSelector(
	[getStripeSubscriptionCalculateData, getCheckoutInterval, subscriptionSelectors.getSource],
	(calculateData, interval, subscriptionSource): StripeSubscriptionSaveRequest => {
		return {
			...calculateData,
			interval: resolveStripeInterval(interval) ?? StripeSubscriptionSaveRequestInterval.Month,
			...(subscriptionSource && { subscriptionSource }),
		}
	},
)

const getIsAvailableWireTransfer = createSelector(
	[
		paymentMethodSelectors.getIsPaymentMethodTransfer,
		packageSelectors.getPackageInfo,
		packageSelectors.getIsShopifyAccount,
		packageSelectors.getIsAllowedStripePaymentGateway,
	],
	(isWireTransfer, packageInfo, isShopifyAccount, isStripeAllowed): boolean => {
		if (!packageInfo) return false

		return (
			!isWireTransfer && !packageInfo.isPaid && !packageInfo.hasShopifyGateway && !isShopifyAccount && !isStripeAllowed
		)
	},
)

const getAiConversationPurchaseEventData = createSelector(
	[getCheckoutAiConversations, getInitialPackage],
	(aiConversations, initialPackage): 'increase' | 'decrease' | 'none' => {
		const initialAiConversations = initialPackage.aiConversations

		if (initialAiConversations === null) return 'none'

		if (aiConversations > initialAiConversations) return 'increase'
		if (aiConversations < initialAiConversations) return 'decrease'
		return 'none'
	},
)

const getAiConversationBonusEventData = createSelector(
	[usageSelectors.getBonusAiConversationsLimits, getCheckoutAiConversations, getInitialPackage],
	(limits, aiConversations, initialPackage): boolean | null => {
		const initialAiConversations = initialPackage.aiConversations

		if (initialAiConversations === aiConversations) return null

		return !!limits.isOverLimit
	},
)

const getSummaryRowMaxCountSize = createSelector([getCheckoutPackageConfiguration], (configuration) => {
	if (!configuration) return null

	const { agents, chatbotConversations, livechatConversations, aiConversations } = configuration

	const digitCountArray = Object.values({ agents, chatbotConversations, livechatConversations, aiConversations }).map(
		(value) => value?.toString().length ?? 0,
	)

	return Math.max(...digitCountArray)
})

const getIsPaymentProcessing = createSelector(
	[getIsPaymentProcessingData, subscriptionSelectors.getIsCreatePending, subscriptionSelectors.getIsUpdatePending],
	(isPaymentProcessing, isCreatePending, isUpdatePending) => {
		return isPaymentProcessing || isCreatePending || isUpdatePending
	},
)

export const checkoutSelectors = {
	getCheckoutType,
	getCurrentCheckoutStep,
	getCheckoutPackage,
	getCheckoutInterval,
	getCheckoutAgents,
	getCheckoutChatbotConversations,
	getCheckoutPaymentMethod,
	getCheckoutCalculation,
	getMinCheckoutAgents,
	getMaxCheckoutAgents,
	getMinCheckoutChatbotConversations,
	getMaxCheckoutChatbotConversations,
	getIsMaxCheckoutAgentsReached,
	getIsMaxCheckoutChatbotConversationsReached,
	getCurrentCheckoutStepInfo,
	getCheckoutPackageConfiguration,
	getCheckoutPackageConfigurationStripe,
	getIsPackageConfigurationSameAsCurrentPackage,
	getCheckoutPaymentGateway,
	getBraintreeThreeDSecureParams,
	getCheckoutStatusModalErrorData,
	getCheckoutStatusModalType,
	getCheckoutCalculationMonthlyBasePrice,
	getCheckoutCalculationDiscounts,
	getBraintreeThreeDSecureParamsTemplate,
	hasLimitedMaxCheckoutAgents,
	hasLimitedMaxChatbotConversations,
	getCalculationTrigger,
	getCheckoutChatbotConversationsBatchSize,
	getSubscriptionCalculateData,
	getStripeSubscriptionCreateData,
	getStripeSubscriptionCalculateData,
	getIsAvailableWireTransfer,
	getIsCheckoutCalculationValid,
	getCheckoutLivechatConversations,
	getMinCheckoutLivechatConversations,
	getMaxCheckoutLivechatConversations,
	getCheckoutLivechatConversationsBatchSize,
	hasLimitedMaxLivechatConversations,
	getIsMaxCheckoutLivechatConversationsReached,
	getIsCheckoutAgentsAllowed,
	getIsCheckoutChatbotConversationAllowed,
	getIsCheckoutLivechatConversationAllowed,
	getInitialPackage,
	getMinCheckoutAiConversations,
	getMaxCheckoutAiConversations,
	getCheckoutAiConversationsBatchSize,
	getCheckoutAiConversations,
	getIsMaxCheckoutAiConversationsReached,
	getIsCheckoutAiConversationAllowed,
	getAiConversationPurchaseEventData,
	getIsAiConversationsInitialized,
	getRedirectUri,
	getAiConversationBonusEventData,
	getSummaryRowMaxCountSize,
	getIsLivechatPackagesConfigurationCollapsed,
	getIsPaymentProcessing,
}
