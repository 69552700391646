import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { AppThunkAction, DashboardState, Dictionary } from 'types'
import { RangeType } from 'shared/models/Analytics'
import { AiChatbotApi, AiChatbotStats, AiChatbotStatsSummary } from 'shared/models/ChatbotStatistics'
import { normalize } from 'utils'
import { DEFAULT_STATISTICS_ARRAY, TransformedChatbotStatistic } from 'modules/chatbotStatistics'

import { aiChatbotStatsApi } from './api'
import { transformAiChatbotStatistics } from './utils'

type AiChatbotStatisticsRootState = Pick<DashboardState, 'aiChatbotStatistics'>

const initialState = {
	summary: null as null | AiChatbotStatsSummary,
	bots: null as null | Dictionary<AiChatbotStats>,
	lastUpdate: null as null | string,
	isFetching: false,
}

const fetchAiChatbotStatisticsThunk = createAsyncThunk('aiChatbotStatistics/FETCH', (data: AiChatbotApi.StatsQuery) => {
	return aiChatbotStatsApi.getStatistics(data)
})

export const fetchAiChatbotStatistics = (): AppThunkAction => async (dispatch) => {
	await dispatch(
		fetchAiChatbotStatisticsThunk({
			range: RangeType.Last30days,
			fields: [
				AiChatbotApi.StatsQueryFields.Summary,
				AiChatbotApi.StatsQueryFields.Bots,
				AiChatbotApi.StatsQueryFields.LastUpdate,
			],
		}),
	)
}

export const fetchAiChatbotStatisticsSummaryByRange =
	(range: RangeType): AppThunkAction =>
	async (dispatch) => {
		await dispatch(
			fetchAiChatbotStatisticsThunk({
				range,
				fields: [AiChatbotApi.StatsQueryFields.Summary],
			}),
		)
	}

const aiChatbotStatisticsSlice = createSlice({
	name: 'aiChatbotStatistics',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAiChatbotStatisticsThunk.fulfilled, (state, { payload }) => {
				const { summary, bots, lastUpdate } = payload
				if (summary) {
					state.summary = summary
				}
				if (bots) {
					state.bots = normalize('botId', bots)
				}
				if (lastUpdate) {
					state.lastUpdate = lastUpdate
				}
				state.isFetching = false
			})
			.addCase(fetchAiChatbotStatisticsThunk.pending, (state) => {
				state.isFetching = true
			})
			.addCase(fetchAiChatbotStatisticsThunk.rejected, (state) => {
				state.isFetching = false
			})
	},
})

const getSummary = (state: AiChatbotStatisticsRootState) => state.aiChatbotStatistics.summary
const getBots = (state: AiChatbotStatisticsRootState) => state.aiChatbotStatistics.bots
const getIsFetching = (state: AiChatbotStatisticsRootState) => state.aiChatbotStatistics.isFetching
const getLastUpdate = (state: AiChatbotStatisticsRootState) => state.aiChatbotStatistics.lastUpdate

const getAiBotSummaryStrings = createSelector([getSummary], (summary): Dictionary<TransformedChatbotStatistic> => {
	if (!summary) return normalize('name', DEFAULT_STATISTICS_ARRAY)

	const botSummaryStrings = transformAiChatbotStatistics(summary)
	return normalize('name', botSummaryStrings)
})

const makeGetAiBotStatisticsStringsById = (id: string) => {
	return createSelector([getBots], (bots): TransformedChatbotStatistic[] | null => {
		if (!bots) return null

		return transformAiChatbotStatistics(bots[id])
	})
}

const { reducer } = aiChatbotStatisticsSlice
export default reducer

export const aiChatbotStatisticsSelectors = {
	getIsFetching,
	getLastUpdate,
	getBots,
	getAiBotSummaryStrings,
	makeGetAiBotStatisticsStringsById,
	getSummary,
}
