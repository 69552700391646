import { batch } from 'react-redux'
import { action, payload } from 'ts-action'

import {
	Chat,
	ChatsFetchResponse,
	ChatsReadResponse,
	ChatsSearchRequest,
	ChatsSearchResponse,
	ChatsTab,
	Visitor,
} from 'models'
import { AppThunkAction } from 'types'
import { Agent, AgentEvents } from 'shared/models/Agent'
import { ContactChanges } from 'shared/models/Contact'
import { ApiError, asyncAction, flashMessage } from 'shared/utils'
import { accountSelectors } from 'modules/account'
import { unselectChat } from 'modules/chatDetail'
import { chatsFilterSelectors, setLastResponseAfter, setRequestFilterParams } from 'modules/chatsFilter'
import {
	chatbotChatDeleted,
	chatsSliceSelectors,
	setIsSearchingClosed,
	setIsSearchingOpen,
	setUnreadChatsCount,
	updateChatbotChatDetails,
} from 'modules/chatsSlice'
import { contactsSelectors, fetchContact, fetchContactData, setSelectedContactChatHistory } from 'modules/contacts'
import { fetchMessages, messageReceived, removeHelpbotResolveMessages } from 'modules/messages'
import { userSelectors } from 'modules/user'
import { fetchVisitor } from 'modules/visitors'

import * as chatsSelectors from './selectors'
import { isAllowedFetchOpenChats, isChatbotConversation, openChatsFilterToRequestParams } from './utils'

const API_RESOURCE = 'v2/conversations'

export const chatUpdated = action(
	'chat/UPDATED',
	payload<{
		chatId: string | null
		values: Partial<Chat>
	}>(),
)
export const chatAgentRead = action('chats/AGENT_READ', payload<{ chatId: string; lastReadAt: string }>())
export const chatAgentUnread = action('chats/AGENT_UNREAD', payload<{ chatId: string }>())
export const chatAgentJoined = action('chats/AGENT_JOINED', payload<{ chatId: string; agentId: Agent['id'] }>())
export const chatAgentLeft = action('chats/AGENT_LEFT', payload<{ chatId: string; agentId: Agent['id'] }>())
export const chatAgentAssigned = action('chats/AGENT_ASSIGNED', payload<{ chatId: string; assignedId: Agent['id'] }>())
export const chatAgentUnassigned = action(
	'chats/AGENT_UNASSIGNED',
	payload<{ chatId: string; unassignedId: Agent['id'] }>(),
)
export const chatOpened = action('chats/OPENED', payload<{ chatId: string; isMuted?: boolean }>())
export const chatDeleted = action('chats/DELETED', payload<{ chatId: string }>())
export const chatClosed = action('chats/CLOSED', payload<{ chatId: string }>())
export const chatSelected = action('chats/SELECTED', payload<{ chat: ChatsFetchResponse }>())
export const chatSelectedError = action('chats/SELECTED_ERROR')
export const chatContactRead = action('chats/VISITOR_READ', payload<{ chatId: string; lastReadAt: string }>())
export const chatUnselected = action('chats/UNSELECTED')
export const chatIncrementUnreadCount = action('chats/INCREMENT_UNREAD', payload<{ chatId: string }>())
export const setChatDetailId = action('chats/SET_DETAIL_ID', payload<{ chatId: string }>())

export const visitorDetailsUpdated = action(
	'visitor/DETAILS_UPDATED',
	payload<{ chatId: string; values: Pick<Visitor, 'connectedAt'> }>(),
)

export const contactDetailsUpdated = action(
	'chats/CONTACT_DETAILS_UPDATED',
	payload<{ contactId: string; chatId: string; values: ContactChanges }>(),
)

/**
 * REST API calls
 */
export const searchOpenChatsAsync = asyncAction<ChatsSearchResponse>('chats/SEARCH_OPEN')
export const searchOpenChats =
	(): AppThunkAction =>
	async (dispatch, getState, { api }) => {
		const state = getState()
		const timezone = accountSelectors.getAccountTimezone(state)
		const filterParams = openChatsFilterToRequestParams()
		const lastTimeFetch = chatsSelectors.getLastFetchOpenChatsTime(state)
		const size = 1000

		if (!isAllowedFetchOpenChats(lastTimeFetch)) return

		const requestParams: ChatsSearchRequest = { size, timezone, sort: [{ createdAt: 'desc' }], ...filterParams }

		dispatch(searchOpenChatsAsync.request({ isPending: true }))
		dispatch(setIsSearchingOpen(true))
		try {
			const response = await api.post<ChatsSearchResponse>(
				`${API_RESOURCE}/search?lastMessage=true&unreadInfo=true&visitorDetails=true&contactDetails=true&readInfo=true&allowExtraSize=true&autoClose=true`,
				requestParams,
			)
			dispatch(searchOpenChatsAsync.success({ isPending: false, response }))
			dispatch(setIsSearchingOpen(false))
		} catch (error) {
			dispatch(searchOpenChatsAsync.failure({ isPending: false, error: error as ApiError }))
			dispatch(setIsSearchingOpen(false))
		}
	}

export const searchClosedChatsAsync = asyncAction<ChatsSearchResponse>('chats/SEARCH_CLOSED')
export const searchClosedChats =
	(): AppThunkAction =>
	async (dispatch, getState, { api }) => {
		const state = getState()
		const timezone = accountSelectors.getAccountTimezone(state)
		const query = chatsFilterSelectors.getClosedChatsSearchRequestQuery(state)
		const filters = chatsFilterSelectors.getClosedChatsSearchRequestFilters(state)

		let requestFilterParams: ChatsSearchRequest = {
			size: 100,
			query,
			timezone,
			sort: [{ createdAt: 'desc' }],
		}

		// advanced filter
		if (Object.keys(filters).length > 0) {
			requestFilterParams = { ...requestFilterParams, filters }
		}

		dispatch(searchClosedChatsAsync.request({ isPending: true }))
		dispatch(setIsSearchingClosed(true))
		try {
			const response = await api.post<ChatsSearchResponse>(
				`${API_RESOURCE}/search?lastMessage=true&unreadInfo=true&contactDetails=true&visitorDetails=true`,
				requestFilterParams,
			)

			batch(() => {
				dispatch(setRequestFilterParams({ requestFilterParams }))
				dispatch(setLastResponseAfter(response.after))
				dispatch(searchClosedChatsAsync.success({ isPending: false, response }))
				dispatch(setIsSearchingClosed(false))
			})
		} catch (error) {
			dispatch(searchClosedChatsAsync.failure({ isPending: false, error: error as ApiError }))
			dispatch(setIsSearchingClosed(false))
		}
	}

export const loadMoreClosedChatsAsync = asyncAction<ChatsSearchResponse>('chats/LOAD_MORE_CLOSED')
export const loadMoreClosedChats =
	(): AppThunkAction<Promise<ChatsSearchResponse | null>> =>
	async (dispatch, getState, { api }) => {
		const state = getState()
		const timezone = accountSelectors.getAccountTimezone(state)
		const filter = chatsFilterSelectors.getClosedChatsFilter(state)
		const { requestFilterParams, lastResponseAfter } = filter

		// after value for Fetch More (pagination)
		if (lastResponseAfter === null) return null
		const data: ChatsSearchRequest = { ...requestFilterParams, after: lastResponseAfter, timezone }

		dispatch(loadMoreClosedChatsAsync.request({ isPending: true }))
		dispatch(setIsSearchingClosed(true))
		try {
			const response = await api.post<ChatsSearchResponse>(
				`${API_RESOURCE}/search?lastMessage=true&unreadInfo=true&contactDetails=true&visitorDetails=true`,
				data,
			)
			dispatch(setLastResponseAfter(response.after))
			dispatch(loadMoreClosedChatsAsync.success({ isPending: false, response }))
			dispatch(setIsSearchingClosed(false))
			return response
		} catch (error) {
			dispatch(loadMoreClosedChatsAsync.failure({ isPending: false, error: error as ApiError }))
			dispatch(setIsSearchingClosed(false))
			return null
		}
	}

export const searchChatsByContactId =
	(contactId: string): AppThunkAction =>
	async (dispatch, getState, { api }) => {
		const state = getState()
		const timezone = accountSelectors.getAccountTimezone(state)
		const data = {
			query: [
				{
					field: 'contactId',
					value: contactId,
				},
			],
			timezone,
		}

		try {
			const response = await api.post<ChatsSearchResponse>(`${API_RESOURCE}/search`, data)
			dispatch(setSelectedContactChatHistory(response.items))
		} catch {
			flashMessage.error('general.error')
		}
	}

export const fetchChatAsync = asyncAction<ChatsFetchResponse>('chats/FETCH')
export const fetchChat =
	(id: string, isChatDetail: boolean): AppThunkAction<Promise<ChatsFetchResponse | null>> =>
	async (dispatch, getState, { api }) => {
		dispatch(fetchChatAsync.request({ isPending: true }))

		const lastMessageRequest = isChatDetail ? '' : '&lastMessage=true'

		try {
			dispatch(fetchMessages(id))
			const response = await api.get<ChatsFetchResponse>(
				`${API_RESOURCE}/${id}?messages=0&contactDetails=true&unreadInfo=true&readInfo=true${lastMessageRequest}&visitorDetails=true`,
			)
			const { contactId, visitorId } = response
			isChatDetail && (await Promise.all([dispatch(fetchContact(contactId)), dispatch(fetchVisitor(visitorId))]))
			dispatch(fetchChatAsync.success({ isPending: false, response }))
			return response
		} catch (error) {
			dispatch(fetchChatAsync.failure({ isPending: false, error: error as ApiError }))
			return null
		}
	}

export const readChatAsync = asyncAction<ChatsReadResponse, { chatId: string }>('chats/READ')
export const readChat =
	(id: string): AppThunkAction =>
	async (dispatch, getState, { api }) => {
		const isChatUnread = chatsSelectors.getIsChatUnread(getState(), { chatId: id })
		const isChatOpen = chatsSelectors.getIsChatOpen(getState(), { chatId: id })
		if (!isChatUnread || !isChatOpen) return

		dispatch(readChatAsync.request({ isPending: true }))
		try {
			const response = await api.patch<ChatsReadResponse>(`${API_RESOURCE}/${id}/read`)
			dispatch(readChatAsync.success({ isPending: false, response, meta: { chatId: id } }))
		} catch (error) {
			dispatch(readChatAsync.failure({ isPending: false, error: error as ApiError }))
		}
	}

export const updateChat =
	(chatId: string, changes: Partial<Chat>): AppThunkAction =>
	async (dispatch, getState, { api }) => {
		try {
			await api.patch(`${API_RESOURCE}/${chatId}`, changes)
			dispatch(chatUpdated({ chatId, values: changes }))
		} catch {
			flashMessage.error('general.error')
		}
	}

/**
 * Websocket events
 */
export const onChatAgentJoined =
	({ chatId, agent, message }: AgentEvents.ChatAgentJoined): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const userId = userSelectors.getUserId(state)
		if (!userId) return

		batch(() => {
			dispatch(chatAgentJoined({ chatId, agentId: agent.id }))
			dispatch(messageReceived({ chatId, userId, message }))
		})
	}

export const onChatAgentLeft =
	({ chatId, agent, message }: AgentEvents.ChatAgentLeft): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const userId = userSelectors.getUserId(state)
		if (!userId) return

		batch(() => {
			dispatch(chatAgentLeft({ chatId, agentId: agent.id }))
			dispatch(messageReceived({ chatId, userId, message }))
		})
	}

export const onChatAgentAssigned =
	({ chatId, assigned, message }: AgentEvents.ChatAgentAssigned): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const userId = userSelectors.getUserId(state)
		const isExistsChat = chatsSelectors.isExistsChat(state, { chatId })
		if (!userId) return

		if (assigned.id === userId && !isExistsChat) {
			const newChat = await dispatch(fetchChat(chatId, false))
			if (!newChat) return
		}

		batch(() => {
			dispatch(chatAgentAssigned({ chatId, assignedId: assigned.id }))
			dispatch(messageReceived({ chatId, userId, message }))
		})
	}

export const onChatAgentUnassigned =
	({ chatId, unassigned, message }: AgentEvents.ChatAgentUnassigned): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const userId = userSelectors.getUserId(state)
		if (!userId) return

		batch(() => {
			dispatch(chatAgentUnassigned({ chatId, unassignedId: unassigned.id }))
			dispatch(messageReceived({ chatId, userId, message }))
		})
	}

export const onChatAgentRead =
	({ chatId, byId, lastReadAt }: AgentEvents.ChatRead): AppThunkAction =>
	(dispatch, getState) => {
		const userId = userSelectors.getUserId(getState())
		if (!userId) return
		if (userId === byId) {
			dispatch(chatAgentRead({ chatId, lastReadAt }))
		}
	}

export const onChatAgentUnread =
	({ chatId, byId }: AgentEvents.ChatRead): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const userId = userSelectors.getUserId(state)
		const unreadChats = chatsSliceSelectors.getUnreadChatsCount(state)
		if (!userId) return
		if (userId === byId) {
			dispatch(chatAgentUnread({ chatId }))
			dispatch(setUnreadChatsCount(unreadChats + 1))
		}
	}

export const onChatContactRead =
	({ chatId }: AgentEvents.ChatRead): AppThunkAction =>
	(dispatch) => {
		const now = new Date().toISOString()
		dispatch(chatContactRead({ chatId, lastReadAt: now }))
	}

export const onChatOpened =
	({ chatId, isMuted }: AgentEvents.ChatOpened): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const isExistsChat = chatsSelectors.isExistsChat(state, { chatId })

		// always fetch chat if doesn't exist in store
		// it's important for cases e.g. when:
		// a) all of Closed chats still weren't loaded
		// b) page "Conversations" isn't active and some chat has just opened (in this chat can be unread messages which we need for counter of unread chats in sidebar)
		if (!isExistsChat) {
			await dispatch(fetchChat(chatId, false))
		}

		// pass property `isMuted` for Sound notification middleware
		// chatOpened has to be dispatch after fetching chat (due to notification middleware)
		dispatch(chatOpened({ chatId, isMuted }))
	}

export const onChatClosed =
	({ chatId, message }: AgentEvents.ChatClosed): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const isExistsChat = chatsSelectors.isExistsChat(state, { chatId })
		const getActiveTab = chatsSliceSelectors.getActiveTab(state)

		if (isExistsChat) {
			batch(() => {
				dispatch(chatClosed({ chatId }))
				dispatch(removeHelpbotResolveMessages({ chatId }))
			})
			const userId = userSelectors.getUserId(state)
			if (userId) {
				dispatch(messageReceived({ chatId, userId, message }))
			}
		} else if (getActiveTab === ChatsTab.Closed) {
			// fetch chat only when Closed tab is active (don't need to fetching chat for Open tab)
			dispatch(fetchChat(chatId, false))
		}
	}

export const onChatVisitorClosed =
	({ chatId, message }: AgentEvents.ChatVisitorClosed): AppThunkAction =>
	(dispatch, getState) => {
		dispatch(chatUpdated({ chatId, values: { isClosed: true } }))

		const userId = userSelectors.getUserId(getState())
		if (!userId) return
		batch(() => {
			dispatch(messageReceived({ chatId, userId, message }))
			dispatch(chatUpdated({ chatId, values: { lastMessage: message } }))
		})
	}

export const onChatRated =
	({ chatId, message }: AgentEvents.ChatRated): AppThunkAction =>
	(dispatch, getState) => {
		const userId = userSelectors.getUserId(getState())
		if (!userId) return
		dispatch(messageReceived({ chatId, userId, message }))
	}

export const onChatUpdated =
	({ chatId, changes }: AgentEvents.ChatUpdated): AppThunkAction =>
	async (dispatch, getState) => {
		dispatch(chatUpdated({ chatId, values: changes }))
		const state = getState()
		const selectedChat = chatsSelectors.getSelectedChat(state)

		// Update contactDetails if contactId was changed
		if ('contactId' in changes) {
			const { contactId } = changes

			if (!contactId) return
			// fetch contact if not exist
			let contact = contactsSelectors.getSelectedContact(state)

			if (selectedChat?.id === chatId) {
				// fetch contact for selected chat
				const resultAction = await dispatch(fetchContact(contactId))
				if (fetchContact.fulfilled.match(resultAction)) {
					contact = resultAction.payload
				}
			} else {
				// fetch contact only for updating contactDetails
				const resultAction = await dispatch(fetchContactData(contactId))
				if (fetchContactData.fulfilled.match(resultAction)) {
					contact = resultAction.payload
				}
			}
			if (contact) {
				const updateData = {
					contactId,
					chatId,
					values: { name: contact.name, avatar: contact.avatar },
				}
				isChatbotConversation(selectedChat?.id ?? null)
					? dispatch(updateChatbotChatDetails(updateData))
					: dispatch(contactDetailsUpdated(updateData))
			}
		}
	}

export const onChatDeleted =
	({ chatId }: AgentEvents.ChatDeleted): AppThunkAction =>
	(dispatch, getState) => {
		const selectedChatId = chatsSelectors.getChatDetailId(getState())
		if (selectedChatId === chatId) {
			dispatch(unselectChat())
		}
		isChatbotConversation(chatId) ? dispatch(chatbotChatDeleted(chatId)) : dispatch(chatDeleted({ chatId }))
	}

export const onContactDetailsUpdated =
	({ id, changes }: AgentEvents.ContactUpdated): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()

		const getChatsByContactId = chatsSelectors.makeGetChatsByContactId()
		const agentsChats = getChatsByContactId(state, { contactId: id }).filter((chat) => !isChatbotConversation(chat.id))
		const chatbotChats = getChatsByContactId(state, { contactId: id }).filter((chat) => isChatbotConversation(chat.id))

		// update contactDetails for each filtered chat by contactId
		agentsChats.forEach((chat) => {
			dispatch(contactDetailsUpdated({ contactId: id, values: changes, chatId: chat.id }))
		})
		chatbotChats.forEach((chat) => {
			dispatch(updateChatbotChatDetails({ contactId: id, values: changes, chatId: chat.id }))
		})
	}
