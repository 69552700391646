import mixpanel from 'mixpanel-browser'

import { Account } from 'shared/models/Account'
import { PackageInfo } from 'shared/models/Package'
import { User } from 'shared/models/User'
import { ConfigurationService } from 'shared/services'

export type MixpanelInfoData = {
	name: User['fullname'] | null
	$email: User['email'] | null
	role: User['role'] | null
	accountId: Account['id'] | null
	package: PackageInfo['name'] | null
	interval: PackageInfo['interval'] | null
	isDemo: PackageInfo['isDemo'] | null
	nextBillingDate: PackageInfo['nextBillingDate'] | null
	mrrCzk: PackageInfo['mrrCZK']
}

const { mixpanelProjectKey } = ConfigurationService.getAppEnvData()
const isEnvProduction = !!mixpanelProjectKey

class MixpanelService {
	public static init() {
		if (isEnvProduction) {
			mixpanel.init(mixpanelProjectKey)
		} else {
			// eslint-disable-next-line no-console
			console.warn('Mixpanel is NOT loaded.')
		}
	}

	public static track(eventName: string, properties?: Record<string, string | string[]>) {
		if (isEnvProduction) {
			mixpanel.track(eventName, properties && properties)
		} else {
			// eslint-disable-next-line no-console
			console.log(`%c MixPanel`, 'background: #4d4d4d; color: #fff', `Track event: ${eventName}`, properties)
		}
	}

	public static setInfo(data: MixpanelInfoData) {
		if (isEnvProduction) {
			mixpanel.identify(`${data.accountId}`)
			mixpanel.people.set(data)
		} else {
			// eslint-disable-next-line no-console
			console.log(`%c MixPanel`, 'background: #4d4d4d; color: #fff', `Mixpanel init data:`, data)
		}
	}
}

export default MixpanelService
