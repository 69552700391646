// do NOT delete unused items - keep them for history
export enum UserPreference {
	AddonOpen = 'ss-addonOpen',
	OpenChatsAutoResolve = 'ss-openChatsAutoResolve',
	ConversationsTab = 'ss-conversationsTab',
	CustomerInfoOpen = 'ss-customerInfoOpen',
	BrowsingHistoryOpen = 'ss-historyOpen',
	ChatsHistoryOpen = 'ss-chatsHistoryOpen',
	ContactTagsOpen = 'ss-contactTagsOpen',
	ChatsFilter = 'ss-chatsFilter',
	FeatureFlags = 'ss-featureFlags', // Only for dev purpose
	MobilePlatformsPage = 'ss-mobilePlatformsPage',
	OpenChatsFilter = 'ss-openChatsFilter',
	TrialExpiringModal = 'ss-trialExpiringModal',
	ChatbotPublishPopover = 'ss-publishButtonPopoverState',
	SendInstructionEmail = 'sendInstructionsModal',
	TranscriptChatEmail = 'sendChatModalEmail',
	ProductNotifications = 'ss-productNotifications',
	LeadgenModals = 'ss-leadgenTemplatesModals',
	SalesModals = 'ss-salesTemplatesModals',
	DemoBannerHidden = 'ss-demoBannerHidden',
	ContactPropertiesOpen = 'ss-contactPropertiesOpen',
	BlackFridayBannerHidden = 'ss-blackFridayBannerHidden-2023-11-20',
	BlackFriday2024BannerHidden = 'ss-blackFridayBannerHidden-2024-11-21',
	BlackFriday2024Notification = 'ss-blackFridayNotification-2024',
	WebinarBannerHidden = 'ss-webinarBannerHidden-2024-07-04',
	ClosedChatsFilter = 'ss-closedChatsFilter',
	DemoBannerPropertiesHidden = 'ss-demoBannerPropertiesHidden',
	DemoBannerGroupsHidden = 'ss-demoBannerGroupsHidden',
	BannerChatbotBuilder = 'ss-bannerChatbotBuilder',
	AiBannerHidden = 'ss-aiBannerHidden',
}
