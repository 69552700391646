import { createAsyncThunk, createSelector, createSlice, PayloadAction, unwrapResult } from '@reduxjs/toolkit'

import {
	AgentEvents,
	Chat,
	ChatbotChatSearchRequest,
	ChatSelectionType,
	ChatsExportRequest,
	ChatsFetchResponse,
	ChatsTab,
	ContactChanges,
	SelectedChat,
} from 'models'
import { AppThunkAction, DashboardState, Dictionary } from 'types'
import { UserPreference } from 'shared/constants'
import { loadUserPreference, storeUserPreference, TranslationService as T } from 'shared/services'
import { flashMessage, notification } from 'shared/utils'
import { ERROR_TOO_MANY_EXPORTS } from 'constants/errors'
import { normalize } from 'utils'
import { createExportNotification } from 'utils/exportNotification'
import { accountSelectors } from 'modules/account'
import { searchClosedChats } from 'modules/chats/actions'
import { transformChat } from 'modules/chats/utils'
import {
	chatsFilterSelectors,
	checkAllChats,
	enableChatsSelection,
	setCheckedChats,
	uncheckAllChats,
} from 'modules/chatsFilter'
import { fetchContact, resetSelectedContact } from 'modules/contacts'
import { setMessages } from 'modules/messages'
import { userSelectors } from 'modules/user'
import { fetchVisitor } from 'modules/visitors'

import { chatsSliceApi } from './api'
import { getDefaultSizeAndSort } from './utils'

export type ChatsSliceRootState = Pick<DashboardState, 'chatsSlice'>

const storedConversationsTab = loadUserPreference(UserPreference.ConversationsTab)

export const searchClosedChatbotChats = createAsyncThunk(
	'CHATBOT_CHATS/SEARCH',
	(requestParams: ChatbotChatSearchRequest) => {
		return chatsSliceApi.searchClosedChatbotChats(requestParams)
	},
)

export const fetchUnreadChats = createAsyncThunk('CHATS/FETCH_UNREAD_COUNT', () => {
	return chatsSliceApi.fetchUnreadChatsCount()
})

export const fetchChatbotChat = createAsyncThunk('CHATBOT_CHAT/FETCH', (id: string) => {
	return chatsSliceApi.fetchChatbotChat(id)
})

export const fetchClosedChatbotChats = (): AppThunkAction => async (dispatch, getState) => {
	try {
		const state = getState()
		const timezone = accountSelectors.getAccountTimezone(state)
		const query = chatsFilterSelectors.getClosedChatsSearchRequestQuery(state)
		const tempFilter = chatsFilterSelectors.getClosedChatsTempFilter(state)
		const { advanced } = tempFilter

		const data: ChatbotChatSearchRequest = {
			...getDefaultSizeAndSort(),
			timezone,
			query,
			...(advanced.query && { textQuery: advanced.query }),
		}

		const response = await dispatch(searchClosedChatbotChats(data))
		const unwrappedResult = unwrapResult(response)
		const { after } = unwrappedResult
		dispatch(setChatbotChatsAfter(after))
	} catch {
		flashMessage.error('general.error')
	}
}

export const loadMoreChatbotChats = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const after = getChatbotChatsAfter(state)
	const timezone = accountSelectors.getAccountTimezone(state)
	const query = chatsFilterSelectors.getClosedChatsSearchRequestQuery(state)
	const data: ChatbotChatSearchRequest = { timezone, after, ...getDefaultSizeAndSort(), query }

	if (after) {
		dispatch(searchClosedChatbotChats(data))
	}
}

export const fetchClosedChats = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const isChatbotChatsContext = chatsFilterSelectors.getIsChatbotChatsContext(state)
	isChatbotChatsContext ? dispatch(fetchClosedChatbotChats()) : dispatch(searchClosedChats())
}

export const fetchChatbotChatThunk =
	(id: string): AppThunkAction<Promise<ChatsFetchResponse | null>> =>
	async (dispatch) => {
		try {
			const response = await dispatch(fetchChatbotChat(id))
			const unwrappedResponse = unwrapResult(response)
			const { contactId, visitorId, messages } = unwrappedResponse
			let specifiedContactId = contactId
			dispatch(setMessages({ messages, chatId: id }))
			if (visitorId) {
				const responseVisitor = await dispatch(fetchVisitor(visitorId))
				const unwrappedResponseVisitor = unwrapResult(responseVisitor)
				// cases if returning visitor acquires contactId
				const { contactId: contactIdVisitor } = unwrappedResponseVisitor
				if (contactIdVisitor) specifiedContactId = contactIdVisitor
			}
			specifiedContactId ? await dispatch(fetchContact(specifiedContactId)) : dispatch(resetSelectedContact())
			return unwrappedResponse
		} catch {
			return null
		}
	}

export const deleteChat =
	(id: string): AppThunkAction =>
	async (_, getState) => {
		const isChatbotChatsContext = chatsFilterSelectors.getIsChatbotChatsContext(getState())
		try {
			await (isChatbotChatsContext ? chatsSliceApi.deleteChatbotChat(id) : chatsSliceApi.deleteChat(id))
			flashMessage.success('history.notifications.chatDeleted', { testId: 'flashMessage-success-delete-conversation' })
		} catch {
			flashMessage.error('general.error', { testId: 'flashMessage-error-delete-conversation' })
		}
	}

export const deleteMultipleChats =
	(selectedIds: string[]): AppThunkAction =>
	async (dispatch, getState) => {
		const query = { ids: selectedIds }
		dispatch(setChatSelection(ChatSelectionType.None))
		const isChatbotChatsContext = chatsFilterSelectors.getIsChatbotChatsContext(getState())
		try {
			await (isChatbotChatsContext
				? chatsSliceApi.deleteMultipleChatbotChats(query)
				: chatsSliceApi.deleteMultipleChats(query))
			flashMessage.success('history.notifications.chatDeleted')
		} catch {
			flashMessage.error('general.error')
		}
	}

export const setActiveChatsTab =
	(tab: ChatsTab): AppThunkAction =>
	(dispatch) => {
		dispatch(setActiveTab({ tab }))
		storeUserPreference(UserPreference.ConversationsTab, tab)
	}

export const onChatVisitorTyping =
	({ chatId, typing }: AgentEvents.ChatVisitorTyping): AppThunkAction =>
	(dispatch) => {
		dispatch(actions.chatVisitorTyping({ chatId, isTyping: typing.is }))
	}

export const setChatSelection =
	(type: ChatSelectionType): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const isChatbotChatsContext = chatsFilterSelectors.getIsChatbotChatsContext(state)
		const botIds = chatsSliceSelectors.getChatbotChatsIds(state)
		if (type === ChatSelectionType.All) {
			dispatch(enableChatsSelection(true))
			isChatbotChatsContext ? dispatch(setCheckedChats({ ids: botIds })) : dispatch(checkAllChats())
		} else if (type === ChatSelectionType.Specific) {
			dispatch(enableChatsSelection(true))
		} else if (type === ChatSelectionType.None) {
			dispatch(enableChatsSelection(false))
			dispatch(uncheckAllChats())
		}
	}

export const exportChats =
	(): AppThunkAction =>
	async (dispatch, getState, { api }) => {
		const state = getState()
		const timezone = accountSelectors.getAccountTimezone(state)
		const user = userSelectors.getActiveUser(state)

		const query = chatsFilterSelectors.getClosedChatsSearchRequestQuery(state)
		const filters = chatsFilterSelectors.getClosedChatsSearchRequestFilters(state)

		let requestFilterParams: ChatsExportRequest = {
			query,
			timezone,
			sort: [{ createdAt: 'asc' }],
		}

		if (Object.keys(filters).length > 0) {
			requestFilterParams = { ...requestFilterParams, filters }
		}

		if (!user || !user.email) {
			flashMessage.error('general.error')
			return
		}

		try {
			await api.post(`export/conversations`, requestFilterParams)
			notification.success(
				createExportNotification(
					T.translate('chat.export.success.title'),
					T.translate('chat.export.success.text', { email: user.email }),
					false,
				),
			)
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (error?.code === ERROR_TOO_MANY_EXPORTS) {
				notification.warning(
					createExportNotification(
						T.translate('chat.export.warning.title'),
						T.translate('chat.export.warning.text'),
						true,
					),
				)
			} else {
				notification.error(
					createExportNotification(T.translate('chat.export.error.title'), T.translate('chat.export.error.text'), true),
				)
			}
		}
	}

export const initialState = {
	chatbotChats: {} as Dictionary<Chat>,
	closedChatbotChatsTotal: 0,
	isFetchingChatbotChat: false,
	isSearchingChatbotChatsClosed: false,
	lastResponseAfter: null as null | number[],
	activeTab: (storedConversationsTab || 'open') as ChatsTab,
	typingVisitors: [] as string[],
	unreadChatsCount: 0, // Used on initial load until conversations weren't loaded
	isSearchingOpen: false,
	isSearchingClosed: false,
}

const chatsSlice = createSlice({
	name: 'chatsSlice',
	initialState,
	reducers: {
		setActiveTab: (state, { payload }: PayloadAction<{ tab: ChatsTab }>) => {
			state.activeTab = payload.tab
		},
		setChatbotChatsAfter: (state, { payload }: PayloadAction<number[]>) => {
			state.lastResponseAfter = payload
		},
		updateChatbotChatDetails: (
			state,
			{ payload }: PayloadAction<{ contactId: string; chatId: string; values: ContactChanges }>,
		) => {
			const { values, chatId } = payload

			if (!chatId) return
			if ('name' in values) {
				const chat = state.chatbotChats[chatId]
				if (chat) {
					state.chatbotChats[chatId].contactDetails = { ...state.chatbotChats[chatId].contactDetails, ...values }
				}
			}
		},
		chatbotChatDeleted: (state, { payload }: PayloadAction<string>) => {
			if (state.chatbotChats[payload]) {
				delete state.chatbotChats[payload]
				state.closedChatbotChatsTotal -= 1
			}
		},
		chatVisitorTyping: (state, { payload }: PayloadAction<{ chatId: string; isTyping: boolean }>) => {
			const { chatId, isTyping } = payload
			const set = new Set(state.typingVisitors)
			if (isTyping) {
				set.add(chatId)
			} else {
				set.delete(chatId)
			}
			state.typingVisitors = [...set]
		},
		setUnreadChatsCount: (state, { payload }: PayloadAction<number>) => {
			state.unreadChatsCount = payload
		},
		setIsSearchingOpen: (state, { payload }: PayloadAction<boolean>) => {
			state.isSearchingOpen = payload
		},
		setIsSearchingClosed: (state, { payload }: PayloadAction<boolean>) => {
			state.isSearchingClosed = payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(searchClosedChatbotChats.fulfilled, (state, { payload, meta }) => {
			const { items, total, after } = payload
			const loadMoreChats = !!meta?.arg?.after

			state.chatbotChats = loadMoreChats ? { ...state.chatbotChats, ...normalize('id', items) } : normalize('id', items)
			state.closedChatbotChatsTotal = total
			state.lastResponseAfter = after
			state.isSearchingChatbotChatsClosed = false
		})
		builder.addCase(searchClosedChatbotChats.rejected, (state) => {
			state.isSearchingChatbotChatsClosed = false
		})
		builder.addCase(searchClosedChatbotChats.pending, (state) => {
			state.isSearchingChatbotChatsClosed = true
		})

		builder.addCase(fetchChatbotChat.fulfilled, (state, { payload }) => {
			state.chatbotChats[payload.id] = state.chatbotChats[payload.id]
				? { ...state.chatbotChats[payload.id], ...payload }
				: payload
			state.isFetchingChatbotChat = false
		})
		builder.addCase(fetchChatbotChat.rejected, (state) => {
			state.isFetchingChatbotChat = false
		})
		builder.addCase(fetchChatbotChat.pending, (state) => {
			state.isFetchingChatbotChat = true
		})

		builder.addCase(fetchUnreadChats.fulfilled, (state, { payload }) => {
			state.unreadChatsCount = payload.count
		})
	},
})

const { reducer, actions } = chatsSlice
export const {
	setUnreadChatsCount,
	setActiveTab,
	setChatbotChatsAfter,
	updateChatbotChatDetails,
	chatbotChatDeleted,
	chatVisitorTyping,
	setIsSearchingOpen,
	setIsSearchingClosed,
} = actions
export default reducer

const getActiveTab = (state: ChatsSliceRootState) => state.chatsSlice.activeTab
const getChatbotChatsAfter = (state: ChatsSliceRootState) => state.chatsSlice.lastResponseAfter
const getChatbotChatsTotal = (state: ChatsSliceRootState) => state.chatsSlice.closedChatbotChatsTotal
const getChatbotChatsMap = (state: ChatsSliceRootState) => state.chatsSlice.chatbotChats
const getIsSearchingChatbotChats = (state: ChatsSliceRootState) => state.chatsSlice.isSearchingChatbotChatsClosed
const getTypingVisitors = (state: ChatsSliceRootState) => state.chatsSlice.typingVisitors
const getUnreadChatsCount = (state: ChatsSliceRootState) => state.chatsSlice.unreadChatsCount
const isSearchingOpen = (state: DashboardState) => state.chatsSlice.isSearchingOpen
const isSearchingAgentsChats = (state: DashboardState) => state.chatsSlice.isSearchingClosed

const getChatbotChats = createSelector([getChatbotChatsMap], (chats): SelectedChat[] => {
	return Object.values(chats).map(transformChat)
})

const getChatbotChatsIds = createSelector([getChatbotChats], (chats) => {
	return chats.map((chat) => chat.id)
})

export const isSearchingClosed = createSelector(
	[isSearchingAgentsChats, getIsSearchingChatbotChats, chatsFilterSelectors.getIsChatbotChatsContext],
	(isSearchingAgentChats, isSearchingChatbotsChats, isChatbotsChatsContext) => {
		return isChatbotsChatsContext ? isSearchingChatbotsChats : isSearchingAgentChats
	},
)

export const chatsSliceSelectors = {
	getActiveTab,
	getChatbotChatsAfter,
	getChatbotChatsTotal,
	getChatbotChats,
	getChatbotChatsMap,
	getIsSearchingChatbotChats,
	getChatbotChatsIds,
	getTypingVisitors,
	getUnreadChatsCount,
	isSearchingOpen,
	isSearchingClosed,
}
