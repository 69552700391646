import moment, { Moment } from 'moment'
import { isType, reducer } from 'ts-action'
import { on } from 'ts-action-immer'

import { Chat, ChatStatus } from 'models'
import { DashboardState, Dictionary } from 'types'
import { normalize } from 'utils'

import * as chatActions from './actions'

export type ChatsState = typeof initialState
export type ChatsRootState = Pick<DashboardState, 'chats'>

export const initialState = {
	chatDetail: null as null | Chat,
	chatDetailId: null as null | Chat['id'],
	chats: {} as Dictionary<Chat>,
	closedChatsTotal: 0,
	openChatsTotal: 0,
	isFetching: false,
	needForceJoin: false, // it's true if another agent has already joined into chat (also if API request /join/ was refused due to same request from another agent in same time)
	wasChatSelected: false, // Used for select chat in ConversationDetail during dash first load
	chatDetailError: false,
	openChatsLoaded: false, // If it's false, use unreadChatsCount
	autoCloseCount: 0, // Number of chats which will be auto resolved
	lastFetchOpenChatsTime: null as null | Moment,
}

export const chats = reducer(
	initialState,
	on(chatActions.chatSelected, (state, { payload }) => {
		const { chat } = payload
		state.wasChatSelected = true
		state.chatDetailError = false
		state.chatDetail = chat
		state.needForceJoin = chat.assignedIds.length > 0
	}),
	on(chatActions.setChatDetailId, (state, { payload }) => {
		const { chatId } = payload
		state.chatDetailId = chatId
	}),
	on(chatActions.chatUnselected, (state) => {
		state.chatDetail = null
		state.chatDetailId = null
		state.needForceJoin = false
	}),
	on(chatActions.chatSelectedError, (state) => {
		state.chatDetailError = true
		state.chatDetail = null
		state.chatDetailId = null
		state.needForceJoin = false
	}),
	on(chatActions.chatUpdated, (state, { payload }) => {
		const { chatId, values } = payload
		const { chatDetail } = state

		if (!chatId) return

		// update selected chat
		if (chatDetail && chatDetail.id === chatId) {
			// apply changes as default
			const changedChat = { ...chatDetail, ...values }

			// apply explicit changes for paths
			if (values && 'paths' in values && values.paths) {
				const visitorPaths = [...values.paths].reverse()
				changedChat.paths = chatDetail.paths ? [...visitorPaths, ...chatDetail.paths] : visitorPaths
			}

			// apply explicit changes for variables
			if (values && 'variables' in values) {
				changedChat.variables = values.variables ?? null
			}

			// save to store
			state.chatDetail = changedChat
		}

		if (state.chats[chatId]) {
			// update chat in list of chats
			state.chats[chatId] = { ...state.chats[chatId], ...values }
		}
	}),
	on(chatActions.chatAgentRead, (state, { payload }) => {
		const { chatId, lastReadAt } = payload
		const chat = state.chats[chatId]
		if (chat) {
			state.chats[chatId].unreadInfo.count = 0
			state.chats[chatId].unreadInfo.lastReadAt = lastReadAt
		}
	}),
	on(chatActions.chatAgentUnread, (state, { payload }) => {
		const { chatId } = payload
		const chat = state.chats[chatId]
		if (chat) {
			state.chats[chatId].unreadInfo.count = 1
			state.chats[chatId].unreadInfo.lastReadAt = null
		}
	}),
	on(chatActions.chatAgentJoined, (state, { payload }) => {
		const { chatId, agentId } = payload
		if (state.chats[chatId]) {
			const chat = state.chats[chatId]
			const assignedIdsSet = new Set(chat.assignedIds)
			const agentIdsSet = new Set(chat.agentIds)
			chat.assignedIds = [...assignedIdsSet.add(agentId)]
			chat.agentIds = [...agentIdsSet.add(agentId)]
			chat.unreadInfo.count = 0
		}
		if (state.chatDetail && state.chatDetail.id === chatId) {
			const { chatDetail } = state
			const assignedIdsSet = new Set(chatDetail.assignedIds)
			const agentIdsSet = new Set(chatDetail.agentIds)
			chatDetail.assignedIds = [...assignedIdsSet.add(agentId)]
			chatDetail.agentIds = [...agentIdsSet.add(agentId)]
			chatDetail.unreadInfo.count = 0
			state.needForceJoin = true
		}
	}),
	on(chatActions.chatAgentLeft, (state, { payload }) => {
		const { chatId, agentId } = payload
		const chat = state.chats[chatId]
		const removeAgentId = (a: string) => a !== agentId
		if (chat) {
			state.chats[chatId].assignedIds = chat.assignedIds.filter(removeAgentId)
		}
		if (state.chatDetail && state.chatDetail.id === chatId) {
			state.chatDetail.assignedIds = state.chatDetail.assignedIds.filter(removeAgentId)
		}
	}),
	on(chatActions.chatAgentAssigned, (state, { payload }) => {
		const { chatId, assignedId } = payload
		const chat = state.chats[chatId]
		const { chatDetail } = state
		if (chat) {
			const assignedIdsSet = new Set(state.chats[chatId].assignedIds)
			const agentIdsSet = new Set(state.chats[chatId].agentIds)
			state.chats[chatId].assignedIds = [...assignedIdsSet.add(assignedId)]
			state.chats[chatId].agentIds = [...agentIdsSet.add(assignedId)]
		}
		if (chatDetail && chatDetail.id === chatId) {
			const assignedIdsSet = new Set(chatDetail.assignedIds)
			const agentIdsSet = new Set(chatDetail.agentIds)
			chatDetail.assignedIds = [...assignedIdsSet.add(assignedId)]
			chatDetail.agentIds = [...agentIdsSet.add(assignedId)]
			state.needForceJoin = true
		}
	}),
	on(chatActions.chatAgentUnassigned, (state, { payload }) => {
		const { chatId, unassignedId } = payload
		const chat = state.chats[chatId]
		if (chat) {
			const assignedIdsSet = new Set(state.chats[chatId].assignedIds)
			assignedIdsSet.delete(unassignedId)
			state.chats[chatId].assignedIds = [...assignedIdsSet]
		}
		if (state.chatDetail && state.chatDetail.id === chatId) {
			const assignedIdsSet = new Set(chat.assignedIds)
			assignedIdsSet.delete(unassignedId)
			state.chatDetail.assignedIds = [...assignedIdsSet]
		}
	}),
	on(chatActions.chatClosed, (state, { payload }) => {
		const { chatId } = payload
		const chat = state.chats[chatId]
		if (chat) {
			// Closing the chat will also reset assigned agents
			state.chats[chatId] = { ...state.chats[chatId], status: ChatStatus.Closed, assignedIds: [] }
		}
		if (state.chatDetail && state.chatDetail.id === chatId) {
			state.chatDetail = { ...state.chatDetail, status: ChatStatus.Closed, assignedIds: [] }
			state.needForceJoin = false
		}
	}),
	on(chatActions.chatOpened, (state, { payload }) => {
		const { chatId } = payload
		const chat = state.chats[chatId]
		if (chat) {
			state.chats[chatId] = { ...state.chats[chatId], status: ChatStatus.Open }
		}
		if (state.chatDetail && state.chatDetail.id === chatId) {
			state.chatDetail = { ...state.chatDetail, status: ChatStatus.Open }
		}
	}),
	on(chatActions.chatContactRead, (state, { payload }) => {
		const { chatId, lastReadAt } = payload
		// selected chat
		if (state.chatDetail && state.chatDetail.readInfo && state.chatDetail.id === chatId) {
			state.chatDetail.readInfo.lastReadAt = lastReadAt
		}
	}),
	on(chatActions.chatDeleted, (state, { payload }) => {
		const { chatId } = payload

		if (state.chats[chatId]) {
			delete state.chats[chatId]
		}
	}),
	on(chatActions.chatIncrementUnreadCount, (state, { payload }) => {
		const { chatId } = payload

		if (state.chats[chatId]) {
			state.chats[chatId].unreadInfo.count += 1
		}
	}),

	// Async action handlers
	on(
		chatActions.searchOpenChatsAsync.request,
		chatActions.searchOpenChatsAsync.success,
		chatActions.searchOpenChatsAsync.failure,
		(state, action) => {
			if (isType(action, chatActions.searchOpenChatsAsync.success)) {
				const { items, total, autoCloseCount } = action.payload.response
				state.chats = { ...state.chats, ...normalize('id', items) }
				state.openChatsTotal = total
				state.openChatsLoaded = true
				state.autoCloseCount = autoCloseCount ?? 0
				state.lastFetchOpenChatsTime = moment()
			}
		},
	),
	on(
		chatActions.searchClosedChatsAsync.request,
		chatActions.searchClosedChatsAsync.success,
		chatActions.searchClosedChatsAsync.failure,
		(state, action) => {
			if (isType(action, chatActions.searchClosedChatsAsync.success)) {
				const { items, total } = action.payload.response

				// merge open chats + response with filtered closed chats for consistency of redux store
				const openChats = Object.values(state.chats).filter((chat) => chat.status === ChatStatus.Open)
				const mergedChats = [...openChats, ...items]

				state.chats = normalize('id', mergedChats)
				state.closedChatsTotal = total
			}
		},
	),
	on(
		chatActions.loadMoreClosedChatsAsync.request,
		chatActions.loadMoreClosedChatsAsync.success,
		chatActions.loadMoreClosedChatsAsync.failure,
		(state, action) => {
			if (isType(action, chatActions.loadMoreClosedChatsAsync.success)) {
				const { items } = action.payload.response
				state.chats = { ...state.chats, ...normalize('id', items) }
			}
		},
	),
	on(
		chatActions.fetchChatAsync.request,
		chatActions.fetchChatAsync.success,
		chatActions.fetchChatAsync.failure,
		(state, action) => {
			const { isPending } = action.payload
			state.isFetching = isPending
			if (isType(action, chatActions.fetchChatAsync.success)) {
				const { response } = action.payload
				state.chats[response.id] = state.chats[response.id] ? { ...state.chats[response.id], ...response } : response
			}
		},
	),
	on(chatActions.readChatAsync.success, (state, { payload }) => {
		const { meta, response } = payload
		if (!meta) return
		const chat = state.chats[meta.chatId]
		if (chat) {
			state.chats[meta.chatId].unreadInfo.count = 0
			state.chats[meta.chatId].unreadInfo.lastReadAt = response.lastReadAt
		}
	}),

	/* Contact/Visitor actions */
	on(chatActions.visitorDetailsUpdated, (state, { payload }) => {
		const { values, chatId } = payload

		if (!chatId) return

		const chat = state.chats[chatId]
		if (chat) {
			state.chats[chatId].visitorDetails = { ...state.chats[chatId].visitorDetails, ...values }
		}
	}),
	on(chatActions.contactDetailsUpdated, (state, { payload }) => {
		const { values, chatId } = payload

		if (!chatId) return

		if ('name' in values) {
			const chat = state.chats[chatId]
			if (chat) {
				state.chats[chatId].contactDetails = { ...state.chats[chatId].contactDetails, ...values }
			}
			if (state.chatDetail && state.chatDetail.id === chatId) {
				state.chatDetail.contactDetails = { ...state.chats[chatId].contactDetails, ...values }
			}
		}
	}),
)
